import React from 'react';
import { useEditor, useNode } from '@craftjs/core';

import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';

import { Slider } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormLabel } from '@material-ui/core';
import { CompactPicker as ColorPicker } from 'react-color';

import { useStylesSelected } from './SelectedWrap';

import sanitizeFromXML from '../../../../util/sanitizeFromXML';

export const Accordion = ({
  accordionTitle,
  background,
  padding,
  children
}) => {
  const {
    connectors: { connect, drag },
    id
  } = useNode();
  const { isSelected } = useEditor((state) => ({
    isSelected: state.events.selected === id
  }));
  const selectedClasses = useStylesSelected();

  return (
    <MuiAccordion
      style={{ margin: '5px 0', background }}
      className={isSelected ? selectedClasses.selected : null}
    >
      <MuiAccordionSummary
        expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <p>{accordionTitle}</p>
      </MuiAccordionSummary>
      <MuiAccordionDetails
        ref={(ref) => connect(drag(ref))}
        style={{
          background: 'white',
          display: 'flow-root',
          padding: `${padding}px`
        }}
      >
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export const AccordionSettings = () => {
  const {
    accordionTitle,
    background,
    padding,
    actions: { setProp }
  } = useNode((node) => ({
    accordionTitle: node.data.props.accordionTitle,
    background: node.data.props.background,
    padding: node.data.props.padding
  }));

  return (
    <div>
      <TextField
        id="accordionTitleInput"
        label="Titel"
        variant="outlined"
        value={accordionTitle}
        onChange={(e) =>
          setProp(
            (props) => (props.accordionTitle = sanitizeFromXML(e.target.value)),
            500
          )
        }
      />
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Background</FormLabel>
        <ColorPicker
          color={background}
          onChangeComplete={(color) => {
            setProp((props) => (props.background = color.hex), 500);
          }}
        />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">Padding</FormLabel>
        <Slider
          defaultValue={padding}
          onChange={(_, value) =>
            setProp((props) => (props.padding = value), 500)
          }
        />
      </FormControl>
    </div>
  );
};

export const AccordionDefaultProps = {
  accordionTitle: '',
  background: '#FFD8AA',
  padding: 3
};

Accordion.craft = {
  props: AccordionDefaultProps,
  related: {
    settings: AccordionSettings
  }
};
