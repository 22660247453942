import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { TableDataContext } from '../../tabeditor/tools/Table';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  abcEditorInTable: {
    maxWidth: '25rem',
    width: '16rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  input: {
    '&:invalid': {
      border: 'red solid 2px'
    }
  },
  switchCustomField: {
    marginTop: '2.2rem'
  },
  switchMediaTextEditor: {
    float: 'right'
  }
}));

export const ToogleStandardValues = ({
  isCustomField,
  setBlockDefaultData,
  resetBlockDefaultData,
  isBlockDefaultData
}) => {
  const { t } = useTranslation();
  //   const [isInTableContext] = useContext(TableDataContext);

  const classes = useStyles();

  const state = !isBlockDefaultData;

  const handleChange = () => {
    if (state) {
      setBlockDefaultData();
    } else {
      resetBlockDefaultData();
    }
  };

  return (
    <Tooltip
      title={
        state
          ? t('instanceEditor.defaultToggleOff')
          : t('instanceEditor.defaultToggleOn')
      }
    >
      <Switch
        checked={state}
        onClick={handleChange}
        color="default"
        name="checkedA"
        className={
          isCustomField
            ? classes.switchCustomField
            : classes.switchMediaTextEditor
        }
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </Tooltip>
  );
};
