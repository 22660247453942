import React from 'react';

import { useDataManagement } from '../../datamanagement/DataManagementContext';
import { InstanceTabEditorReader } from './InstanceTabEditorReader';
import { TabsDisplay } from '../tools/TabsDisplay';
import { checkTabDataRequirement } from './TabEditorDisplayNode';

export const MultipleInstanceTab = ({
  multipleInstanceData,
  parentNode,
  parentInstanceEditor,
  nodesContent
}) => {
  const { getVariableContentDefs } = useDataManagement();
  const multipleDataTab = parentNode.props.tabs[0];

  const variableContentDefs = getVariableContentDefs(
    parentInstanceEditor.instanceType
  );
  let hasMetDataRequirement = true;
  if (multipleDataTab.dataReq) {
    hasMetDataRequirement = checkTabDataRequirement(
      multipleDataTab,
      parentInstanceEditor,
      variableContentDefs
    );
  }
  if (!hasMetDataRequirement) return null;

  const startingRootNodeId = parentNode.linkedNodes[multipleDataTab.id];

  const tabs = multipleInstanceData.childrenInstances.map(
    (childInstanceData) => {
      let label = childInstanceData.dataIdentifier;
      if (!label) {
        label = childInstanceData.id;
      }

      const tab = {
        id: multipleDataTab.id + '-' + childInstanceData.id,
        label: { noLangLabel: label },
        singleData: false
      };

      let childInstanceType = childInstanceData.className;
      if (childInstanceType === 'CustomInstance') {
        childInstanceType = childInstanceData.subType;
      }

      tab.content = (
        <InstanceTabEditorReader
          readOnly={parentInstanceEditor.readOnly}
          instanceType={childInstanceType}
          instanceId={childInstanceData.id}
          loadedInstanceData={null}
          startingRootNodeId={startingRootNodeId}
          loadedNodesContent={nodesContent}
          loadedContentLang={parentInstanceEditor.contentLang}
        />
      );

      return tab;
    }
  );

  return (
    <TabsDisplay
      tabs={tabs}
      orientation={parentNode.props.orientation}
      isShortVerticalTab={parentNode.props.isShortVerticalTab}
    ></TabsDisplay>
  );
};
