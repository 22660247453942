import React from 'react';
import PropTypes from 'prop-types';

import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell
} from '@material-ui/core';

import { useStylesTable, TableDataContext } from '../tools/Table';

export const SingleInstanceTableDisplay = ({ columns }) => {
  const classes = useStylesTable();

  if (!columns) {
    return null;
  } else {
    return (
      <MuiTableContainer size="small" className={classes.tableWrap}>
        <MuiTable
          className="instanceEditorTable"
          aria-label="instance-editor-table"
        >
          <MuiTableHead className={classes.head}>
            <MuiTableRow>
              {columns.map((column) => (
                <MuiTableCell key={column.dataId}>{column.header}</MuiTableCell>
              ))}
            </MuiTableRow>
          </MuiTableHead>
          <MuiTableBody>
            <TableDataContext.Provider value={[true, null]}>
              <MuiTableRow hover={false}>
                {columns.map((column) => (
                  <MuiTableCell key={column.dataId}>
                    {column.content}
                  </MuiTableCell>
                ))}
              </MuiTableRow>
            </TableDataContext.Provider>
          </MuiTableBody>
        </MuiTable>
      </MuiTableContainer>
    );
  }
};
SingleInstanceTableDisplay.propTypes = {
  columns: PropTypes.array,
  orientation: PropTypes.any,
  isMultipleData: PropTypes.bool
};
