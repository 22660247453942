import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

import { Box, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TreeSelect, {
  BranchNode,
  ValueNode,
  defaultInput
} from 'mui-tree-select';

import { TableDataContext } from './Table';
import { TabDataContext } from './Tabs';
import SelectedWrap from './SelectedWrap';
import { contentTypes, setContentDefs } from '../DataSourceContent';
import MissingDefinition from './MissingDefinition';

export const BulkDataTypes = {
  media: 'media',
  media_group: 'media_group_'
};

export const MediaEditorSelector = ({ mediaType, bulkDataType }) => {
  const [isInTableContext, changeTableColumnDataCategory] =
    useContext(TableDataContext);
  const [isInTabContext, addTabDataRequirement] = useContext(TabDataContext);
  const { t } = useTranslation();

  const {
    connectors: { connect, drag },
    actions: { setProp: setNodeProps },
    id: nodeId
  } = useNode();
  const {
    isSelected,
    query: { node: nodeMap }
  } = useEditor((state) => ({
    isSelected: state.events.selected === nodeId
  }));
  const parentId = nodeMap(nodeId)?.get().data.parent;

  const [isSelectModalOpen, setSelectModalOpen] = useState(false);
  const [mediumDefs, setMediumDefs] = useState(null);
  const mediumDef = mediumDefs?.find((def) => def.value === mediaType);

  let label;
  if (bulkDataType === BulkDataTypes.media) {
    label = t('tabeditor.tools.mediaEditor.bulkData');
  } else if (
    bulkDataType &&
    bulkDataType.startsWith(BulkDataTypes.media_group)
  ) {
    label = t('tabeditor.tools.mediaEditor.bulkDataMediaGroup', {
      groupId: bulkDataType.split(BulkDataTypes.media_group)[1]
    });
  } else {
    label =
      mediumDef?.label ||
      mediaType ||
      t('tabeditor.tools.mediaEditor.labelPlaceholder');
  }

  useEffect(() => {
    setContentDefs(nodeMap, nodeId, setMediumDefs, contentTypes.Medium);
  }, []);

  useEffect(() => {
    if (isInTableContext) {
      const tableColumnDataId = nodeMap(parentId)?.get().data.props.dataId;

      const tableColumnDataCategory = {};
      if (mediaType) {
        tableColumnDataCategory.mediaType = mediaType;
      } else if (bulkDataType) {
        tableColumnDataCategory.bulkDataType = bulkDataType;
      }
      tableColumnDataCategory.label = label;

      changeTableColumnDataCategory(tableColumnDataId, tableColumnDataCategory);
    }

    if (isInTabContext) {
      const dataSource = nodeMap(parentId)?.get().data.props.dataSource?.source;
      let dataTypeId;
      if (mediaType) {
        dataTypeId = mediaType;
      } else if (bulkDataType) {
        dataTypeId = 'bulkDataType_' + bulkDataType;
      }

      addTabDataRequirement(dataSource, dataTypeId, contentTypes.Medium);
    }
  }, [mediaType, bulkDataType, label]);

  useEffect(() => {
    if (!mediaType && !bulkDataType) {
      setSelectModalOpen(true);
    }
  }, [parentId]);

  return (
    <div ref={(ref) => connect(drag(ref))} className="editorMockup mediaEditor">
      <SelectedWrap isSelected={isSelected}>
        {!isInTableContext && (
          <div className="linkdedInstancesEditorTitle editorTitle">
            <h2>
              <span>{label}</span>
            </h2>
            <button
              disabled
              className="btn btn-success addLinkedInstance"
              type="button"
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
        )}
        {isSelectModalOpen && !(mediaType && !mediumDef) && (
          <SelectElement
            mediumDefs={mediumDefs}
            setNodeProps={setNodeProps}
            mediaType={mediaType}
            bulkDataType={bulkDataType}
            setSelectModalOpen={setSelectModalOpen}
          ></SelectElement>
        )}
        <div className="allImages">
          <Box display="flex" justifyContent="center" alignItems="center">
            {mediaType && !mediumDef ? (
              <MissingDefinition defTitle={mediaType} />
            ) : (
              <Icon className="fa fa-picture-o" />
            )}
          </Box>
        </div>
      </SelectedWrap>
    </div>
  );
};
MediaEditorSelector.propTypes = {
  mediaType: PropTypes.any,
  bulkDataType: PropTypes.any
};

const useStylesSelect = makeStyles(() => ({
  bulkData: {
    textDecoration: 'underline'
  },
  outerPopper: {
    width: 'auto !important'
  }
}));

const SelectElement = ({
  mediumDefs,
  setNodeProps,
  mediaType,
  bulkDataType,
  setSelectModalOpen
}) => {
  const { t } = useTranslation();
  const classes = useStylesSelect();
  const isAutomaticOpen = setSelectModalOpen ? true : null;

  const mediaGroups = {};
  mediumDefs?.forEach((def) => {
    if (def.groupId) {
      let group = mediaGroups[def.groupId];
      if (!group) {
        group = [];
        mediaGroups[def.groupId] = group;
      }
      group.push(def);
    }
  });
  const hasGroups = Object.keys(mediaGroups).length > 0;

  const [treeState, setTreeState] = useState([]);
  const [branch, setBranch] = useState(null);

  let valueNode = null;
  let valueNodeDef = null;

  if (mediaType) {
    valueNodeDef = mediumDefs?.find(
      (mediumDef) => mediumDef.value === mediaType
    );
  } else if (bulkDataType) {
    if (bulkDataType === BulkDataTypes.media) {
      valueNodeDef = {
        value: BulkDataTypes.media,
        label: t('tabeditor.tools.mediaEditor.bulkData')
      };
    } else if (bulkDataType.startsWith(BulkDataTypes.media_group)) {
      valueNodeDef = {
        value: bulkDataType,
        label: t('tabeditor.tools.mediaEditor.bulkDataMediaGroup', {
          groupId: bulkDataType.split(BulkDataTypes.media_group)[1]
        })
      };
    }
  }

  if (valueNodeDef) {
    valueNode = new ValueNode(valueNodeDef);
  }

  useEffect(() => {
    const rootOptions = getRootOptions();
    setTreeState(rootOptions);
  }, [JSON.stringify([].concat(mediumDefs))]);

  function getRootOptions() {
    let rootOptions = [];
    if (!mediumDefs) return rootOptions;

    if (!hasGroups) {
      rootOptions = getAllMediaOptions();
    } else {
      rootOptions.push(
        new BranchNode({
          value: 'allMediaNode',
          label: t('tabeditor.tools.mediaEditor.allMedia')
        })
      );

      for (let groupId in mediaGroups) {
        rootOptions.push(
          new BranchNode({
            value: groupId,
            label: groupId
          })
        );
      }
    }

    return rootOptions;
  }

  function getAllMediaOptions() {
    let options = [];
    options.push({
      value: BulkDataTypes.media,
      label: t('tabeditor.tools.mediaEditor.bulkData')
    });

    if (mediumDefs) {
      options.push(...mediumDefs);
    }

    return options;
  }

  function getOptionLabel(option) {
    if (option.valueOf().label) {
      return option.valueOf().label;
    } else {
      return '-';
    }
  }

  function changeBranch(_, selectedBranch) {
    if (selectedBranch) {
      let options = [];
      const branchId = selectedBranch.valueOf().value;

      if (branchId === 'allMediaNode' && mediumDefs) {
        options = getAllMediaOptions();

        setBranch(selectedBranch);
      } else if (branchId in mediaGroups) {
        const groupId = branchId;
        options.push({
          value: BulkDataTypes.media_group + groupId,
          label: t('tabeditor.tools.mediaEditor.bulkDataMediaGroup', {
            groupId: groupId
          })
        });
        options.push(...mediaGroups[groupId]);
        setBranch(selectedBranch);
      }

      setTreeState(options);
    } else {
      const rootOptions = getRootOptions();

      setTreeState(rootOptions);
      setBranch(null);
    }
  }

  function changeMediumType(_, newValueNode) {
    const newValue = newValueNode?.valueOf().value;
    if (!newValue) return;

    let selectedBulkDataType = null;
    let selectedMediumType = null;

    if (newValue === BulkDataTypes.media) {
      selectedBulkDataType = newValue;
    } else if (newValue.startsWith(BulkDataTypes.media_group)) {
      selectedBulkDataType = newValue;
    } else if (mediumDefs?.find((def) => def.value === newValue)) {
      selectedMediumType = newValue;
    }

    setNodeProps((props) => {
      props.mediaType = selectedMediumType;
      props.bulkDataType = selectedBulkDataType;
    });

    if (setSelectModalOpen) {
      setSelectModalOpen(false);
    }
  }

  function closeOnBlur() {
    if (setSelectModalOpen) {
      setSelectModalOpen(false);
    }
  }

  var inputProps = {};
  if (isAutomaticOpen) {
    inputProps.open = isAutomaticOpen;
    inputProps.classes = {
      popper: classes.outerPopper
    };
  }

  return (
    <TreeSelect
      style={isAutomaticOpen ? { opacity: 0, width: 0, height: 0 } : null}
      {...inputProps}
      onBranchChange={changeBranch}
      options={treeState}
      branch={branch}
      value={valueNode}
      onChange={changeMediumType}
      onClose={closeOnBlur}
      getOptionLabel={getOptionLabel}
      renderInput={useCallback(
        (params) =>
          defaultInput({
            ...params,
            variant: 'outlined',
            label: t('tabeditor.tools.mediaEditor.type')
          }),
        []
      )}
    />
  );
};

export const MediaEditorSelectorSettings = () => {
  const [mediumDefs, setMediumDefs] = useState(null);
  const {
    actions: { setProp: setNodeProps },
    id: nodeId,
    props: nodeProps
  } = useNode((node) => ({
    props: node.data.props
  }));
  const {
    query: { node: nodeMap }
  } = useEditor();

  useEffect(() => {
    setContentDefs(nodeMap, nodeId, setMediumDefs, contentTypes.Medium);
  }, []);

  return (
    <SelectElement
      mediumDefs={mediumDefs}
      setNodeProps={setNodeProps}
      mediaType={nodeProps.mediaType}
      bulkDataType={nodeProps.bulkDataType}
    />
  );
};

export const MediaEditorSelectorDefaultProps = {
  mediaType: ''
};
MediaEditorSelector.craft = {
  props: MediaEditorSelectorDefaultProps,
  related: {
    settings: MediaEditorSelectorSettings
  }
};
