function ajaxGet(url, params, returnCall) {
  const ajaxData = {
    response: null,
    error: null
  };

  for (let param in params) {
    let paramVal = params[param];
    if (paramVal === null) {
      delete params[param];
    }
  }

  let call;
  if (returnCall) {
    call = $.ajax({
      method: 'get',
      url: url,
      data: params
    });
  } else {
    call = $.ajax({
      method: 'get',
      url: url,
      data: params
    })
      .done(function (response) {
        if (response.success) {
          ajaxData.response = response;
        } else {
          TOGO.Util.notify('', response.message, 5000, true);
          ajaxData.error = response.message;
        }
      })
      .fail(function (jqxhr, textStatus, errorThrown) {
        let errorMsg = TOGO.callbacks.defaultAjaxErrorHandler(
          jqxhr,
          textStatus,
          errorThrown
        );
        ajaxData.error = errorMsg;

        if (jqxhr.status === 423) {
          ajaxData.instanceLocked = true;
        }
      });
  }

  if (returnCall) {
    return call;
  }

  const returnAjaxData = () => {
    return ajaxData;
  };

  return call.then(returnAjaxData, returnAjaxData);
}

export default ajaxGet;
