import React, { useState } from "react";
import {
	IconButton, MenuItem, Select, makeStyles, span,Tooltip
} from "@material-ui/core";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiInputBase-input": {
			padding: "0px ​16px 0px 1px", 
			"& .MuiSelect-select.MuiSelect-select": {
				padding: 0,
				paddingLeft: 25
				},
				"& .MuiInputBase-input": {
				padding: 0,
				paddingLeft: 1
			}
		}
	},
	details: {
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		flex: '1 0 auto',
	},
	btn: {
		padding: '5px',
		margin: '12px'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(1),
	},
	playIcon: {
		height: 38,
		width: 38,
	},
	labelPagination:{
		fontSize: "14px",
		padding: "10px"
	}
}));

function Pagination({paginationstate, gotoPage ,previousPage,
		nextPage, handleChangeElementsPerPage, handleChangeCurrentPage, currentPage ,elementsPerPage}) {

	// callback: null
	// countElements: 13
	// countPages: 2
	// currentPage: 1
	// elementsPerPage: 10
	// elementsPerPageOptions: (3) [10, 50, 100]
	// endIndexOfCurPage: 10
	// firstIndexOfCurPage: 0
	// firstPageCheck: true
	// lastPageCheck: false
	// url: "/textsearch?searchTerm="
	


	//{paginationElement.currentPage eq paginationElement.countPages ? ' disabled' : ''}
	//{paginationElement.currentPage eq 1 ? ' disabled' : ''}

	const classes = useStyles();
	const [page, setPage] = useState();
	//const [pageOptions, setPageoption] = useState(1000);
	const [pageIndex, setPageIndex] = useState(1);
	//	const [pageCount, setPageCount] = useState(1000);
	// const [canPreviousPage, setCanPreviousPage] = useState(true);
	// const [canNextPage, setCanNextPage] = useState(true);
	const [pageSize, setPageSize] = useState(10);



	return (
		<>
			<div className="pagination">
				{paginationstate.countElements >= elementsPerPage  &&
				<div>


				<Tooltip
					title={'Erste Seite'}>
					<span style={{ display: 'inline-block' }}>
					<IconButton className={classes.btn} onClick={() => gotoPage(1)} disabled={currentPage == 1 }>
					<FirstPageIcon />
					</IconButton>
					</span>
				</Tooltip>


				<Tooltip
					title={'Vorige Seite'}>
					<span style={{ display: 'inline-block' }}>
					<IconButton className={classes.btn} onClick={() => previousPage()} disabled={currentPage == 1}>
					<KeyboardArrowLeftIcon />
					</IconButton>{' '}
					</span>
				</Tooltip>
				<span>
					<span className={classes.labelPagination}>
						Seite {'   '}
					</span>{' '}
					<Select
						style={{ padding: '0px 5px 0px 5px' }}
						className={classes.root} 
						value={currentPage}
						onChange={e => {
							const page = Number(e.target.value) ;
							handleChangeCurrentPage(page)
						}}
					>
						{Array.from({length: paginationstate.countPages}, (_, i) => i + 1).map(pageIndex => (
							<MenuItem key={pageIndex} value={pageIndex}>{pageIndex}</MenuItem>
						))}
					</Select>

					<span className={classes.labelPagination}>
						&#160;von &#160; {paginationstate.countPages}
					</span>{' '}
				</span>


				<Tooltip
					title={'Nächste Seite'}>
					<span style={{ display: 'inline-block' }}>
					<IconButton className={classes.btn} onClick={() => nextPage()} disabled={currentPage == paginationstate.countPages}>
						<KeyboardArrowRightIcon />
					</IconButton>{' '}
					</span>
				</Tooltip>
				<Tooltip
					title={'Letzte Seite'}>
					<span style={{ display: 'inline-block' }}>
					<IconButton className={classes.btn} onClick={() => gotoPage(paginationstate.countPages)} disabled={currentPage == paginationstate.countPages}>
					<LastPageIcon />
				</IconButton>{' '}
					</span>
				</Tooltip>
				</div>}
				<span>
					Elemente pro Seite
				</span>{' '}
				<Select
					style={{ padding: '0px 5px 0px 5px' }}
					className={classes.root} 
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={elementsPerPage}
					onChange={e => {
						if ( paginationstate.endIndexOfCurPage <= elementsPerPage ){
							handleChangeElementsPerPage(Number(e.target.value));
						}
					}}>
					{[10,50,100].map(pageSize => (
						<MenuItem key={pageSize} value={pageSize}>{pageSize}</MenuItem>
					))}
				</Select>
			</div>
		</>
	)
}

export default Pagination
