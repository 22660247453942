import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Popover } from 'react-tiny-popover';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/core/styles';

import theme from '../PimTogoTheme';
import PimSpinner from '../components/common/PimSpinner';

import ajaxGet from '../../services/ajaxGet.js';
import ajaxPost from '../../services/ajaxPost.js';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  errorText: {
    color: 'red',
    fontSize: '10px'
  },
  emailList: {
    maxWidth: '30%',
    backgroundColor: '#ebebeb',
    marginTop: '3rem'
  }
}));

export default function CustomerAdresses() {
  const classes = useStyles();
  const [items, setItems] = useState([]);
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPopoverOpen, setIsPopoverOpen] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    ajaxGet('customersAddressesJson').then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setItems(ajaxData.response.items);
      }
      setError(ajaxData.error);
      setLoading(false);
    });
  }, []);

  function onNewEmailChange(event) {
    setNewEmail(event.target.value);
  }

  function onAdd() {
    ajaxPost('customersAddresses/addCustomerAddress', { email: newEmail }).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          setItems(items.concat(ajaxData.response));
        }
        setError(ajaxData.error);
        setLoading(false);
      }
    );

    setNewEmail('');
    setDisabled(true);
  }

  function onDelete(id) {
    ajaxPost('customersAddresses/deleteCustomerAddress', { id: id }).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          setItems(items.filter((item) => item.id !== id));
        }
        setError(ajaxData.error);
        setLoading(false);
      }
    );

    setItems(items.filter((item) => item.id !== id));
  }

  function verifyEmail(newEmail) {
    const validateEmailAddressRegEx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;

    if (validateEmailAddressRegEx.test(newEmail)) {
      setIsPopoverOpen(false);
      setDisabled(false);
    } else {
      setIsPopoverOpen(true);
      setDisabled(true);
    }
  }

  if (error) {
    return <div>Error: {error}</div>;
  } else if (loading) {
    return <PimSpinner />;
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
          <div>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <Icon className="fa fa-envelope-o" aria-hidden="true" />
              </Grid>
              <Grid item>
                <Popover
                  isOpen={isPopoverOpen}
                  positions={['bottom']}
                  content={
                    <div className={classes.errorText}>
                      {t('customerAdresses.noValidEmail')}
                    </div>
                  }
                  reposition={false}
                  padding={2}
                >
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    type="email"
                    value={newEmail}
                    onChange={onNewEmailChange}
                    onInput={() => {
                      verifyEmail(newEmail);
                    }}
                  />
                </Popover>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onAdd}
                  disabled={disabled}
                  color="primary"
                >
                  <Icon className="fa fa-plus" aria-hidden="true" />
                </Button>
              </Grid>
            </Grid>
          </div>
          <div className={classes.emailList}>
            <List dense={true}>
              {items.map((item) => (
                <ListItem key={item.id}>
                  <ListItemAvatar>
                    <Avatar>
                      <Icon className="fa fa-envelope" aria-hidden="true" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.email} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onDelete(item.id)}
                    >
                      <Icon className="fa fa-trash" aria-hidden="true" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </ThemeProvider>
    );
  }
}
