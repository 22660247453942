import React, { useContext, useState, useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

import { Slider, FormControl, FormLabel } from '@material-ui/core';
import ContentEditable from 'react-contenteditable';

import { TabEditorContext } from '../TabEditor';
import { useStylesSelected } from './SelectedWrap';
import sanitizeFromXML from '../../../../util/sanitizeFromXML';

export const initTitles = { de: 'Überschrift', en: 'Headline', it: 'Titolo', fr: 'Titre' };

export const Title = ({ text, fontSize, textAlign }) => {
  const {
    connectors: { connect, drag },
    selectedNode,
    id,
    actions: { setProp }
  } = useNode((state) => ({
    selectedNode: state.events.selected
  }));
  const { isSelected } = useEditor((state) => ({
    isSelected: state.events.selected === id
  }));
  const selectedClasses = useStylesSelected();
  const [lang] = useContext(TabEditorContext);

  const [editable, setEditable] = useState(false);

  useEffect(() => {
    if (selectedNode) {
      return;
    }

    setEditable(false);
  }, [selectedNode]);

  return (
    <div
      className={isSelected ? selectedClasses.selected : null}
      ref={(ref) => connect(drag(ref))}
      onClick={() => selectedNode && setEditable(true)}
    >
      <ContentEditable
        html={text[lang]}
        disabled={!editable}
        onChange={(e) =>
          setProp(
            (props) => (props.text[lang] = sanitizeFromXML(e.target.value)),
            500
          )
        }
        tagName="p"
        style={{ fontSize: `${fontSize}px`, textAlign }}
      />
    </div>
  );
};

const TitleSettings = () => {
  const { t } = useTranslation();
  const {
    actions: { setProp },
    fontSize
  } = useNode((node) => ({
    text: node.data.props.text,
    fontSize: node.data.props.fontSize
  }));

  return (
    <>
      <FormControl size="small" component="fieldset">
        <FormLabel component="legend">
          {t('tabeditor.settings.fontSize')}
        </FormLabel>
        <Slider
          value={fontSize || 7}
          step={7}
          min={1}
          max={50}
          onChange={(_, value) => {
            setProp((props) => (props.fontSize = value), 1000);
          }}
        />
      </FormControl>
    </>
  );
};

export const TitleDefaultProps = {
  text: initTitles,
  fontSize: 20
};

Title.craft = {
  props: TitleDefaultProps,
  related: {
    settings: TitleSettings
  }
};
