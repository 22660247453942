import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  IconButton,
  Container,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  DialogTitle,
  Dialog
} from '@material-ui/core';
import { InputAdornment, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PaginationSFTPComponent from './PaginationSFTPConnectionConfiguration';
import ajaxGet from 'src/services/ajaxGet';
import EnhancedTableSFTP from './EnhancedTableSFTP';
import theme, { usePimStyles } from '../PimTogoTheme';
// import PersonAddAltIcon from '@material-ui/icons/PersonAddAlt';
import PersonAddAltIcon from '@material-ui/icons/PersonAdd';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CreateSFTPConnectionConfiguration from './CreateSFTPConnectionConfiguration';


const useStyles = makeStyles(() => ({
  root: {
    padding: '5px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titel: {
    backgroundColor: ' #f29528',
    color: '#08080 !important',
    margin: '0px'
  },
  submitButton: {
    margin: '5px !important'
  },
  editwidth: {
    width: '44rem !important'
  }
}));

const INITIAL_FORM_STATE_SFTP_CONNECTION_CONFIG = {
  id:'',
  serverName : '',
  serverURL: '',
  downloadFolder: '',
  user:'',
  password: '',
  privateKey: '',
};

export default function SFTPConnectionConfiguration() {
  const classes = useStyles();
  const classesPim = usePimStyles();
  const { t } = useTranslation();

  const [searchExpression, setSearchExpression] = useState('');
  const [sftpConnectionConfigurations, setSftpConnectionConfigurations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(5);
  const [paginationstate, setPaginationstate] = useState({});
  const [openDialogSFTPConnectionConfiguration, setOpenDialogSFTPConnectionConfiguration] = useState(false);
  const [toCreateSFTPConnectionConfiguration, setToCreateSFTPConnectionConfiguration] = useState(true);
  // const [sftpConnectionConfigurationList, setSftpConnectionConfigurationList] = useState({});
  const [initFormSFTPConnectionConfigurationState, setInitFormSFTPConnectionConfigurationState] = useState(
    INITIAL_FORM_STATE_SFTP_CONNECTION_CONFIG
  );
  
  const handelEventSearchSFTPConnectionConfigurations = () => {
    setCurrentPage(1);
    setElementsPerPage(5);
    searchsftpConnectionConfigurations();
  };

  const searchsftpConnectionConfigurations = (showFirstUserPage) => {

    let targetPage = currentPage;
    if (showFirstUserPage) {
      targetPage = 1;
    }

    ajaxGet('sftpConnectionConfigManagement/search', {
      searchExpression: searchExpression,
      currentPage: targetPage,
      elementsPerPage: elementsPerPage
    }).then((ajaxData) => {
      const response = ajaxData.response;
      if (!ajaxData.error && response) {
        let sftpConnectionConfigViewList = response.sftpConnectionConfigurations;
        setSftpConnectionConfigurations(sftpConnectionConfigViewList);
        setPaginationstate(response.pagination);
      }
    });
  };

  useEffect(() => {
    searchsftpConnectionConfigurations();
  }, [currentPage, elementsPerPage]);

  const handleChange = (ev) => {
    setSearchExpression(ev.target.value);
  };

  function handleChangeCurrentPage(newValue) {
    setCurrentPage(newValue);
  }

  function handleChangeElementsPerPage(newValue) {
    setCurrentPage(1);
    setElementsPerPage(newValue);
  }

  const gotoPage = (val) => {
    setCurrentPage(val);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClickOpenDialogSFTPconnectionConfig = () => {
    setOpenDialogSFTPConnectionConfiguration(true);
  };

  const addUserhandel = () => {
    setOpenDialogSFTPConnectionConfiguration(true);
    const sftpConnectionConfigurationForm = {
      id:'',
      serverName: '',
      serverURL: '',
      downloadFolder:'',
      user: '',
      password: '',
      privateKey: '',
    };

    setInitFormSFTPConnectionConfigurationState(sftpConnectionConfigurationForm);
  };

  const handleCloseDialogSFTPConnectionConfiguration = (event, reason) => {
    if (reason && (reason == 'backdropClick' || reason == 'escapeKeyDown'))
      return;
      setOpenDialogSFTPConnectionConfiguration(false);
      setToCreateSFTPConnectionConfiguration(true);
      setInitFormSFTPConnectionConfigurationState(INITIAL_FORM_STATE_SFTP_CONNECTION_CONFIG);
    
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setCurrentPage(1);
      setElementsPerPage(5);
      searchsftpConnectionConfigurations();
    }
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'black',
      background: '#ebddbad1',
      border: '0.5px solid',
      '&:hover': {
        //you want this to be the same as the backgroundColor above
        backgroundColor: '#625c5652'
      }
    }
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Grid className={classes.root}>
          <form>
            <TextField
              label={t('manageOrganization.organization.search')}
              margin="normal"
              id="user-name-search"
              fullWidth
              value={searchExpression}
              onChange={handleChange}
              onKeyDown={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => handelEventSearchSFTPConnectionConfigurations()}
                    position="start"
                  >
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </form>
        </Grid>
        <Grid className={classes.root}>
          {true && (
            <PaginationSFTPComponent
              paginationstate={paginationstate}
              searchExpression={searchExpression}
              isOrganization={false}
              gotoPage={gotoPage}
              previousPage={previousPage}
              nextPage={nextPage}
              currentPage={currentPage}
              elementsPerPage={elementsPerPage}
              handleChangeCurrentPage={handleChangeCurrentPage}
              handleChangeElementsPerPage={handleChangeElementsPerPage}
            />
          )}
        </Grid>
        {'   '}
        <Grid className={classes.root}>
          <Button
            variant="outlined"
            onClick={() => {
              setToCreateSFTPConnectionConfiguration(true);
              addUserhandel();
            }}
            startIcon={<PersonAddAltIcon />}
            className={`${classesPim.addAction} ${classes.submitButton}`}
          >
            <Typography fontSize={5}>{t('managUsersSFTP.createTitle')} </Typography>
          </Button>

          <CreateSFTPConnectionConfiguration
            openDialogSFTPConnectionConfiguration={openDialogSFTPConnectionConfiguration}
            handleCloseDialogSFTPConnectionConfiguration={handleCloseDialogSFTPConnectionConfiguration}
            initFormSFTPConnectionConfigurationState={initFormSFTPConnectionConfigurationState}
            toCreateSFTPConnectionConfiguration={toCreateSFTPConnectionConfiguration}
            searchsftpConnectionConfigurations={searchsftpConnectionConfigurations}
          />

          <EnhancedTableSFTP
            sftpConnectionConfigurations={sftpConnectionConfigurations}
            setInitFormSFTPConnectionConfigurationState={setInitFormSFTPConnectionConfigurationState}
            setSftpConnectionConfigurations={setSftpConnectionConfigurations}
            setToCreateSFTPConnectionConfiguration={setToCreateSFTPConnectionConfiguration}
            handleClickOpenDialogSFTPconnectionConfig={handleClickOpenDialogSFTPconnectionConfig}
            searchsftpConnectionConfigurations={searchsftpConnectionConfigurations}
          />
        </Grid>
      </ThemeProvider>
    </div>
  );
}
