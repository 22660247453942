import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TreeSelect, {
  BranchNode,
  ValueNode,
  defaultInput
} from 'mui-tree-select';

import { TableDataContext } from './Table';
import { TabDataContext } from './Tabs';
import SelectedWrap from './SelectedWrap';
import MissingDefinition from './MissingDefinition';
import { contentTypes, setContentDefs } from '../DataSourceContent';
import { useTitleStyles } from '../../datamanagement/featurecomponents/FeatureComponent';

export const BulkDataTypes = {
  general_fields: 'general_fields',
  features: 'features',
  custom_fields: 'custom_fields',
  custom_fields_group: 'custom_fields_group_'
};

export const FieldEditorSelector = ({
  customFieldType,
  generalFieldType,
  bulkDataType
}) => {
  const [isInTableContext, changeTableColumnDataCategory] =
    useContext(TableDataContext);
  const [isInTabContext, addTabDataRequirement] = useContext(TabDataContext);
  const { t } = useTranslation();
  const classesTitle = useTitleStyles();

  const {
    connectors: { connect, drag },
    actions: { setProp: setNodeProps },
    id: nodeId
  } = useNode();
  const {
    isSelected,
    query: { node: nodeMap }
  } = useEditor((state) => ({
    isSelected: state.events.selected === nodeId
  }));
  const parentId = nodeMap(nodeId)?.get().data.parent;

  const [isSelectModalOpen, setSelectModalOpen] = useState(false);
  const [generalFieldDefs, setGeneralFieldDefs] = useState(null);
  const [customFieldDefs, setCustomFieldDefs] = useState(null);

  const generalFieldDef = generalFieldDefs?.find(
    (def) => def.value === generalFieldType
  );
  const customFieldDef = customFieldDefs?.find(
    (def) => def.value === customFieldType
  );

  let label;
  if (bulkDataType === BulkDataTypes.general_fields) {
    label = t('tabeditor.tools.fieldEditor.bulkDataGeneralFields');
  } else if (bulkDataType === BulkDataTypes.features) {
    label = t('tabeditor.tools.fieldEditor.bulkDataFeatures');
  } else if (bulkDataType === BulkDataTypes.custom_fields) {
    label = t('tabeditor.tools.fieldEditor.bulkDataCustomFields');
  } else if (
    bulkDataType &&
    bulkDataType.startsWith(BulkDataTypes.custom_fields_group)
  ) {
    label = t('tabeditor.tools.fieldEditor.bulkDataFieldGroup', {
      groupId: bulkDataType.split(BulkDataTypes.custom_fields_group)[1]
    });
  } else {
    label =
      generalFieldDef?.label ||
      generalFieldType ||
      customFieldDef?.label ||
      customFieldType ||
      t('tabeditor.tools.fieldEditor.labelPlaceholder');
  }

  useEffect(() => {
    setContentDefs(
      nodeMap,
      nodeId,
      setGeneralFieldDefs,
      contentTypes.GeneralField
    );
    setContentDefs(
      nodeMap,
      nodeId,
      setCustomFieldDefs,
      contentTypes.CustomField
    );
  }, []);

  useEffect(() => {
    if (isInTableContext) {
      const tableColumnDataId = nodeMap(parentId)?.get().data.props.dataId;

      const tableColumnDataCategory = {};
      if (generalFieldType) {
        tableColumnDataCategory.generalFieldType = generalFieldType;
      } else if (customFieldType) {
        tableColumnDataCategory.customFieldType = customFieldType;
      } else if (bulkDataType) {
        tableColumnDataCategory.bulkDataType = bulkDataType;
      }
      tableColumnDataCategory.label = label;

      changeTableColumnDataCategory(tableColumnDataId, tableColumnDataCategory);
    }

    if (isInTabContext) {
      const dataSource = nodeMap(parentId)?.get().data.props.dataSource?.source;
      let dataTypeId;
      let dataReqCategory;
      if (customFieldType) {
        dataTypeId = customFieldType;
        dataReqCategory = contentTypes.CustomField;
      } else if (generalFieldType && generalFieldType != 'articleNumber') {
        dataTypeId = generalFieldType;
        dataReqCategory = contentTypes.GeneralField;
      } else if (bulkDataType) {
        dataTypeId = 'bulkDataType_' + bulkDataType;

        if (bulkDataType === BulkDataTypes.features) {
          dataReqCategory = contentTypes.Features;
        } else if (
          bulkDataType === BulkDataTypes.custom_fields ||
          bulkDataType.startsWith(BulkDataTypes.custom_fields_group)
        ) {
          dataReqCategory = contentTypes.CustomField;
        }
      }

      addTabDataRequirement(dataSource, dataTypeId, dataReqCategory);
    }
  }, [generalFieldType, customFieldType, bulkDataType, label]);

  useEffect(() => {
    if (!customFieldType && !generalFieldType && !bulkDataType) {
      setSelectModalOpen(true);
    }
  }, [parentId]);

  return (
    <SelectedWrap isSelected={isSelected} addClassName="editorMockup">
      <div
        ref={(ref) => connect(drag(ref))}
        className="customField fieldComponent"
      >
        <div className="inputFeatureCustomFieldWrap">
          {isSelectModalOpen && !(customFieldType && !customFieldDef) && (
            <SelectElement
              generalFieldDefs={generalFieldDefs}
              customFieldDefs={customFieldDefs}
              setNodeProps={setNodeProps}
              customFieldType={customFieldType}
              generalFieldType={generalFieldType}
              bulkDataType={bulkDataType}
              setSelectModalOpen={setSelectModalOpen}
            ></SelectElement>
          )}

          {customFieldType && !customFieldDef ? (
            <MissingDefinition defTitle={customFieldType} />
          ) : (
            <TextField
              label={isInTableContext ? null : label}
              InputLabelProps={{
                shrink: false,
                disableAnimation: true,
                className: classesTitle.titleSelect
              }}
              InputProps={{
                readOnly: true
              }}
              size="small"
              variant={isInTableContext ? 'standard' : 'outlined'}
              value={t('tabeditor.tools.fieldEditor.exampleValue')}
            />
          )}
        </div>
      </div>
    </SelectedWrap>
  );
};
FieldEditorSelector.propTypes = {
  customFieldType: PropTypes.any,
  generalFieldType: PropTypes.any,
  bulkDataType: PropTypes.string
};

const useStylesSelect = makeStyles(() => ({
  bulkData: {
    textDecoration: 'underline'
  },
  outerPopper: {
    width: 'auto !important'
  }
}));

const SelectElement = ({
  generalFieldDefs,
  customFieldDefs,
  setNodeProps,
  generalFieldType,
  customFieldType,
  bulkDataType,
  setSelectModalOpen
}) => {
  const { t } = useTranslation();
  const classes = useStylesSelect();
  const isAutomaticOpen = setSelectModalOpen ? true : null;

  const featuresDef = generalFieldDefs?.find(
    (generalFieldDef) => generalFieldDef.value === BulkDataTypes.features
  );
  const keywordsDef = generalFieldDefs?.find(
    (generalFieldDef) => generalFieldDef.value === 'keyword'
  );
  const hasGeneralFieldDefs =
    generalFieldDefs?.length > 0 &&
    !(generalFieldDefs.length == 1 && keywordsDef);
  const hasCustomFieldDefs = customFieldDefs?.length > 0;

  const customFieldGroups = {};
  customFieldDefs?.forEach((def) => {
    if (def.groupId) {
      let group = customFieldGroups[def.groupId];
      if (!group) {
        group = [];
        customFieldGroups[def.groupId] = group;
      }
      group.push(def);
    }
  });

  for (let groupId in customFieldGroups) {
    let groupCustomFieldDefs = customFieldGroups[groupId];
    groupCustomFieldDefs.sort((a, b) => {
      if (a.groupOrder === b.groupOrder) {
        return 0;
      }
      // nulls sort after anything else
      else if (a.groupOrder === null) {
        return 1;
      } else if (b.groupOrder === null) {
        return -1;
      } else {
        return a.groupOrder > b.groupOrder ? 1 : -1;
      }
    });
  }

  const customFieldBranch = new BranchNode({
    value: 'customfieldNode',
    label: t('tabeditor.tools.fieldEditor.customFields')
  });

  const [treeState, setTreeState] = useState([]);
  const [branch, setBranch] = useState(null);
  const [lastBranch, setLastBranch] = useState(null);

  let valueNode = null;
  let valueNodeDef = null;
  let valueNodeBranchNode = null;
  if (generalFieldType) {
    valueNodeDef = generalFieldDefs?.find(
      (generalFieldDef) => generalFieldDef.value === generalFieldType
    );
  } else if (customFieldType) {
    valueNodeDef = customFieldDefs?.find(
      (customFieldDef) => customFieldDef.value === customFieldType
    );
    valueNodeBranchNode = customFieldBranch;
  } else if (bulkDataType) {
    if (bulkDataType === BulkDataTypes.features) {
      valueNodeDef = featuresDef;
    } else if (bulkDataType === BulkDataTypes.custom_fields) {
      valueNodeDef = {
        value: BulkDataTypes.custom_fields,
        label: t('tabeditor.tools.fieldEditor.bulkDataCustomFields')
      };
    } else if (bulkDataType.startsWith(BulkDataTypes.custom_fields_group)) {
      valueNodeDef = {
        value: bulkDataType,
        label: t('tabeditor.tools.fieldEditor.bulkDataFieldGroup', {
          groupId: bulkDataType.split(BulkDataTypes.custom_fields_group)[1]
        })
      };
    }
  }

  if (valueNodeDef) {
    valueNode = new ValueNode(valueNodeDef, valueNodeBranchNode);
  }

  useEffect(() => {
    const rootOptions = getRootOptions();
    setTreeState(rootOptions);
  }, [JSON.stringify([].concat(generalFieldDefs).concat(customFieldDefs))]);

  function getRootOptions() {
    let rootOptions = [];
    if (hasGeneralFieldDefs) {
      rootOptions = rootOptions.concat(
        generalFieldDefs.filter(
          (generalFieldDef) =>
            ![BulkDataTypes.features, 'keyword'].includes(generalFieldDef.value)
        )
      );
    }
    if (keywordsDef) {
      rootOptions.push(keywordsDef);
    }
    if (featuresDef) {
      rootOptions.push(
        new BranchNode({
          value: 'featuresNode',
          label: t('data.features')
        })
      );
    }
    if (hasCustomFieldDefs) {
      rootOptions.push(
        new BranchNode({
          value: 'customfieldsOverviewNode',
          label: t('tabeditor.tools.fieldEditor.customFields')
        })
      );
    }

    return rootOptions;
  }

  function getOptionLabel(option) {
    if (option.valueOf().label) {
      return option.valueOf().label;
    } else {
      return '-';
    }
  }

  function changeBranch(_, selectedBranch) {
    const targetBranch = selectedBranch || lastBranch;

    if (targetBranch) {
      let options = [];
      const branchId = targetBranch.valueOf().value;

      if (branchId === 'featuresNode' && featuresDef) {
        options.push(featuresDef);

        setBranch(targetBranch);
        setLastBranch(null);
      } else if (branchId === 'customfieldsOverviewNode') {
        options.push(
          new BranchNode({
            value: 'customfieldsNode',
            label: t('tabeditor.tools.fieldEditor.customFieldsNode')
          })
        );
        for (let groupId in customFieldGroups) {
          options.push(
            new BranchNode({
              value: groupId,
              label: groupId
            })
          );
        }

        setBranch(targetBranch);
        setLastBranch(null);
      } else if (branchId === 'customfieldsNode' && customFieldDefs) {
        options.push({
          value: BulkDataTypes.custom_fields,
          label: t('tabeditor.tools.fieldEditor.bulkDataCustomFields')
        });
        options.push(...customFieldDefs);

        setBranch(selectedBranch);
        setLastBranch(branch);
      } else if (branchId in customFieldGroups) {
        const groupId = branchId;
        options.push({
          value: BulkDataTypes.custom_fields_group + groupId,
          label: t('tabeditor.tools.fieldEditor.bulkDataFieldGroup', {
            groupId: groupId
          })
        });
        options.push(...customFieldGroups[groupId]);
        setBranch(selectedBranch);
        setLastBranch(branch);
      }

      setTreeState(options);
    } else {
      const rootOptions = getRootOptions();

      setTreeState(rootOptions);
      setBranch(null);
      setLastBranch(null);
    }
  }

  function changeFieldType(_, newValueNode) {
    const newValue = newValueNode?.valueOf().value;
    if (!newValue) return;

    let selectedBulkDataType = null;
    let selectedGeneralFieldType = null;
    let selectedCustomFieldType = null;

    if (newValue in BulkDataTypes) {
      selectedBulkDataType = newValue;
    } else if (newValue.startsWith(BulkDataTypes.custom_fields_group)) {
      selectedBulkDataType = newValue;
    } else if (generalFieldDefs?.find((def) => def.value === newValue)) {
      selectedGeneralFieldType = newValue;
    } else if (customFieldDefs?.find((def) => def.value === newValue)) {
      selectedCustomFieldType = newValue;
    }

    setNodeProps((props) => {
      props.bulkDataType = selectedBulkDataType;
      props.generalFieldType = selectedGeneralFieldType;
      props.customFieldType = selectedCustomFieldType;
    });

    if (setSelectModalOpen) {
      setSelectModalOpen(false);
    }
  }

  function closeOnBlur() {
    if (setSelectModalOpen) {
      setSelectModalOpen(false);
    }
  }

  var inputProps = {};
  if (isAutomaticOpen) {
    inputProps.open = isAutomaticOpen;
    inputProps.classes = {
      popper: classes.outerPopper
    };
  }

  return (
    <TreeSelect
      style={isAutomaticOpen ? { opacity: 0, width: 0, height: 0 } : null}
      {...inputProps}
      onBranchChange={changeBranch}
      options={treeState}
      branch={branch}
      value={valueNode}
      onChange={changeFieldType}
      onClose={closeOnBlur}
      getOptionLabel={getOptionLabel}
      renderInput={useCallback(
        (params) =>
          defaultInput({
            ...params,
            variant: 'outlined',
            label: t('tabeditor.tools.fieldEditor.type')
          }),
        []
      )}
    />
  );
};

export const FieldEditorSelectorSettings = () => {
  const [generalFieldDefs, setGeneralFieldDefs] = useState(null);
  const [customFieldDefs, setCustomFieldDefs] = useState(null);

  const {
    actions: { setProp: setNodeProps },
    id,
    props: nodeProps
  } = useNode((node) => ({
    props: node.data.props
  }));
  const {
    query: { node: nodeMap }
  } = useEditor();

  useEffect(() => {
    setContentDefs(nodeMap, id, setGeneralFieldDefs, contentTypes.GeneralField);
    setContentDefs(nodeMap, id, setCustomFieldDefs, contentTypes.CustomField);
  }, []);

  return (
    <SelectElement
      generalFieldDefs={generalFieldDefs}
      customFieldDefs={customFieldDefs}
      setNodeProps={setNodeProps}
      generalFieldType={nodeProps.generalFieldType}
      customFieldType={nodeProps.customFieldType}
      bulkDataType={nodeProps.bulkDataType}
    ></SelectElement>
  );
};

export const FieldEditorSelectorDefaultProps = {
  customFieldType: '',
  generalFieldType: ''
};
FieldEditorSelector.craft = {
  props: FieldEditorSelectorDefaultProps,
  related: {
    settings: FieldEditorSelectorSettings
  }
};
