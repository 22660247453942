import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';
import { useTranslation } from 'react-i18next';
import CableRoundedIcon from '@mui/icons-material/CableOutlined';

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { t } = useTranslation();

const headCells_SFTP = [
  {
    id: 'Aktionen',
    numeric: false,
    disablePadding: false,
    label: t('manageOrganization.organization.actions')
  },
  {
    id: 'connection',
    numeric: false,
    disablePadding: false,
    label:  t('managUsersSFTP.connection') 
  },
  { id: 'serverUrl', numeric: false, disablePadding: false, label:  t('managUsersSFTP.serverUrl')  },
  { id: 'folder', numeric: false, disablePadding: false, label:  t('managUsersSFTP.downLoadFolder')  },
  { id: 'user', numeric: false, disablePadding: false, label:  t('managUsersSFTP.user')  }
];
//Verbindung Server-URL Verzeichnis

// managUsersSFTP:{
//   connection:'Verbindung',
//   serverUrl:'Server-URL',
//   folder:'Verzeichnis'
//   //Verbindung Server-URL Verzeichnis 

// }
  return (
    <>
        <TableHead>
          <TableRow>
            {headCells_SFTP.map((headCell, index) => (
              <TableCell
                key={index}
                align={headCell.numeric ? 'right' : 'left'}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTableCell-head': {
      backgroundColor: '#a9a9a975',
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    '& .MuiTableCell-body': {
      fontSize: '1.5rem'
    },
    width: '100%',
    '& .MuiSvgIcon-root': { fontSize: '3rem !important' }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750,
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  deleteBtn: {
    color: 'rgb(237 14 14 / 89%) !important'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

export default function EnhancedTableSFTP({
  sftpConnectionConfigurations,
  setInitFormSFTPConnectionConfigurationState,
  setSftpConnectionConfigurations,
  searchsftpConnectionConfigurations,
  setToCreateSFTPConnectionConfiguration,
  handleClickOpenDialogSFTPconnectionConfig
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('company');
  const { t } = useTranslation();

  const confirmDeleteSFTPConnectcion = (id) => {
    TOGO.popupManager.confirmZebraDialog(
       t('managUsersSFTP.deleteButtonConfirm'),
       deleteSFTPConnection,
      id
    );
  };



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const deleteSFTPConnection = (id) => {
    var pojo = new Object();
    pojo.sftpConnectionConfigId = id;
    ajaxPost('sftpConnectionConfigManagement/delete', pojo).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setSftpConnectionConfigurations(sftpConnectionConfigurations.filter((org) => org.id !== id));
        TOGO.Util.notifyResponse(ajaxData.response.message, false);
        searchsftpConnectionConfigurations();
      }
    });
  };

  const copieSFTPConnectionConfig = (id) => {
    getConnectionInfo(id, true);
  };

  const testSFTPConnection = (id) =>{
    var pojo = new Object();
    pojo.sftpConnectionConfigId = id;
    ajaxPost('sftpConnectionConfigManagement/testConnection', pojo).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        TOGO.Util.notifyResponse(ajaxData.response.message, false);
      }
    }); 

  }
  const getConnectionInfo = (id, bool) => {
    ajaxGet('sftpConnectionConfigManagement/edit', {
      sftpConnectionConfigId: id
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        console.log(ajaxData.response);
        var res = ajaxData.response.sftpConnectionConfigForm;
        if (res != null && res != undefined) {
          var userform = {
            id: id,
            serverName: res?.serverName || '',
            serverURL: res?.serverURL || '',
            downloadFolder: res?.downloadFolder || '',
            user: res?.user || '',
            password: res?.password || '',
            privateKey: res?.privateKey || ''
          }
          setInitFormSFTPConnectionConfigurationState(userform);
          setToCreateSFTPConnectionConfiguration(bool);
          handleClickOpenDialogSFTPconnectionConfig();
        }
      }
    });
  };
  const editSFTPConnectcion = (id) => {
    ajaxGet('sftpConnectionConfigManagement/edit', {
      sftpConnectionConfigId: id
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        var res = ajaxData.response.sftpConnectionConfigForm;
        if (res != null && res != undefined) {
          var sftpform = {
            id: id,
            serverName: res?.serverName || '',
            serverURL: res?.serverURL || '',
            downloadFolder: res?.downloadFolder || '',
            user: res?.user || '',
            password: res?.password || '',
            privateKey: res?.privateKey || ''

          };
          setInitFormSFTPConnectionConfigurationState(sftpform);
          setToCreateSFTPConnectionConfiguration(false);
          handleClickOpenDialogSFTPconnectionConfig();
        }
      }
    });
  };


  function naturalSorter(as, bs) {
    var a,
      b,
      a1,
      b1,
      i = 0,
      n,
      L,
      rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;
    if (as === bs) return 0;
    if (as == '' || as == null) return 1;
    a = as.toLowerCase().match(rx);
    if (bs == '' || bs == null) return -1;
    b = bs.toLowerCase().match(rx);
    L = a.length;
    while (i < L) {
      if (!b[i]) return 1;
      (a1 = a[i]), (b1 = b[i++]);
      if (a1 !== b1) {
        n = a1 - b1;
        if (!isNaN(n)) return n;
        return a1 > b1 ? 1 : -1;
      }
    }
    return b[i] ? -1 : 0;
  }
  function sortAlphaNum(a, b) {
    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    var aA = a.replace(reA, '');
    var bA = b.replace(reA, '');
    if (aA === bA) {
      var aN = parseInt(a.replace(reN, ''), 10);
      var bN = parseInt(b.replace(reN, ''), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }
  }

  function descendingComparator(a, b, orderBy) {
    return naturalSorter(
      a['organizationForm'][orderBy],
      b['organizationForm'][orderBy]
    );
  }

  function getComparator(order, orderBy) {
    console.log(orderBy);
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <div className={classes.root}>
      {/* <ConfirmDialog
	 handelCloseConfirmDialog={handelCloseConfirmDialog} openConfirmDialog={openConfirmDialog} deleteOrganization={deleteOrganization}/> */}
      {sftpConnectionConfigurations.length > 0 ? (
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="simple table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
                <TableBody>
                  {sftpConnectionConfigurations.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              <Typography fontSize={5}>{t('common.edit')}</Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                editSFTPConnectcion(row.id);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              <Typography fontSize={5}>
                                {t('managUsersSFTP.copieConnection')}
                              </Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                copieSFTPConnectionConfig(row.id);
                              }}
                            >
                              <FileCopyIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              <Typography fontSize={5}>{t('managUsersSFTP.deleteButton')}</Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                confirmDeleteSFTPConnectcion(row.id);
                              }}
                            >
                              <DeleteIcon className={classes.deleteBtn} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              <Typography fontSize={5}>{'Test SFTP-Verbindung'}</Typography>
                            }
                          >
                            <IconButton
                              onClick={() => {
                                testSFTPConnection(row.id);
                              }}
                            >
                              <CableRoundedIcon fontSize="large" />
                            </IconButton>
                          </Tooltip>

                        </TableCell>
                        <TableCell align="left">
                          {row.serverName}
                        </TableCell>
                        <TableCell align="left">
                          {row.serverURL}
                        </TableCell>
                        <TableCell align="left">
                          {row.downloadFolder}
                        </TableCell>
                        <TableCell align="left">
                          {row.user}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
            </Table>
          </TableContainer>{' '}
        </Paper>
      ) : (
        <Typography
          paragraph={true}
          noWrap={false}
          fontWeight={'bold'}
          align="center"
          fontSize={20}
        >
          {t('common.noSearchResults')}
        </Typography>
      )}
    </div>
  );
}

