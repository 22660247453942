import React, { useState, useEffect } from 'react';
import {
  IconButton,
  TextField,
  Button,
  Typography
} from '@material-ui/core';
import { InputAdornment, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PaginationComponent from './PaginationComponent';
import ajaxGet from 'src/services/ajaxGet';
import EnhancedTable from './EnhancedTable';
import theme, { usePimStyles } from '../PimTogoTheme';
import CreateOrganization from '../user/CreateOrganization';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import OrganisationForm from '../user/OrganizationForm';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    padding: '5px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titel: {
    backgroundColor: ' #f29528',
    color: '#08080 !important',
    margin: '0px'
  },
  submitButton: {
    margin: '5px !important'
  },
  editwidth: {
    width: '44rem !important'
  }
}));

export default function Organizations() {
  const INITIAL_FORM_STATE = {
    id: '',
    company: '',
    companyAffix: '',
    taxnumber: '',
    pdfNameBuilder: 'Standard',
    department: '',
    street: '',
    streetNumber: '',
    postalCode: '',
    city: '',
    country: '',
    phone: '',
    fax: '',
    email: '',
    website: '',
    organisationState: 'ACTIVE'
  };

  const { t } = useTranslation();
  const classesPim = usePimStyles();
  const classes = useStyles();
  const [searchExpression, setSearchExpression] = useState('');

  const [organizations, setOrganizations] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(5);
  const [paginationstate, setPaginationstate] = useState({});
  const [open, setOpen] = useState(false);
  const [isLocked, setIsLocked] = useState(true);
  const [toCreateOrga, setToCreateOrga] = useState(false);

  const [internalEdit, setInternalEdit] = useState(false);
  const [initFormState, setInitFormState] = useState(INITIAL_FORM_STATE);

  const handelEventSearchOrganization = () => {
    setCurrentPage(1);
    setElementsPerPage(5);
    searchOrganizations();
  };

  const searchOrganizations = () => {
    ajaxGet('organizationManagement/searchOrganization', {
      searchExpression: searchExpression,
      currentPage: currentPage,
      elementsPerPage: elementsPerPage
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        if (ajaxData.response.internalEdit) {
          setInternalEdit(ajaxData.response.internalEdit);
          var res = ajaxData.response.orgasInView[0].organizationForm;
          if (res != null && res != undefined) {
            var orgaform = {
              id: ajaxData.response.orgasInView[0].id,
              company: res?.company || '',
              companyAffix: res?.companyAffix || '',
              taxnumber: res?.taxnumber || '',
              pdfNameBuilder: res?.pdfNameBuilder || '',
              department: res?.department || '',
              street: res?.street || '',
              streetNumber: res?.streetNumber || '',
              postalCode: res?.postalCode || '',
              city: res?.city || '',
              country: res?.country || '',
              phone: res?.phone || '',
              fax: res?.fax || '',
              email: res?.email || '',
              website: res?.website || '',
              organisationState: res?.organisationState || ''
            };
            setToCreateOrga(false);
            setInitFormState(orgaform);
          }
        } else {
          let organizationList = ajaxData.response.orgasInView;
          setOrganizations(organizationList);
          setPaginationstate(ajaxData.response.pagination);
        }
      }
    });
  };

  useEffect(() => {
    searchOrganizations();
  }, [currentPage, elementsPerPage]);

  const handleChange = (ev) => {
    setSearchExpression(ev.target.value);
  };

  function handleChangeCurrentPage(newValue) {
    setCurrentPage(newValue);
  }

  function handleChangeElementsPerPage(newValue) {
    setCurrentPage(1);
    setElementsPerPage(newValue);
  }

  const gotoPage = (val) => {
    setCurrentPage(val);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && (reason == 'backdropClick' || reason == 'escapeKeyDown'))
      return;
    setOpen(false);
    if (!internalEdit) {
      setToCreateOrga(true);
      setInitFormState(INITIAL_FORM_STATE);
    }
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      setCurrentPage(1);
      setElementsPerPage(5);
      searchOrganizations();
    }
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'black',
      background: '#ebddbad1',
      border: '0.5px solid',
      '&:hover': {
        //you want this to be the same as the backgroundColor above
        backgroundColor: '#625c5652'
      }
    }
  });

  return (
    <div>
      <ThemeProvider theme={theme}>
        {internalEdit ? (
          <Grid className={classes.editwidth}>
            <OrganisationForm
              handleClose={handleClose}
              initFormState={initFormState}
              isLocked={isLocked}
              internalEdit={internalEdit}
              toCreateOrga={toCreateOrga}
            />
          </Grid>
        ) : (
          <>
            <Grid className={classes.root}>
              <form>
                <TextField
                  label= {t('manageOrganization.organization.search')}
                  margin="normal"
                  id="user-name-search"
                  fullWidth
                  value={searchExpression}
                  onChange={handleChange}
                  onKeyDown={handleKeypress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        onClick={() => handelEventSearchOrganization()}
                        position="start"
                      >
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </form>
            </Grid>
            <Grid className={classes.root}>
              {organizations.length != 0 && (
                <PaginationComponent
                  paginationstate={paginationstate}
                  searchExpression={searchExpression}
                  isOrganization={true}
                  gotoPage={gotoPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  currentPage={currentPage}
                  elementsPerPage={elementsPerPage}
                  handleChangeCurrentPage={handleChangeCurrentPage}
                  handleChangeElementsPerPage={handleChangeElementsPerPage}
                />
              )}
            </Grid>
            {'   '}
            <Grid className={classes.root}>
              <Button
                variant="outlined"
                onClick={() => {
                  setToCreateOrga(true);
                  handleClickOpen();
                }}
                className={`${classesPim.addAction} ${classes.submitButton}`}
              >
                <Typography fontSize={5}>{t('manageOrganization.organization.createTitle')}</Typography>
              </Button>

              <CreateOrganization
                open={open}
                handleClose={handleClose}
                initFormState={initFormState}
                setOrganizations={setOrganizations}
                organizations={organizations}
                isLocked={isLocked}
                currentPage={currentPage}
                toCreateOrga={toCreateOrga}
              />
              <EnhancedTable
                rows={organizations}
                isOrganization={true}
                handleClickOpen={handleClickOpen}
                setOrganizations={setOrganizations}
                setInitFormState={setInitFormState}
                setIsLocked={setIsLocked}
                setToCreateOrga={setToCreateOrga}
                searchOrganizations={searchOrganizations}
              />
            </Grid>
          </>
        )}
      </ThemeProvider>
    </div>
  );
}
