import React from 'react';
import { Editor, Frame, Element } from '@craftjs/core';
import { Paper, Grid, makeStyles } from '@material-ui/core';

import { SettingsPanel } from './SettingsPanel';
import { Toolbox } from './Toolbox';
import { Topbar } from './Topbar';

import { TextEditorSelector } from './tools/TextEditorSelector';
import { MediaEditorSelector } from './tools/MediaEditorSelector';
import { FieldEditorSelector } from './tools/FieldEditorSelector';
import { Card, CardPart } from './tools/Card';
import { Tabs, TabCanvas } from './tools/Tabs';
import { Table, CellCanvas } from './tools/Table';
import { Container } from './tools/Container';
import { Accordion } from './tools/Accordion';
import { Title } from './tools/Title';

import i18n from '../../../i18n';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

export const productInstanceType = {
  value: 'Product',
  label: i18n.t('data.product')
};

const resolverComponents = {
  Card,
  CardPart,
  Tabs,
  TabContent: TabCanvas,
  Table,
  TableCellContent: CellCanvas,
  TextEditorSelector,
  MediaEditorSelector,
  FieldEditorSelector,
  Title,
  Container,
  Accordion
};

export const InstanceTabEditor = () => {
  const classes = useStyles();

  return (
    <div style={{ margin: '0 auto' }}>
      <Editor
        resolver={resolverComponents}
        rootDataSource={{
          source: productInstanceType.value,
          singleData: true,
          label: productInstanceType.label
        }}
      >
        <Topbar />
        <Grid container spacing={5} style={{ paddingTop: '10px' }}>
          <Grid item xs={8} className="tabEditorRoot">
            <Frame>
              <Element
                canvas
                is={Container}
                padding={5}
                background="#ffffff"
                dataSource={{
                  source: productInstanceType.value,
                  singleData: true,
                  childOverride: false
                }}
              ></Element>
            </Frame>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.root}>
              <Toolbox />
              <SettingsPanel />
            </Paper>
          </Grid>
        </Grid>
      </Editor>
    </div>
  );
};
