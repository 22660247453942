import React from 'react';
import { useEditor, Element } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

import { Box, Icon, makeStyles } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import { TextEditorSelector } from './tools/TextEditorSelector';
import { MediaEditorSelector } from './tools/MediaEditorSelector';
import { FieldEditorSelector } from './tools/FieldEditorSelector';
import { Container } from './tools/Container';
import { Tabs } from './tools/Tabs';
import { Table } from './tools/Table';
import { Title } from './tools/Title';
import { initTitles } from './tools/Title';

const useStyles = makeStyles(() => ({
  wrap: {
    background: '#ffd8a9'
  }
}));

export const Toolbox = () => {
  const { connectors } = useEditor();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.wrap} px={2} py={2}>
      <TreeView
        className="tabeditor-toolbox"
        defaultCollapseIcon={<Icon className="fa fa-caret-down" />}
        defaultExpandIcon={<Icon className="fa fa-caret-right" />}
        defaultExpanded={['1', '6']}
      >
        <TreeItem nodeId="1" label={t('tabeditor.tools.structure')}>
          <TreeItem
            nodeId="2"
            label={t('tabeditor.tools.title')}
            ref={(ref) => connectors.create(ref, <Title text={initTitles} />)}
          ></TreeItem>
          <TreeItem
            nodeId="3"
            label={t('tabeditor.tools.container')}
            ref={(ref) =>
              connectors.create(
                ref,
                <Element canvas is={Container} padding={20} />
              )
            }
          ></TreeItem>
          <TreeItem
            nodeId="4"
            label={t('tabeditor.tools.tabs.name')}
            ref={(ref) => connectors.create(ref, <Tabs />)}
          ></TreeItem>
          <TreeItem
            nodeId="5"
            label={t('tabeditor.tools.table.name')}
            ref={(ref) => connectors.create(ref, <Table />)}
          ></TreeItem>
        </TreeItem>
        <TreeItem nodeId="6" label={t('tabeditor.tools.editors')}>
          <TreeItem
            nodeId="7"
            label={t('tabeditor.tools.textEditor.name')}
            ref={(ref) => connectors.create(ref, <TextEditorSelector />)}
          ></TreeItem>
          <TreeItem
            nodeId="8"
            label={t('tabeditor.tools.mediaEditor.name')}
            ref={(ref) =>
              connectors.create(
                ref,
                <MediaEditorSelector
                  label={t('tabeditor.tools.mediaEditor.labelPlaceholder')}
                />
              )
            }
          ></TreeItem>
          <TreeItem
            nodeId="9"
            label={t('tabeditor.tools.fieldEditor.name')}
            ref={(ref) =>
              connectors.create(
                ref,
                <FieldEditorSelector
                  label={t('tabeditor.tools.fieldEditor.labelPlaceholder')}
                />
              )
            }
          ></TreeItem>
        </TreeItem>
      </TreeView>
    </Box>
  );
};
