import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TreeSelect, {
  BranchNode,
  ValueNode,
  defaultInput
} from 'mui-tree-select';

import { TableDataContext } from './Table';
import { TabDataContext } from './Tabs';
import SelectedWrap from './SelectedWrap';
import { contentTypes, setContentDefs } from '../DataSourceContent';
import MissingDefinition from './MissingDefinition';

export const BulkDataTypes = {
  texts: 'texts',
  texts_group: 'texts_group_'
};

export const TextEditorSelector = ({ textType, bulkDataType }) => {
  const { t } = useTranslation();
  const [isInTableContext, changeTableColumnDataCategory] =
    useContext(TableDataContext);
  const [isInTabContext, addTabDataRequirement] = useContext(TabDataContext);

  const {
    connectors: { connect, drag },
    actions: { setProp: setNodeProps },
    id: nodeId
  } = useNode();
  const {
    isSelected,
    query: { node: nodeMap }
  } = useEditor((state) => ({
    isSelected: state.events.selected === nodeId
  }));
  const parentId = nodeMap(nodeId)?.get().data.parent;

  const [isSelectModalOpen, setSelectModalOpen] = useState(false);
  const [textDefs, setTextDefs] = useState(null);
  const textDef = textDefs?.find((def) => def.value === textType);

  let label;
  if (bulkDataType === BulkDataTypes.texts) {
    label = t('tabeditor.tools.textEditor.bulkData');
  } else if (
    bulkDataType &&
    bulkDataType.startsWith(BulkDataTypes.texts_group)
  ) {
    label = t('tabeditor.tools.textEditor.bulkDataTextGroup', {
      groupId: bulkDataType.split(BulkDataTypes.texts_group)[1]
    });
  } else {
    label =
      textDef?.label ||
      textType ||
      t('tabeditor.tools.textEditor.labelPlaceholder');
  }

  useEffect(() => {
    setContentDefs(nodeMap, nodeId, setTextDefs, contentTypes.Text);
  }, []);

  useEffect(() => {
    if (isInTableContext) {
      const tableColumnDataId = nodeMap(parentId)?.get().data.props.dataId;

      const tableColumnDataCategory = {};
      if (textType) {
        tableColumnDataCategory.textType = textType;
      } else if (bulkDataType) {
        tableColumnDataCategory.bulkDataType = bulkDataType;
      }
      tableColumnDataCategory.label = label;

      changeTableColumnDataCategory(tableColumnDataId, tableColumnDataCategory);
    }

    if (isInTabContext) {
      const dataSource = nodeMap(parentId)?.get().data.props.dataSource?.source;
      let dataTypeId;
      if (textType) {
        dataTypeId = textType;
      } else if (bulkDataType) {
        dataTypeId = 'bulkDataType_' + bulkDataType;
      }

      addTabDataRequirement(dataSource, dataTypeId, contentTypes.Text);
    }
  }, [textType, bulkDataType, label]);

  useEffect(() => {
    if (!textType && !bulkDataType) {
      setSelectModalOpen(true);
    }
  }, [parentId]);

  return (
    <div
      ref={(ref) => connect(drag(ref))}
      className="editorMockup textEditorWrapper"
    >
      <SelectedWrap isSelected={isSelected}>
        <div className="textEditor">
          {!isInTableContext && (
            <div className="textEditorTitle editorTitle">
              <h2>{label}</h2>
            </div>
          )}
          {isSelectModalOpen && !(textType && !textDef) && (
            <SelectElement
              textDefs={textDefs}
              setNodeProps={setNodeProps}
              textType={textType}
              bulkDataType={bulkDataType}
              setSelectModalOpen={setSelectModalOpen}
            ></SelectElement>
          )}
          <div className="textEditorDummy">
            {textType && !textDef ? (
              <MissingDefinition defTitle={textType} />
            ) : (
              t('tabeditor.tools.textEditor.exampleText')
            )}
          </div>
        </div>
      </SelectedWrap>
    </div>
  );
};
TextEditorSelector.propTypes = {
  textType: PropTypes.any,
  bulkDataType: PropTypes.any,
  variant: PropTypes.any
};

const useStylesSelect = makeStyles(() => ({
  bulkData: {
    textDecoration: 'underline'
  },
  outerPopper: {
    width: 'auto !important'
  }
}));

const SelectElement = ({
  textDefs,
  setNodeProps,
  textType,
  bulkDataType,
  setSelectModalOpen
}) => {
  const { t } = useTranslation();
  const classes = useStylesSelect();
  const isAutomaticOpen = setSelectModalOpen ? true : null;

  const textGroups = {};
  textDefs?.forEach((def) => {
    if (def.groupId) {
      let group = textGroups[def.groupId];
      if (!group) {
        group = [];
        textGroups[def.groupId] = group;
      }
      group.push(def);
    }
  });
  const hasGroups = Object.keys(textGroups).length > 0;

  const [treeState, setTreeState] = useState([]);
  const [branch, setBranch] = useState(null);

  let valueNode = null;
  let valueNodeDef = null;
  if (textType) {
    valueNodeDef = textDefs?.find((textDef) => textDef.value === textType);
  } else if (bulkDataType) {
    if (bulkDataType === BulkDataTypes.texts) {
      valueNodeDef = {
        value: BulkDataTypes.texts,
        label: t('tabeditor.tools.textEditor.bulkData')
      };
    } else if (bulkDataType.startsWith(BulkDataTypes.texts_group)) {
      valueNodeDef = {
        value: bulkDataType,
        label: t('tabeditor.tools.textEditor.bulkDataTextGroup', {
          groupId: bulkDataType.split(BulkDataTypes.texts_group)[1]
        })
      };
    }
  }

  if (valueNodeDef) {
    valueNode = new ValueNode(valueNodeDef);
  }

  useEffect(() => {
    const rootOptions = getRootOptions();
    setTreeState(rootOptions);
  }, [JSON.stringify([].concat(textDefs))]);

  function getRootOptions() {
    let rootOptions = [];
    if (!textDefs) return rootOptions;

    if (!hasGroups) {
      rootOptions = getAllTextsOptions();
    } else {
      rootOptions.push(
        new BranchNode({
          value: 'allTextNode',
          label: t('tabeditor.tools.textEditor.allTexts')
        })
      );

      for (let groupId in textGroups) {
        rootOptions.push(
          new BranchNode({
            value: groupId,
            label: groupId
          })
        );
      }
    }

    return rootOptions;
  }

  function getAllTextsOptions() {
    let options = [];
    options.push({
      value: BulkDataTypes.texts,
      label: t('tabeditor.tools.textEditor.bulkData')
    });

    if (textDefs) {
      options.push(...textDefs);
    }

    return options;
  }

  function getOptionLabel(option) {
    if (option.valueOf().label) {
      return option.valueOf().label;
    } else {
      return '-';
    }
  }

  function changeBranch(_, selectedBranch) {
    if (selectedBranch) {
      let options = [];
      const branchId = selectedBranch.valueOf().value;

      if (branchId === 'allTextNode' && textDefs) {
        options = getAllTextsOptions();

        setBranch(selectedBranch);
      } else if (branchId in textGroups) {
        const groupId = branchId;
        options.push({
          value: BulkDataTypes.texts_group + groupId,
          label: t('tabeditor.tools.textEditor.bulkDataTextGroup', {
            groupId: groupId
          })
        });
        options.push(...textGroups[groupId]);
        setBranch(selectedBranch);
      }

      setTreeState(options);
    } else {
      const rootOptions = getRootOptions();

      setTreeState(rootOptions);
      setBranch(null);
    }
  }

  function changeTextType(_, newValueNode) {
    const newValue = newValueNode?.valueOf().value;
    if (!newValue) return;

    let selectedBulkDataType = null;
    let selectedTextType = null;

    if (newValue === BulkDataTypes.texts) {
      selectedBulkDataType = newValue;
    } else if (newValue.startsWith(BulkDataTypes.texts_group)) {
      selectedBulkDataType = newValue;
    } else if (textDefs?.find((def) => def.value === newValue)) {
      selectedTextType = newValue;
    }

    setNodeProps((props) => {
      props.textType = selectedTextType;
      props.bulkDataType = selectedBulkDataType;
    });

    if (setSelectModalOpen) {
      setSelectModalOpen(false);
    }
  }

  function closeOnBlur() {
    if (setSelectModalOpen) {
      setSelectModalOpen(false);
    }
  }

  var inputProps = {};
  if (isAutomaticOpen) {
    inputProps.open = isAutomaticOpen;
    inputProps.classes = {
      popper: classes.outerPopper
    };
  }

  return (
    <TreeSelect
      style={isAutomaticOpen ? { opacity: 0, width: 0, height: 0 } : null}
      {...inputProps}
      onBranchChange={changeBranch}
      options={treeState}
      branch={branch}
      value={valueNode}
      onChange={changeTextType}
      onClose={closeOnBlur}
      getOptionLabel={getOptionLabel}
      renderInput={useCallback(
        (params) =>
          defaultInput({
            ...params,
            variant: 'outlined',
            label: t('tabeditor.tools.textEditor.type')
          }),
        []
      )}
    />
  );
};

export const TextEditorSelectorSettings = () => {
  const { t } = useTranslation();
  const [textDefs, setTextDefs] = useState(null);
  const {
    actions: { setProp: setNodeProps },
    id: nodeId,
    props: nodeProps
  } = useNode((node) => ({
    props: node.data.props
  }));
  const {
    query: { node: nodeMap }
  } = useEditor();

  useEffect(() => {
    setContentDefs(nodeMap, nodeId, setTextDefs, contentTypes.Text);
  }, []);

  function changeVariant(event) {
    const val = event.target.value;
    setNodeProps((props) => {
      props.variant = val;
    });
  }

  return (
    <>
      <SelectElement
        textDefs={textDefs}
        setNodeProps={setNodeProps}
        textType={nodeProps.textType}
        bulkDataType={nodeProps.bulkDataType}
      />
      <FormControl
        variant="outlined"
        component="fieldset"
        style={{ marginTop: '2rem' }}
      >
        <InputLabel id="textvariant-select-outlined-label">
          {t('tabeditor.tools.textEditor.variant')}
        </InputLabel>
        <Select
          labelId="textvariant-select-outlined-label"
          id="textvariant-select-outlined"
          label={t('tabeditor.tools.textEditor.variant')}
          defaultValue="formatted"
          value={nodeProps.variant}
          onChange={changeVariant}
        >
          <MenuItem value="formatted">
            {t('tabeditor.tools.textEditor.formatted')}
          </MenuItem>
          <MenuItem value="simple">
            {t('tabeditor.tools.textEditor.simple')}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export const TextEditorSelectorDefaultProps = {
  textType: '',
  variant: 'formatted'
};

TextEditorSelector.craft = {
  props: TextEditorSelectorDefaultProps,
  related: {
    settings: TextEditorSelectorSettings
  }
};
