import React, { useState } from 'react';

import { useExport } from './ExportContext';
import { FeatureComponent } from '../articledata/datamanagement/featurecomponents/FeatureComponent';

export const ExportHierarchyInfoEditor = ({ hierarchyInfo }) => {
  const {
    setHierarchyInfoValues,
    setHierarchyInfoState,
    getHierarchyInfoState,
    deleteHeaderFieldValue,
    deleteFromRegExErrorList,
    setRegExErrorList
  } = useExport();

  const [regExError, setRegExError] = useState(false);
  const infoEditorData = {};

  const infoId = hierarchyInfo.bmeCatField;
  const hashCode = hierarchyInfo.keyHashCode;

  let title = infoId;
  let attributes = hierarchyInfo.bmeCatAttributes;

  let regexRestriction = hierarchyInfo.regexRestriction;
  let regExErrorMessage = hierarchyInfo.regExErrorMessage;

  if (regexRestriction) {
    infoEditorData.regexRestriction = regexRestriction;
    infoEditorData.isRegExError = regExError;
    infoEditorData.regexRestrictionMsg = regExErrorMessage;
  }

  let numericMinRestriction = hierarchyInfo.numericMinRestriction;
  let numericMaxRestriction = hierarchyInfo.numericMaxRestriction;
  let numericStepRestriction = hierarchyInfo.numericStepRestriction;

  if (
    numericMinRestriction &&
    numericMaxRestriction &&
    numericStepRestriction &&
    !regexRestriction
  ) {
    infoEditorData.numericMinRestriction = numericMinRestriction;
    infoEditorData.numericMaxRestriction = numericMaxRestriction;
    infoEditorData.numericStepRestriction = numericStepRestriction;
    infoEditorData.isRegExError = regExError;
    infoEditorData.regexRestrictionMsg = regExErrorMessage;
  }

  if (attributes && Object.keys(attributes).length > 0) {
    title += ' (';
    for (let key in attributes) {
      title += ' ' + key + '=' + attributes[key];
    }
    title += ' )';
  }

  infoEditorData.title = title;
  infoEditorData.mandatory = hierarchyInfo.mandatory;

  const featureType = hierarchyInfo.featureType;

  infoEditorData.featureComponent = {};
  infoEditorData.featureComponent.featureType = featureType;

  infoEditorData.customFieldNotCommonOptions = true;

  if (
    hierarchyInfo.featureType === 'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION'
  ) {
    infoEditorData.featureComponent.multiSelection = true;
  }

  const value = getHierarchyInfoState(hashCode);

  function setValueState(clientStateValue) {
    // if (regexRestriction) {
    //   const regex = new RegExp('^' + regexRestriction + '$');
    //   if (clientStateValue === '' || regex.test(clientStateValue)) {
    //     setHeaderFieldState(hashCode, clientStateValue);
    //   } else {
    //     setError(true);
    //   }
    // } else {
    setHierarchyInfoState(hashCode, clientStateValue);
    // }
  }

  function saveCreateValue(newValue, valuePayload) {
    if (newValue) {
      if (regexRestriction) {
        const regex = new RegExp('^' + regexRestriction + '$');
        if (newValue === '' || regex.test(newValue)) {
          setHierarchyInfoValues(hashCode, valuePayload);
          setRegExError(false);
          deleteFromRegExErrorList(hashCode);
        } else {
          setRegExErrorList(hashCode);
          setRegExError(true);
        }
      } else if (
        numericMinRestriction &&
        numericMaxRestriction &&
        numericStepRestriction &&
        !regexRestriction
      ) {
        if (
          newValue >= numericMinRestriction &&
          newValue <= numericMaxRestriction &&
          newValue % numericStepRestriction == 0
        ) {
          setHierarchyInfoValues(hashCode, valuePayload);
          setRegExError(false);
          deleteFromRegExErrorList(hashCode);
        } else {
          setRegExError(true);
          setRegExErrorList(hashCode);
        }
      } else {
        setHierarchyInfoValues(hashCode, valuePayload);
      }
    } else {
      deleteHeaderFieldValue(hashCode);
      setRegExError(false);
      deleteFromRegExErrorList(hashCode);
    }
    if (value?.selected) {
      if (!newValue) {
        newValue = {};
        newValue.customOptions = [];
        newValue.selected = [];
      }
      newValue.standardOptions = value.standardOptions;
    }

    setHierarchyInfoState(hashCode, newValue);
  }

  if (!infoEditorData) {
    return null;
  }
  return (
    <div
      className="custom-field-editor instanceEditorComponent"
      style={{ margin: '2rem 0' }}
    >
      <FeatureComponent
        featureInfo={infoEditorData}
        value={value}
        setValueState={setValueState}
        saveChangedValue={saveCreateValue}
        loading={false}
      />
    </div>
  );
};
