import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import {
  Dialog,
  DialogTitle
} from '@material-ui/core';


import { usePimStyles } from '../PimTogoTheme';

import OrganisationForm from '../user/OrganizationForm';


export const CreateOrganization = ({
  open,
  handleClose,
  initFormState,
  isLocked,
  toCreateOrga,
  currentPage,
  setOrganizations,
  organizations
}) => {
  const { t } = useTranslation();
  const classesPim = usePimStyles();

  const FORM_VALIDATION = Yup.object().shape({
    id: '',
    company: Yup.string().required('name required'),
    companyAffix: '',
    taxnumber: Yup.string().required('state required'),
    pdfNameBuilder: Yup.string().required('state required'),
    department: '',
    street: Yup.string().required('state required'),
    streetNumber: Yup.string().required('state required'),
    postalCode: Yup.string().required('postalCode required'),
    city: Yup.string().required('state required'),
    country: Yup.string().required('state required'),
    phone: Yup.number().required('phone required').positive().integer(),
    fax: Yup.number().positive().integer(),
    email: Yup.string().email('Invalid email format'),
    website: Yup.string().matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url!'
    ),
    organisationState: ''
  });

  const pdfNameBuilderList = { Standard: 'Standard', Krempel: 'Krempel' };
  const organisationStates__ = {
    ACTIVE: 'ACTIVE',
    SILENT: 'SILENT',
    LOCKED: 'LOCKED'
  };
  const organisationStates = { ACTIVE: 'ACTIVE', SILENT: 'SILENT' };

  useEffect(() => {}, []);

  return (
    <Dialog
      aria-labelledby="create-instance-title"
      open={open}
      onClose={handleClose}
      className={classesPim.dialog}
      transitionDuration={0}
    >
      <DialogTitle
        id="create-instance-title"
        className={classesPim.dialogTitle}
      >
        {/* {t('instanceEditor.createInstance', { instanceTitle: instanceTitel })} */}
        {toCreateOrga ? t('manageOrganization.organization.createTitle') : t('manageOrganization.organization.editTitle')}
      </DialogTitle>
      <OrganisationForm
        handleClose={handleClose}
        initFormState={initFormState}
        currentPage={currentPage}
        isLocked={isLocked}
        toCreateOrga={toCreateOrga}
        setOrganizations={setOrganizations}
        organizations={organizations}
      />
    </Dialog>
  );
};

export default CreateOrganization;
