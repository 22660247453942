import React, { useEffect, useState } from 'react';

import { DataManagementProvider } from './DataManagementContext';

import { InstanceTabEditorReader } from '../tabeditor/reader/InstanceTabEditorReader';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../PimTogoTheme';
import { SelectEditor } from './SelectEditor';

export default function InstanceEditor() {
  const [targetEditorConfig, setTargetEditorConfig] = useState(null);
  const ptdId = $('#productPtdSelectButton').data('ptd-id') || null;

  useEffect(() => {}, [targetEditorConfig]);

  return (
    <ThemeProvider theme={theme}>
      <DataManagementProvider readOnlyProvided={false} ptdId={ptdId}>
        <SelectEditor setTargetEditorConfig={setTargetEditorConfig} />
        <InstanceTabEditorReader
          readOnly={false}
          selectedNodesContent={targetEditorConfig}
        />
      </DataManagementProvider>
    </ThemeProvider>
  ); //TODO: read only
}
