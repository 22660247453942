import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useReducer
} from 'react';
import PropTypes from 'prop-types';
import { Element, useEditor, useNode } from '@craftjs/core';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import ContentEditable from 'react-contenteditable';

import {
  AppBar,
  Tabs as MuiTabs,
  Tab as MuiTab,
  FormControl,
  Grid,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  Container,
  ContainerSettings,
  ContainerDefaultProps,
  containerMoveInRule
} from './Container';

import { TabEditorContext } from '../TabEditor';
import { useStylesSelected } from './SelectedWrap';
import sanitizeFromXML from '../../../../util/sanitizeFromXML';
import ajaxGet from '../../../../services/ajaxGet';

export const OrientationValues = {
  horizontal: 'horizontal',
  vertical: 'vertical'
};

export const TabDataContext = createContext([false, []]);
export const TabCanvasForceUpdateContext = createContext(null);

export const TabPanel = ({
  selectedTabIndex,
  index,
  orientation,
  children
}) => {
  return (
    <div
      className="TabPanel"
      role="tabpanel"
      hidden={selectedTabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {selectedTabIndex === index && (
        <div
          style={
            orientation === OrientationValues.horizontal
              ? {
                  paddingTop: 20
                }
              : null
          }
        >
          {children}
        </div>
      )}
    </div>
  );
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  selectedTabIndex: PropTypes.any.isRequired,
  orientation: PropTypes.any.isRequired
};

export const TabCanvas = ({
  background,
  padding,
  dataSource,
  containerFlexColumns,
  children
}) => {
  const [, , deleteTabDataRequirement] = useContext(TabDataContext);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const selectedClasses = useStylesSelected();
  const classes = useStylesTabs();
  const {
    connectors: { connect },
    id: nodeId,
    actions: { setProp }
  } = useNode();
  const {
    isSelected,
    query: { node: nodeMap }
  } = useEditor((state) => ({
    isSelected: state.events.selected === nodeId
  }));

  const parentId = nodeMap(nodeId)?.get().data.parent;

  useEffect(() => {
    if (parentId && (!dataSource || !dataSource.childOverride)) {
      const parentDataSource = nodeMap(parentId)?.get().data.props.dataSource;

      if (parentDataSource) {
        const newDataSource = {
          source: parentDataSource.source,
          childOverride: false
        };
        setProp((props) => (props.dataSource = newDataSource), 500);
      }
    }
  }, [parentId]);

  let tabDataTypeIds = collectTabReqDataTypeIds(nodeMap, nodeId, []);

  useEffect(() => {
    deleteTabDataRequirement(tabDataTypeIds);
  }, [tabDataTypeIds]);

  return (
    <TabCanvasForceUpdateContext.Provider value={forceUpdate}>
      <div
        ref={connect}
        style={{
          background,
          padding: `${padding}px`,
          minHeight: '5rem'
        }}
        className={`${isSelected ? selectedClasses.selected : ''} ${
          containerFlexColumns === '2' ? classes.two : ''
        }`}
      >
        {children}
      </div>
    </TabCanvasForceUpdateContext.Provider>
  );
};
TabCanvas.craft = {
  props: ContainerDefaultProps,
  related: {
    settings: ContainerSettings
  },
  rules: {
    canMoveIn: containerMoveInRule
  }
};

const collectTabReqDataTypeIds = (nodeMap, curNodeId, tabDataTypeIds) => {
  const curNodeData = nodeMap(curNodeId)?.get().data;
  const curNodeProps = curNodeData?.props;

  if (curNodeProps.deleted) return tabDataTypeIds;

  if (curNodeProps?.textType) {
    tabDataTypeIds.push(curNodeProps.textType);
  } else if (curNodeProps?.mediaType) {
    tabDataTypeIds.push(curNodeProps.mediaType);
  } else if (curNodeProps?.customFieldType) {
    tabDataTypeIds.push(curNodeProps.customFieldType);
  } else if (
    curNodeProps?.generalFieldType &&
    curNodeProps.generalFieldType != 'articleNumber'
  ) {
    tabDataTypeIds.push(curNodeProps.generalFieldType);
  } else if (curNodeProps?.bulkDataType) {
    tabDataTypeIds.push('bulkDataType_' + curNodeProps.bulkDataType);
  }

  const childNodes = curNodeData?.nodes;
  childNodes.forEach((childNodeId) => {
    collectTabReqDataTypeIds(nodeMap, childNodeId, tabDataTypeIds);
  });

  const linkedNodes = curNodeData?.linkedNodes;
  for (const linkedNodeKey in linkedNodes) {
    const linkedNode = linkedNodes[linkedNodeKey];
    collectTabReqDataTypeIds(nodeMap, linkedNode, tabDataTypeIds);
  }

  return tabDataTypeIds;
};
export { collectTabReqDataTypeIds };

function a11yProps(index) {
  return {
    // id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const useStylesTabs = makeStyles(() => ({
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'black',
      height: 5,
      top: 43
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      opacity: 1,
      fontWeight: 'bold'
    }
  },
  tabsNoShadow: {
    boxShadow:
      '0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%) !important'
  },
  tabsVertical: {
    display: 'flex',
    alignItems: 'center',
    background: 'white',
    color: 'black',
    borderRight: '#ffd8a9 solid 2px',
    borderRadius: 4,
    maxHeight: '35rem',
    width: '18rem',

    '&  > .MuiTabs-scroller > .MuiTabs-indicator': {
      backgroundColor: 'black',
      width: 5
    },
    '& > .MuiTabs-scroller > .MuiTabs-flexContainerVertical > .MuiTab-root': {
      minWidth: '72px'
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      opacity: 1,
      fontWeight: 'bold'
    }
  },
  verticalFlexWrap: {
    display: 'flex',
    flexGrow: 1,
    '&  > .tabsContentWrap': {
      maxWidth: 'calc(90% - 3rem)'
    },
    '&  > .tabsContentWrap > .TabPanel > .childrenTabPanel > .tab-content > .containerDisplayPaper':
      {
        width: 'calc(100vw - 64rem)'
      },
    '&  > .tabsContentWrap > .TabPanel > .childrenTabPanel > .tab-content > .containerDisplayPaper > .mediaEditor':
      {
        width: 'calc(100vw - 69rem)'
      },
    '&  > .tabsContentWrap > .TabPanel > .childrenTabPanel > div >.tab-content > .containerDisplayPaper':
      {
        width: 'calc(100vw - 64rem)'
      },
    '&  > .tabsContentWrap > .TabPanel > .childrenTabPanel > div > .tab-content > .containerDisplayPaper > .mediaEditor':
      {
        width: 'calc(100vw - 69rem)'
      }
  },
  tabTitleVertical: {
    margin: 0
  },
  tabTitleEdit: {
    cursor: 'text'
  },
  two: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 0 100%'
    },
    '& > .editorMockup': {
      flex: '1 0 48%',
      margin: '0 5px'
    }
  }
}));

const initTabTexts = {
  de: 'Erster Tab',
  en: 'First tab',
  it: 'Primo tab',
  fr: 'Premier onglet'
};
const createTabTexts = {
  de: 'Neuer Tab',
  en: 'New tab',
  it: 'Nuovo tab',
  fr: 'Nouvel onglet'
};

export const Tabs = ({ tabs, orientation }) => {
  const [lang] = useContext(TabEditorContext);
  const forceUpdateTabCanvas = useContext(TabCanvasForceUpdateContext);
  const [isInOuterTabContext, addOuterTabDataRequirement] =
    useContext(TabDataContext);
  const classes = useStylesTabs();
  const [dragTabId, setDragTabId] = useState('');
  const isVertical = orientation === OrientationValues.vertical;

  const {
    actions: { setProp },
    id,
    parentId,
    isMultipleData,
    selectedTabIndex
  } = useNode((node) => ({
    parentId: node.data.parent,
    isMultipleData: node.data.props.isMultipleData,
    selectedTabIndex: node.data.props.selectedTabIndex
  }));
  const {
    query: { node: nodeMap }
  } = useEditor();
  const parentDataSource = nodeMap(parentId)?.get().data.props.dataSource;

  const handleDragTab = (ev) => {
    setDragTabId(ev.currentTarget.id);
  };

  const onDragOverTab = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleDropTab = (ev) => {
    const dragTabposition = tabs.findIndex((tab) => tab.id === dragTabId);
    const dropTabPosition = tabs.findIndex(
      (tab) => tab.id === ev.currentTarget.id
    );

    const newTabs = moveArrayItemToNewIndex(
      tabs,
      dropTabPosition,
      dragTabposition
    );
    setProp((props) => ((props.tabs = newTabs), 500));

    selectTab(null, dropTabPosition);
  };

  const swapArrayElements = (arr, indexA, indexB) => {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
  };

  const moveArrayItemToNewIndex = (arr, old_index, new_index) => {
    const tabsArray = JSON.parse(JSON.stringify(arr));
    swapArrayElements(tabsArray, old_index, new_index);
    return tabsArray;
  };

  useEffect(() => {
    if (tabs) {
      return;
    }

    let initTabs = [];

    if (parentDataSource && !parentDataSource.singleData) {
      setProp((props) => {
        props.isMultipleData = true;
        props.orientation = OrientationValues.vertical;
      }, 500);

      if (parentDataSource.source === 'Article') {
        initTabs.push({
          id: 'articleNumber' + '-' + new Date().getTime(),
          label: {
            de: 'Art. Nr.',
            en: 'Art. No.',
            it: 'Arti. No.',
            fr: 'Art. N°'
          },
          singleData: true
        });
      } else {
        let instanceTabLabel = i18n.t(
          'tabeditor.settings.multipleDataSourceField'
        );

        ajaxGet('dataManagement/instanceDataIdentifierDef', {
          instanceTypeTarget: parentDataSource.source
        }).then((ajaxData) => {
          if (!ajaxData.error && ajaxData.response) {
            let dataIdentifierDef = ajaxData.response.message;
            if (dataIdentifierDef) {
              instanceTabLabel = dataIdentifierDef;
            }
          }

          initTabs.push({
            id: parentDataSource.source + '-' + new Date().getTime(),
            label: { noLangLabel: instanceTabLabel },
            singleData: true
          });

          setProp((props) => ((props.tabs = initTabs), 500));
        });

        return;
      }
    } else {
      initTabs.push({
        id: id + '-' + new Date().getTime(),
        label: initTabTexts,
        singleData: true,
        selected: true
      });
    }

    setProp((props) => ((props.tabs = initTabs), 500));
  }, []);

  function selectTab(_, selectedIndex) {
    setProp((props) => ((props.selectedTabIndex = selectedIndex), 500));
  }

  function editTabLabel(event, targetIndex) {
    setProp((props) => {
      props.tabs = props.tabs.filter((tab) => tab);
      const targetTab = props.tabs.find(
        (_, curIndex) => curIndex === targetIndex
      );
      const newLangLabel = sanitizeFromXML(event.target.value);
      targetTab.label[lang] = newLangLabel;
    }, 500);
  }

  function createAddTabDataRequirementFunction(targetIndex) {
    return function (dataSource, dataTypeId, dataReqCategory) {
      if (!dataSource || !dataTypeId) return;

      if (isInOuterTabContext) {
        addOuterTabDataRequirement(dataSource, dataTypeId, dataReqCategory);
      }

      setProp((props) => {
        props.tabs = props.tabs.filter((tab) => tab);
        const targetTab = props.tabs.find(
          (_, curIndex) => curIndex === targetIndex
        );
        let dataReq = targetTab.dataReq || {};
        let sourceReq = dataReq[dataSource] || {};

        let categoryReq = sourceReq[dataReqCategory] || {};
        categoryReq[dataTypeId] = true;
        sourceReq[dataReqCategory] = categoryReq;
        dataReq[dataSource] = sourceReq;
        targetTab.dataReq = dataReq;
      }, 500);
    };
  }

  function createDeleteTabDataRequirementFunction(targetIndex) {
    return function (newDataTypeIds) {
      if (!newDataTypeIds) return;

      if (isInOuterTabContext && forceUpdateTabCanvas) {
        forceUpdateTabCanvas();
      }

      setProp((props) => {
        props.tabs = props.tabs.filter((tab) => tab);
        const targetTab = props.tabs.find(
          (_, curIndex) => curIndex === targetIndex
        );

        if (!targetTab) return;
        let dataReq = targetTab.dataReq;
        if (!dataReq) return;

        for (let sourceReqKey in dataReq) {
          let sourceReq = dataReq[sourceReqKey];
          for (let categoryReqKey in sourceReq) {
            let categoryReq = sourceReq[categoryReqKey];
            for (let dataTypeId in categoryReq) {
              if (!newDataTypeIds.includes(dataTypeId)) {
                delete categoryReq[dataTypeId];
                if (!Object.keys(categoryReq).length) {
                  delete sourceReq[categoryReqKey];
                  if (!Object.keys(sourceReq).length) {
                    delete dataReq[sourceReqKey];
                  }
                }
              }
            }
          }
        }
      }, 500);
    };
  }

  if (!tabs) {
    return null;
  } else {
    return (
      <Container>
        <div className={isVertical ? classes.verticalFlexWrap : null}>
          <TabsWrap orientation={orientation}>
            <MuiTabs
              value={selectedTabIndex}
              onChange={selectTab}
              orientation={orientation}
              variant="scrollable"
              scrollButtons="on"
              className={isVertical ? classes.tabsVertical : classes.tabs}
            >
              {tabs
                .filter((tab) => tab)
                .map((tab, index) => (
                  <MuiTab
                    draggable={true}
                    onDragOver={onDragOverTab}
                    onDragStart={handleDragTab}
                    onDrop={handleDropTab}
                    id={tab.id}
                    key={tab.id}
                    {...a11yProps(index)}
                    label={
                      <React.Fragment>
                        <ContentEditable
                          html={tab.label[lang] || tab.label.noLangLabel}
                          disabled={isMultipleData}
                          onChange={(e) => editTabLabel(e, index)}
                          tagName="p"
                          style={{ fontSize: '12px' }}
                          className={`${
                            isVertical ? classes.tabTitleVertical : null
                          } ${isMultipleData ? null : classes.tabTitleEdit}`}
                        />
                      </React.Fragment>
                    }
                  />
                ))}
            </MuiTabs>
          </TabsWrap>
          <div className="tabsContentWrap" style={{ flex: 1 }}>
            {tabs
              .filter((tab) => tab)
              .map((tab, index) => (
                <TabPanel
                  key={tab.id}
                  selectedTabIndex={selectedTabIndex}
                  index={index}
                  orientation={orientation}
                >
                  <TabDataContext.Provider
                    value={[
                      true,
                      createAddTabDataRequirementFunction(index),
                      createDeleteTabDataRequirementFunction(index)
                    ]}
                  >
                    <Element
                      canvas
                      is={TabCanvas}
                      id={tab.id}
                      dataSource={{
                        source: parentDataSource.source,
                        singleData: tab.singleData,
                        childOverride: parentDataSource.childOverride
                      }}
                    ></Element>
                  </TabDataContext.Provider>
                </TabPanel>
              ))}
          </div>
        </div>
      </Container>
    );
  }
};
Tabs.propTypes = {
  tabs: PropTypes.array,
  orientation: PropTypes.any,
  isMultipleData: PropTypes.bool,
  selectedTabIndex: PropTypes.number
};

export const TabsWrap = ({ orientation, children }) => {
  const classes = useStylesTabs();

  return orientation == OrientationValues.horizontal ? (
    <AppBar
      position="static"
      style={{
        background: 'white',
        color: 'black',
        borderBottom: '#ffd8a9 solid 2px',
        borderRadius: 4
      }}
      className={classes.tabsNoShadow}
    >
      {children}
    </AppBar>
  ) : (
    children
  );
};

export const useStylesTabsSettings = makeStyles(() => ({
  buttonsWrap: {
    textAlign: 'center'
  },
  buttons: {
    marginTop: '1rem'
  }
}));
export const TabsSettings = () => {
  const classes = useStylesTabsSettings();
  const { t } = useTranslation();
  const {
    tabs,
    orientation,
    isMultipleData,
    selectedTabIndex,
    actions: { setProp },
    id
  } = useNode((node) => ({
    tabs: node.data.props.tabs,
    orientation: node.data.props.orientation,
    isMultipleData: node.data.props.isMultipleData,
    selectedTabIndex: node.data.props.selectedTabIndex
  }));

  function createTab() {
    const newTabId = id + '-' + new Date().getTime();
    const newTabs = tabs.concat({
      id: newTabId,
      label: createTabTexts,
      singleData: true,
      selected: false
    });
    setProp((props) => (props.tabs = newTabs));
  }

  function deleteSelectedTab() {
    if (tabs.length <= 1) {
      return;
    }

    setProp((props) => {
      props.tabs = tabs.filter((_, index) => index != selectedTabIndex);
      props.selectedTabIndex = 0;
    }, 500);
  }

  function changeOrientation(event) {
    setProp((props) => {
      props.orientation = event.target.value;
    });
  }

  return (
    <>
      {!isMultipleData && (
        <Grid item className={classes.buttonsWrap}>
          <div>
            <button className="btn btn-success iconButton" onClick={createTab}>
              <i className="fa fa-plus" aria-hidden="true"></i>
              <span>{t('tabeditor.tools.tabs.add')}</span>
            </button>
          </div>
          {tabs.length > 1 && (
            <div className={classes.buttons}>
              <button
                className="btn btn-danger iconButton"
                onClick={deleteSelectedTab}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
                <span>{t('tabeditor.tools.tabs.deleteSelected')}</span>
              </button>
            </div>
          )}
        </Grid>
      )}
      <Grid item>
        <FormControl
          variant="outlined"
          component="fieldset"
          style={{ marginTop: '2rem' }}
        >
          <InputLabel id="tabs-config-orientation-label">
            {t('tabeditor.tools.tabs.orientation')}
          </InputLabel>
          <Select
            labelId="tabs-config-orientation-label"
            id="tabs-config-orientation"
            label={t('tabeditor.tools.tabs.orientation')}
            defaultValue={OrientationValues.horizontal}
            value={orientation}
            onChange={changeOrientation}
          >
            <MenuItem value={OrientationValues.horizontal}>
              {t('tabeditor.tools.tabs.horizontal')}
            </MenuItem>
            <MenuItem value={OrientationValues.vertical}>
              {t('tabeditor.tools.tabs.vertical')}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export const TabsDefaultProps = {
  tabs: null,
  orientation: OrientationValues.horizontal,
  isMultipleData: false,
  selectedTabIndex: 0
};
Tabs.craft = {
  props: TabsDefaultProps,
  related: {
    settings: TabsSettings
  }
};
