import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  IconButton,
  Container,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  DialogTitle,
  Dialog,
  makeStyles
} from '@material-ui/core';
import { InputAdornment, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ProductList from './productList';
import Pagination from './Pagination';
import useFetch from './AjaxServiceCall';
import ajaxGet from 'src/services/ajaxGet';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { useDataManagement } from '../DataManagementContext';
import PimSpinner from 'src/jsx/components/common/PimSpinner';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5px'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  titel: {
    backgroundColor: ' #f29528',
    color: '#08080 !important',
    margin: '0px'
  }
}));

export default function AddMedien({
  open,
  handleClose,
  searchUrl,
  addImage,
  blockDefault
}) {
  const classes = useStyles();
  const [searchMedienName, setSearchMedienName] = useState('');

  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [elementsPerPage, setElementsPerPage] = useState(10);
  const [paginationstate, setPaginationstate] = useState({});
  const { getIsLoadingAddMedia } = useDataManagement();
  const handelEventSearchMedien = () => {
    setCurrentPage(1);
    setElementsPerPage(10);
    searchMedien();
  };

  const changeStatusItem = (product) => {
    for (var i in products) {
      if (products[i].id == product.id) {
        products[i].actions[0].url = null;
        break; //Stop this loop, we found it!
      }
    }
    setProducts(products);
  };
  const searchMedien = () => {
    setProducts([]);
    ajaxGet('dataManagement/reactSearchLinkedInstances?' + searchUrl, {
      searchTerm: searchMedienName,
      currentPage: currentPage,
      elementsPerPage: elementsPerPage,
      blockDefault: blockDefault
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let productMap = ajaxData.response.resultDisplayItems.filter(
          (product) => !product.error
        );
        setProducts(productMap);
        setPaginationstate(ajaxData.response.pagination);
      }
      setError(ajaxData.error);
      setLoading(false);
    });
  };

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    searchMedien();
  }, [currentPage, elementsPerPage]);

  const handleChange = (ev) => {
    setSearchMedienName(ev.target.value);
  };

  function handleChangeCurrentPage(newValue) {
    setCurrentPage(newValue);
  }

  function handleChangeElementsPerPage(newValue) {
    setElementsPerPage(newValue);
  }

  const gotoPage = (val) => {
    setCurrentPage(val);
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleKeypress = (event) => {
    //it triggers by pressing the enter key
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      searchMedien();
    }
  };

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'black',
      background: '#ebddbad1',
      border: '0.5px solid',
      '&:hover': {
        //you want this to be the same as the backgroundColor above
        backgroundColor: '#625c5652'
      }
    }
  });

  function closeAddMedienDialog() {
    setProducts([]);
    handleClose();
  }

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  return (
    <div>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={closeAddMedienDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          fontWeight="fontWeightBold"
          id="customized-dialog-title"
          onClose={closeAddMedienDialog}
          className={classes.titel}
        >
          Medien hinzufügen
        </DialogTitle>
        <DialogContent>
          <Grid className={classes.root}>
            <form>
              <TextField
                label="suchen"
                margin="normal"
                id="name search"
                fullWidth
                value={searchMedienName}
                onChange={handleChange}
                onKeyDown={handleKeypress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      onClick={() => handelEventSearchMedien()}
                      position="start"
                    >
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </form>
          </Grid>
          <Grid className={classes.root}>
            {products.length != 0 && (
              <Pagination
                paginationstate={paginationstate}
                searchMedien={searchMedien}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                currentPage={currentPage}
                elementsPerPage={elementsPerPage}
                handleChangeCurrentPage={handleChangeCurrentPage}
                handleChangeElementsPerPage={handleChangeElementsPerPage}
              />
            )}
          </Grid>
          {'   '}
          <Grid className={classes.root}>
            {getIsLoadingAddMedia() ? (
              <PimSpinner></PimSpinner>
            ) : (
              products && (
                <ProductList
                  changeStatusItem={changeStatusItem}
                  products={products}
                  addImage={addImage}
                />
              )
            )}
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
