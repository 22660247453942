
import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';

import { standardEditor } from '../tabeditor/standardEditor';

import ajaxGet from '../../../services/ajaxGet';

import { useImmer } from 'use-immer';

const URL_TAB_EDITOR = 'tabEditor/';

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		display: 'inline-flex' 
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	titelSelect:{
		fontSize: "17px !important",
		fontFamily: "'Roboto', sans-serif !important",
		color: "#636363 !important",
		margin: '0px 7px 0px -6px'
	}
}));


export function getInstanceTypePayload(instanceType) {
	let objectClass = null;
	let subType = null;
	if (instanceType === 'Product') {
		objectClass = instanceType;
	} else {
		subType = instanceType;
	}

	return [objectClass, subType];
}

const PRODUCT_STANDARD_EDITOR = "__product_standard_editor__";

export const SelectEditor = ({setTargetEditorConfig}) => {

		const classes = useStyles();
		// const { actions, query } = useEditor();
		const [editorList , setEditorList] = useImmer([]);
		const [selectedEditorId, setSelectedEditorId] = useState('');
		const [showSelect, setShowSelect] = useState(false);
	
		const handleChangeEditor = (event) => {
			let changedEditorId = event.target.value;
			let res = editorList.find((editor) =>  editor.id === changedEditorId);
			let editorId = res ? res.id : PRODUCT_STANDARD_EDITOR;
					
			const url = URL_TAB_EDITOR + 'setEditorId';
			ajaxGet(url, {
				editorId: editorId,
			}).then((ajaxData) => {
				if (!ajaxData.error && ajaxData.response) {
					if (res) {
						res = JSON.parse(res.configurationJson);
						setTargetEditorConfig(res);
						setSelectedEditorId(res.id);							
					}
					else if (changedEditorId === PRODUCT_STANDARD_EDITOR) {
						setSelectedEditorId(PRODUCT_STANDARD_EDITOR);
						setTargetEditorConfig(standardEditor);
					}						
				}
			});

		};
	
		useEffect(() => {
			const abortCont = new AbortController();
			const instanceType = "Product";
			const [objectClass, customInstanceType] = getInstanceTypePayload(instanceType);
	
			const url = URL_TAB_EDITOR + 'getEditorConfigsSelectEditor';
			ajaxGet(url, {
				containingObjectClass: objectClass,
				customInstance: customInstanceType
			}).then((ajaxData) => {
				if (!ajaxData.error && ajaxData.response) {
					let loadedVersions = ajaxData.response.editorConfigDataResultList;
					loadedVersions.sort((a, b) => b.modified - a.modified);
					setEditorList(loadedVersions);
					let id =  ajaxData.response.selectedEditorId;
					if (id) {
						let appliedVersion = loadedVersions.find((version) => version.id === id);
						if (appliedVersion) {
							setSelectedEditorId(appliedVersion.id);

							appliedVersion = JSON.parse(appliedVersion.configurationJson);
							setTargetEditorConfig(appliedVersion);
						}
						else {
							setSelectedEditorId(PRODUCT_STANDARD_EDITOR);
							setTargetEditorConfig(standardEditor);
						}						
					} else {
						let appliedVersion = loadedVersions.find((version) => version.applied && version.id === selectedEditorId);
						if (appliedVersion) {
							setSelectedEditorId(appliedVersion.id);
						}
						else {
							setSelectedEditorId(PRODUCT_STANDARD_EDITOR);
							setTargetEditorConfig(standardEditor);
						}
							
					
					}

					setShowSelect(true);
				}
			});

			return () => abortCont.abort();

		}, [selectedEditorId]);

		if (!showSelect) {
			return null;
		}

		return (
			<FormControl className={classes.formControl}>
			<div style={{ display: 'inline-flex' }}>
				<div className={classes.titelSelect}>
				<Typography
					variant="body1"
					style={{ top: '0.5rem', position: 'relative' }}
				>
					Editor :
				</Typography>
				</div>
				<div style={{ alignSelf: 'center' }}>
				<Select value={selectedEditorId} onChange={handleChangeEditor}>
					<MenuItem value={PRODUCT_STANDARD_EDITOR}>Standard</MenuItem>
					{editorList.map((Editor) => (
					<MenuItem key={Editor.id} value={Editor.id}>
						{Editor.name}
					</MenuItem>
					))}
				</Select>
				</div>
			</div>
			</FormControl>
	);

};
