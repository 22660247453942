import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, LinearProgress } from '@material-ui/core';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  abcEditorInTable: {
    maxWidth: '25rem',
    width: '16rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  input: {
    '&:invalid': {
      border: 'red solid 2px'
    }
  }
}));

export const getAlphanumericPayloadData = (value) => ({
  alphaNumericValue: value
});

export const Alphanumeric = ({
  featureInfo,
  text,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();

  const classes = useStyles();

  text = text == null ? '' : text;
  text = featureInfo.formula == null ? text : featureInfo.formula;
  const [inputState, setInputState] = useState(text);
  const [blurState, setBlurState] = useState(text);

  const title = isInTableContext ? null : featureInfo.title;

  const mandatory = featureInfo.mandatory;
  const isMandatoryError = mandatory && !inputState;
  const readOnly = featureInfo.readOnly;
  const regExError = featureInfo.isRegExError;
  const regexRestriction = featureInfo.regexRestriction;
  const regExErrorMessage = featureInfo.regexRestrictionMsg;

  const hasDefaultData = featureInfo.hasDefaultData;

  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !hasDefaultData) {
    helper = t('common.errorMandatory');
  } else if (regExError) {
    helper =
      'Die eingegebenen Daten entsprechen nicht den Vorgaben:\n' +
      (regExErrorMessage ? regExErrorMessage : '') +
      ' ' +
      regexRestriction;
  }

  useEffect(() => {
    setInputState(text);
  }, [text]);

  function saveValue(newValue) {
    if (!newValue) {
      if (mandatory && !hasDefaultData && !featureInfo.storePayloadData) {
        return;
      }
      newValue = null;
    }

    if (blurState == newValue || (!blurState && !newValue) || loading) return;
    setBlurState(newValue);

    setValueState(newValue);
    saveChangedValue(newValue, getAlphanumericPayloadData(newValue));
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  function handleSetInputState(value) {
    // if (regexRestriction) {
    //   const regex = new RegExp('^' + regexRestriction + '$');
    //   if (value === '' || regex.test(value)) {
    //     helper = '';
    //     setInputState(value);
    //   } else {
    //     helper = 'Regex nicht erfüllt: ' + regexRestriction;
    //   }
    // } else {
    setInputState(value);
    // }
  }

  return (
    <TextField
      label={title}
      InputLabelProps={{
        shrink: false,
        disableAnimation: true,
        className: classesTitle.title
      }}
      className={
        isInTableContext
          ? classes.abcEditorInTable
          : classesTitle.datepickerStyle
      }
      variant={isInTableContext ? 'standard' : 'outlined'}
      value={inputState}
      error={
        Boolean(error) || (isMandatoryError && !hasDefaultData) || regExError
      }
      FormHelperTextProps={{
        component: 'div',
        style: { whiteSpace: 'pre-wrap', wordWrap: 'break-word' }
      }}
      helperText={helper}
      onChange={(event) => {
        if (!readOnly) {
          handleSetInputState(event.target.value);
        }
      }}
      // inputProps={
      //   regexRestriction
      //     ? { className: classes.input, pattern: regexRestriction }
      //     : {}
      // }
      onBlur={(event) => saveValue(event.target.value)}
      onMouseEnter={showError}
    />
  );
};
