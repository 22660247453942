import ajaxGet from './ajaxPost';
import ajaxPost from './ajaxPost';

const wait = (interval) =>
  new Promise((resolve) => setTimeout(resolve, interval));

async function ajaxRetry(ajaxCall, retriesLeft = 10, interval = 1000) {
  try {
    return await ajaxCall().then((response) => {
      let ajaxData = {};
      if (response.error) {
        ajaxData.error = response.error;
      }
      if (response.success) {
        ajaxData.response = response;
      } else {
        ajaxData.error = response.message;
      }
      return ajaxData;
    });
  } catch (error) {
    let errorMsg = getHttpErrorCodeMsg(error.status);
    if (error.status != 423) {
      return Promise.resolve({ error: errorMsg });
    }
    if (retriesLeft === 0) {
      return Promise.resolve({ error: errorMsg });
    }

    console.log('Trying again: ' + errorMsg);
    await wait(interval);
    return await ajaxRetry(ajaxCall, --retriesLeft, interval);
  }
}

export function ajaxGetRetry({ url, params, retries, interval }) {
  let call = ajaxGet(url, params, true);

  return ajaxRetry(call, retries, interval);
}

export function ajaxPostRetry({ url, params, json, retries, interval }) {
  function post() {
    return ajaxPost(url, params, json, null, true);
  }

  return ajaxRetry(post, retries, interval);
}

function getHttpErrorCodeMsg(status) {
  let msg = '';
  switch (status) {
    case 0:
      msg = TOGO.DefaultMessages.noConnection;
      break;
    case 400:
      msg = TOGO.DefaultMessages.badRequest;
      break;
    case 401:
      msg = TOGO.DefaultMessages.unauthorized;
      break;
    case 403:
      msg = TOGO.DefaultMessages.forbidden;
      break;
    case 404:
      msg = TOGO.DefaultMessages.notFound;
      break;
    case 423:
      msg = TOGO.DefaultMessages.locked;
      break;
    case 422:
      msg = TOGO.DefaultMessages.clientDataError;
      break;
    case 500:
      msg = TOGO.DefaultMessages.internalServerError;
      break;
    default:
      msg = TOGO.DefaultMessages.errorTitle;
      break;
  }
  return msg;
}
