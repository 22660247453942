import React, { useState, useEffect } from 'react';

import PimSpinner from '../components/common/PimSpinner';

import ajaxPost from '../../services/ajaxPost.js';

import { InstanceTabEditorReader } from '../articledata/tabeditor/reader/InstanceTabEditorReader';
import { ptdDefaultDataForProductEditor } from './ptdDefaultDataForProductEditor';

export default function PtdDefaultDataCustomInstance({
  customInstanceType,
  ptdId,
  isCustomInstanceExpanded
}) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customInstanceId, setCustomInstanceId] = useState(null);

  useEffect(() => {
    if (isCustomInstanceExpanded) {
      ajaxPost('dataManagement/setPtdDefaultDataTargetLinkCustomInstance', {
        customInstance: customInstanceType,
        id: ptdId
      }).then((ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          setCustomInstanceId(ajaxData.response.id);
        }
        setError(ajaxData.error);
        setLoading(false);
      });
    }
  }, [isCustomInstanceExpanded]);

  if (error) {
    return <div>Error: {error}</div>;
  } else if (customInstanceId === null || loading) {
    return <PimSpinner />;
  } else {
    return (
      <InstanceTabEditorReader
        readOnly={false}
        instanceType={customInstanceType}
        instanceId={customInstanceId}
        startingRootNodeId={'ROOT'}
        loadedNodesContent={ptdDefaultDataForProductEditor}
        loadedContentLang={$('#contentLangSelectElement').val() || null}
      />
    );
  }
}
