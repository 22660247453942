import React from 'react';

import { CircularProgress, Grid } from '@material-ui/core';

export default function PimSpinner() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}>
        <CircularProgress
          style={{
            color: '#F29528'
          }}
        />
      </Grid>
    </Grid>
  );
}
