import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import { useImmer } from 'use-immer';

import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n.js';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    width: '50rem',
    height: '40rem',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  titleHead: {
    textAlign: 'center'
  },
  bmeCatTooltip: {
    '& > .MuiTooltip-tooltip': {
      whiteSpace: 'pre-wrap',
      textAlign: 'left !important'
    }
  }
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export const TransferList = ({
  groupId,
  specificationModel,
  setSpecificationModel,
  titleRight,
  saveList,
  setSaveList
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useImmer([]);
  const [right, setRight] = useImmer([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const { t } = useTranslation();

  useEffect(() => {
    setLeft(specificationModel.leftLists[groupId]);
    setRight(specificationModel.rightLists[groupId]);
  }, [specificationModel]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));

    const newLeft = not(left, leftChecked);
    setLeft(newLeft);
    let initSave = { ...saveList };
    leftChecked.forEach((element) => {
      delete initSave[element.id];
    });
    // newLeft.forEach((element) => {
    //   initSave[element.id] = element;
    // });
    setSaveList(initSave);
    setChecked(not(checked, leftChecked));
    
    specificationModel.leftLists[groupId] = not(left, leftChecked);
    specificationModel.rightLists[groupId] = right.concat(leftChecked)
    setSpecificationModel(specificationModel);

  };

  const handleCheckedLeft = () => {
    const newLeft = left.concat(rightChecked);
    setLeft(newLeft);
    let initSave = { ...saveList };
    rightChecked.forEach((element) => {
      initSave[element.id] = element;
    });
    setSaveList(initSave);

    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

    specificationModel.leftLists[groupId] = left.concat(rightChecked);
    specificationModel.rightLists[groupId] = not(right, rightChecked)
    setSpecificationModel(specificationModel);

  };

  const customList = (title, items) => (
    <Card>
      <h4 className={classes.titleHead}>{title}</h4>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={i18n.t('bmeCatDataModel.selectAll')}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => (
          <ListItem
            key={value.id}
            role="listitem"
            button
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  'aria-labelledby': `transfer-list-all-item-${value.id}-label`
                }}
              />
            </ListItemIcon>
            {value.description ? (
              <Tooltip
                title={<React.Fragment>{value.description}</React.Fragment>}
                PopperProps={{ className: classes.bmeCatTooltip }}
              >
                <ListItemText
                  id={`transfer-list-all-item-${value.id}-label`}
                  primary={
                    value.contentLangLabel.missingContentLang
                      ? `<${value.contentLangLabel.typeName}: ${value.contentLangLabel.text}>`
                      : value.contentLangLabel.text
                  }
                />
              </Tooltip>
            ) : (
              <ListItemText
                id={`transfer-list-all-item-${value.id}-label`}
                primary={
                  value.contentLangLabel.missingContentLang
                    ? `<${value.contentLangLabel.typeName}: ${value.contentLangLabel.text}>`
                    : value.contentLangLabel.text
                }
              />
            )}
          </ListItem>
        ))}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        {customList(i18n.t('bmeCatDataModel.currentDataModel'), left)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(titleRight, right)}</Grid>
    </Grid>
  );
};
