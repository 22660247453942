import React, { useState, useEffect } from 'react';

import { useExport } from './ExportContext';
import { FeatureComponent } from '../articledata/datamanagement/featurecomponents/FeatureComponent';

export const ExportCustomFieldEditor = ({ customField }) => {
  const {
    setCustomFieldValues,
    setCustomFieldState,
    getCustomFieldState,
    getCustomFieldValues,
    deleteCustomFieldValue,
    deleteFromRegExErrorList,
    setRegExErrorList
  } = useExport();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [regExError, setRegExError] = useState(false);
  const customFieldEditorData = {};

  const customFieldId = customField.contentIdentifier;
  const hashCode = customField.keyHashCode;
  let value = getCustomFieldState(hashCode);

  let title = customFieldId;
  let attributes = customField.bmeCatAttributes;
  let isEmpty = true;

  let regexRestriction = customField.regexRestriction;
  let regExErrorMessage = customField.regExErrorMessage;

  if (regexRestriction) {
    customFieldEditorData.regexRestriction = regexRestriction;
    customFieldEditorData.isRegExError = regExError;
    customFieldEditorData.regexRestrictionMsg = regExErrorMessage;
  }

  let numericMinRestriction = customField.numericMinRestriction;
  let numericMaxRestriction = customField.numericMaxRestriction;
  let numericStepRestriction = customField.numericStepRestriction;

  if (
    numericMinRestriction &&
    numericMaxRestriction &&
    numericStepRestriction &&
    !regexRestriction
  ) {
    customFieldEditorData.numericMinRestriction = numericMinRestriction;
    customFieldEditorData.numericMaxRestriction = numericMaxRestriction;
    customFieldEditorData.numericStepRestriction = numericStepRestriction;
    customFieldEditorData.isRegExError = regExError;
    customFieldEditorData.regexRestrictionMsg = regExErrorMessage;
  }

  if (attributes) {
    for (let keys in attributes) {
      isEmpty = false;
      break; // exiting since we found that the object is not empty
    }
    if (!isEmpty) {
      title += ' (';
      for (let key in attributes) {
        title += ' ' + key + '=' + attributes[key];
      }
      title += ' )';
    }
  }
  customFieldEditorData.title = title;
  customFieldEditorData.mandatory = customField.mandatory;

  const featureType = customField.featureType;

  customFieldEditorData.featureComponent = {};
  customFieldEditorData.featureComponent.featureType = featureType;

  customFieldEditorData.customFieldNotCommonOptions = true;

  if (customField.featureType === 'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION') {
    customFieldEditorData.featureComponent.multiSelection = true;
  }

  // if (customField.defaultValueAlphanumeric) {
  //   setCustomFieldState(customFieldId, customField.defaultValueAlphanumeric);
  //   setCustomFieldValues(customField, {
  //     alphaNumericValue: customField.defaultValueAlphanumeric
  //   });
  // }

  // if (customField.defaultValueNumeric) {
  //   setCustomFieldState(customFieldId, customField.defaultValueNumeric);
  //   setCustomFieldValues(customField, {
  //     numericValue: customField.defaultValueNumeric
  //   });
  // }

  function setValueState(clientStateValue) {
    setCustomFieldState(hashCode, clientStateValue);
  }

  function saveCreateValue(newValue, valuePayload) {
    if (newValue) {
      if (regexRestriction) {
        const regex = new RegExp('^' + regexRestriction + '$');
        if (newValue === '' || regex.test(newValue)) {
          setCustomFieldValues(hashCode, valuePayload);
          setRegExError(false);
          deleteFromRegExErrorList(hashCode);
        } else {
          setRegExError(true);
          setRegExErrorList(hashCode);
        }
      } else if (
        numericMinRestriction &&
        numericMaxRestriction &&
        numericStepRestriction &&
        !regexRestriction
      ) {
        if (
          newValue >= numericMinRestriction &&
          newValue <= numericMaxRestriction &&
          newValue % numericStepRestriction == 0
        ) {
          setCustomFieldValues(hashCode, valuePayload);
          setRegExError(false);
          deleteFromRegExErrorList(hashCode);
        } else {
          setRegExError(true);
          setRegExErrorList(hashCode);
        }
      } else {
        setCustomFieldValues(hashCode, valuePayload);
      }
    } else {
      deleteCustomFieldValue(hashCode);
      setRegExError(false);
      deleteFromRegExErrorList(hashCode);
    }
    if (value?.selected) {
      if (!newValue) {
        newValue = {};
        newValue.customOptions = [];
        newValue.selected = [];
      }
      newValue.standardOptions = value.standardOptions;
    }

    setCustomFieldState(hashCode, newValue);
  }

  if (!customFieldEditorData) {
    return null;
  }
  return (
    <div
      className="custom-field-editor instanceEditorComponent"
      style={{ margin: '2rem 0' }}
    >
      <FeatureComponent
        featureInfo={customFieldEditorData}
        value={value}
        setValueState={setValueState}
        saveChangedValue={saveCreateValue}
        loading={loading}
        error={error}
      />
    </div>
  );
};
