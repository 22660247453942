export const createArticleView = {
  CREATE_ARTICLE_ROOT: {
    type: { resolvedName: 'Container' },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 10,
      dataSource: {
        source: 'Article',
        singleData: true,
        childOverride: true
      }
    },
    displayName: 'Container',
    custom: {},
    parent: null,
    hidden: false,
    nodes: ['b2n-KjXCk', 'W8l9V4apv', 'SB_5ML9l7', 'eEwr40ufH'],
    linkedNodes: {}
  },
  'b2n-KjXCk': {
    type: { resolvedName: 'FieldEditorSelector' },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: 'articleNumber',
      label: 'Feldtyp auswählen...'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'IZJgZ28uSI',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  eEwr40ufH: {
    type: { resolvedName: 'FieldEditorSelector' },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'features'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'IZJgZ28uSI',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  SB_5ML9l7: {
    type: { resolvedName: 'FieldEditorSelector' },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'IZJgZ28uSI',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  W8l9V4apv: {
    type: { resolvedName: 'TextEditorSelector' },
    isCanvas: false,
    props: { variant: 'simple', bulkDataType: 'texts' },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'IZJgZ28uSI',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  }
};
