import React from 'react';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useContainerStyles = makeStyles(() => ({
  root: {
    '& > .instanceEditorComponent': {
      margin: '2rem 1rem'
    }
  },
  two: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'calc(100vw - 53rem)',
    '& > *': {
      flex: '1 0 100%'
    },
    '& > .instanceEditorComponent': {
      flex: '1 0 48% !important',
      margin: '1rem 5px !important'
    }
  }
}));

export const ContainerDisplay = ({
  background,
  padding,
  containerFlexColumns,
  children
}) => {
  const classes = useContainerStyles();
  return (
    <Paper
      className={`${classes.root} ${
        containerFlexColumns === '2' ? classes.two : null
      } containerDisplayPaper`}
      style={{ margin: '5px 0', background, padding: `${padding}px` }}
    >
      {children}
    </Paper>
  );
};
