import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		margin: '11px 10px',
		border:"1px solid"
	},
	details: {
		display: 'flex',
		flexDirection: 'flex-end',
	},
	content: {
		flex: '1 0 auto',
		margin: 'auto'
	},
	cover: {
		width: "174px",
		height: "128px",
		display: "flex",
		justifyContent: "center",
		margin: "1px"
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		// paddingLeft: theme.spacing(1),
		// paddingBottom: theme.spacing(1),
		position: 'relative',
		left: "53px",
		top: "34px"
	},
	playIcon: {
		height: 38,
		width: 38,
	},
	title:{
		margin:"auto",
 }

}));

export default function ProductCard({product ,addImage, changeStatusItem}) {
		// const [shadow, setShadow] = useState({});
		// const onMouseOver = () => setShadow({ shadow: 3 });
		// const onMouseOut = () => setShadow({ shadow: 1 });
		const classes = useStyles();
		const [disabledCard, setDisabledCard] = useState(true);

	useEffect(() => {
		if (product.actions[0].url != null ){
			setDisabledCard(false)
		}
	}, [])


	return (
		<Card
		style={{
			opacity: disabledCard ? 0.25 : 1,
			pointerEvents: disabledCard ? "none" : "initial"
		}}    // onMouseOver={onMouseOver}
		// onMouseOut={onMouseOut}
		// zDepth={shadow}
		className={classes.root}>
				<CardMedia 
				className={classes.cover}
				image={product.itemPicture.thumbnailUri}
				title="Live from space album cover"
			/>
			<div className={classes.details}>
				<CardContent className={classes.content}>
						{product.title}
				</CardContent>
				<div  >
					<IconButton onClick={() => { setDisabledCard(true); addImage(product, function() {
						changeStatusItem(product);
					}); }}  className={classes.controls} >
						<AddCircleOutlineIcon fontSize="large" className={classes.playIcon} />
					</IconButton>
				</div>
			</div>
		</Card>
	);
}
