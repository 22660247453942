import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


import {
  Dialog,
  DialogTitle,

} from '@material-ui/core';

import  { usePimStyles } from '../PimTogoTheme';


import UserForm from '../user/UserForm';

export const CreateUser = ({
  openDialogUser,
  handleCloseDialogUser,
  initFormState,
  isLocked,
  toCreateOrga,
  internalEdit,
  roleInfos,
  orgList,
  searchUsers
}) => {
  const { t } = useTranslation();
  const classesPim = usePimStyles();

  useEffect(() => {}, []);

  return (
    <Dialog
      aria-labelledby="create-instance-title"
      open={openDialogUser}
      onClose={handleCloseDialogUser}
      className={classesPim.dialog}
      transitionDuration={0}
    >
      <DialogTitle
        id="create-instance-title"
        className={classesPim.dialogTitle}
      >        {toCreateOrga ? t('managUsers.register.createTitle') : t('managUsers.register.editTitle') }

      </DialogTitle>
      <UserForm
        handleCloseDialogUser={handleCloseDialogUser}
        roleInfos={roleInfos}
        orgList={orgList}
        initFormState={initFormState}
        isLocked={isLocked}
        toCreateOrga={toCreateOrga}
        internalEdit={internalEdit}
        searchUsers={searchUsers}
      />
    </Dialog>
  );
};

export default CreateUser;
