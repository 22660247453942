import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useDataManagement } from './DataManagementContext';
import MediaEditorMain from './media/MediaEditorMain';

import ajaxPost from 'src/services/ajaxPost';
import ajaxGet from 'src/services/ajaxGet';

const useStyles = makeStyles(() => ({
  defaultData: {
    '& .mediaContainer > .MuiBox-root': {
      backgroundColor: '#fafad2'
    }
  }
}));

export const MediaEditor = ({ mediaType, instanceEditor }) => {
  const {
    getMediaState,
    setMediaState,
    isDefaultData,
    deleteDefaultData,
    setDefaultData,
    setBlockDefaultData,
    deleteBlockDefaultData,
    isBlockDefaultData,
    setIsLoadingAddMedia
  } = useDataManagement();
  const classes = useStyles();
  const instanceId = instanceEditor.instanceId;
  const mediaList = getMediaState(instanceId, mediaType);
  const mediaEditorData = instanceEditor.mediaEditor[mediaType];
  const searchUrl = mediaEditorData?.addLinkedInstanceUrl.split('?')[1];

  const defaultDataObject = {
    id: mediaType,
    type: 'mediaEditor',
    instanceId: instanceEditor.instanceId
  };
  const blockDefault = isBlockDefaultData(defaultDataObject);
  const isDefaultDataPTD = isDefaultData(defaultDataObject);

  const updateMediaList = (mediaListItem) => {
    setMediaState(instanceId, mediaType, mediaListItem);
  };

  function onDelete(id) {
    const item = mediaList.find((image) => image.id === id);
    const mediaListChanged = mediaList.filter((media) => media.id !== id);

    ajaxPost(
      item.changePositionUri +
        '&delete=' +
        true +
        '&dragged=' +
        0 +
        '&dropped=' +
        0 +
        '&shift=' +
        0,
      {},
      {}
    ).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let mediaModel = ajaxData.response.mediumModel;
        let resetDefaultData = ajaxData.response.resetDefault;
        if (mediaModel && mediaModel.length) {
          setDefaultData(defaultDataObject);
          setMediaState(instanceId, mediaType, mediaModel);
        } else if (resetDefaultData) {
          resetBlockDefaultDataMedia(true);
          deleteDefaultData(defaultDataObject);
          setMediaState(instanceId, mediaType, mediaListChanged);
        } else {
          setMediaState(instanceId, mediaType, mediaListChanged);
        }
        console.log('test');
      }
    });
  }

  const addImage = (product, callback) => {
    let addSelectedLink = product.actions[0].url;
    setIsLoadingAddMedia(true);
    if (blockDefault) {
      addSelectedLink += '&blockDefault=' + blockDefault;
    }
    ajaxGet(addSelectedLink).then((ajaxData) => {
      setIsLoadingAddMedia(false);
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
      const item = product.itemPicture;
      const deleteItemURL =
        'dataManagement/changeLinkedInstancePosistion?' +
        searchUrl +
        '&linkedInstanceId=' +
        product.id +
        '&delete=' +
        true +
        '&dragged=' +
        0 +
        '&dropped=' +
        0 +
        '&shift=' +
        0;
      item.id = product.id;
      item.changePositionUri = deleteItemURL;
      var listItem = [item];
      const mediaListItem = mediaList.concat(listItem);
      callback(product);
      resetBlockDefaultDataMedia(true);
      deleteDefaultData(defaultDataObject);
      setMediaState(instanceId, mediaType, mediaListItem);
    });
  };

  function setBlockDefaultDataMedia() {
    let contentIdentifier = defaultDataObject.id;
    let type = defaultDataObject.type;
    let parentId = instanceEditor.instanceId;
    let parentObjectClass = instanceEditor.instanceType;

    ajaxPost('dataManagement/postSetBlockDefaultData', {
      contentIdentifier: contentIdentifier,
      type: type,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
    });
    setBlockDefaultData(defaultDataObject);
    deleteDefaultData(defaultDataObject);
    setMediaState(instanceId, mediaType, []);
  }

  function resetBlockDefaultDataMedia(withOutReset) {
    let contentIdentifier = defaultDataObject.id;
    let type = defaultDataObject.type;
    let parentId = instanceEditor.instanceId;
    let parentObjectClass = instanceEditor.instanceType;

    ajaxPost('dataManagement/postResetBlockDefaultData', {
      contentIdentifier: contentIdentifier,
      type: type,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
    });
    deleteBlockDefaultData(defaultDataObject);
    if (!withOutReset) {
      setDefaultData(defaultDataObject);
      resetDefaultMedia();
    }
  }

  function resetDefaultMedia() {
    let contentIdentifier = defaultDataObject.id;
    let parentId = instanceEditor.instanceId;
    let parentObjectClass = instanceEditor.instanceType;

    ajaxGet('dataManagement/getDefaultMedia', {
      contentIdentifier: contentIdentifier,
      parentId: parentId,
      parentObjectClass: parentObjectClass
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let mediaModel = ajaxData.response.mediumModel;

        if (mediaModel && mediaModel.length) {
          setMediaState(instanceId, mediaType, mediaModel);
        }
      }
    });
  }

  if (!mediaEditorData) {
    return null;
  }

  var title = '';
  if (mediaEditorData.contentLangTitle) {
    if (mediaEditorData.contentLangTitle.missingContentLang) {
      title =
        '<' +
        mediaEditorData.contentLangTitle.typeName +
        ': ' +
        mediaEditorData.contentLangTitle.text +
        '>';
    } else {
      title = mediaEditorData.contentLangTitle.text;
    }
  }

  if (!mediaList) return null;
  return (
    <div
      className={`mediaEditor instanceEditorComponent ${
        isDefaultDataPTD ? classes.defaultData : null
      }`}
      style={{ maxWidth: 'calc(100vw - 58rem)' }}
    >
      <MediaEditorMain
        onDelete={onDelete}
        addImage={addImage}
        mediaList={mediaList}
        setMediaState={setMediaState}
        instanceId={instanceId}
        mediaType={mediaType}
        titleMediaEditor={title}
        searchUrl={searchUrl}
        updateMediaList={updateMediaList}
        isDefaultData={isDefaultDataPTD}
        deleteDefaultData={deleteDefaultData}
        defaultDataObject={defaultDataObject}
        setBlockDefaultData={setBlockDefaultDataMedia}
        resetBlockDefaultData={resetBlockDefaultDataMedia}
        isBlockDefaultData={blockDefault}
      />
    </div>
  );
};
