import React, { useEffect, useState } from 'react';
import ajaxGet from '../../services/ajaxGet.js';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField } from '@material-ui/core';
import { DATA_FIELD_TYPES, useExport } from './ExportContext';
import { useBMEcatStyles } from './BMEcatExportOverview.jsx';
import ListSubheader from '@material-ui/core/ListSubheader';

export const BMEcatConfiguration = () => {
  let contentLang = $('#contentLangSelectElement').val() || null;

  const {
    getSelectedSpec,
    setSelectedSpec,
    getSelectedVariant,
    setSelectedVariant,
    getClassification,
    setClassification,
    getNullFeatureValueDefinitionInput,
    setNullFeatureValueDefinitionInput,
    setHeaderFields,
    setHierarchyInfos,
    setCustomFields,
    setCustomFieldState,
    setCustomFieldValues,
    setHeaderFieldValues,
    setHierarchyInfoValues,
    setHeaderFieldState,
    setHierarchyInfoState,
    setMandatoryFieldIds,
    setMandatoryHeaderFieldIds,
    getSpecificationList,
    setSpecificationList,
    getSpecVariantList,
    setSpecVariantList,
    clearAfterChange,
    setLoadingBmeCat,
    getCustomSpecList,
    setCustomSpecList,
    setCustomSpecDbId,
    settingLoadedDataFields
  } = useExport();

  const classes = useBMEcatStyles();

  const [nullFeatureDef, setNullFeatureDef] = useState('');
  const [classificationMap, setClassificationMap] = useState({});

  let selectedSpec = getSelectedSpec();
  let selectedVariant = getSelectedVariant();
  let classification = getClassification();
  if (classification == null) {
    classification = 'all';
  }
  let specVariantsList = getSpecVariantList();
  let specificationList = getSpecificationList();
  let customSpecList = getCustomSpecList();

  useEffect(() => {
    ajaxGet('getExportSpecifications', { contentLanguage: contentLang }).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          let spezificationList = ajaxData.response.exportSpecifications;
          let classificationMap = ajaxData.response.classificationMap;
          let customSpecList = ajaxData.response.exportCustomSpecList;
          setSpecificationList(spezificationList);
          setClassificationMap(classificationMap);
          setCustomSpecList(customSpecList);
          let nullFeatureInput = getNullFeatureValueDefinitionInput();
          if (nullFeatureInput) {
            setNullFeatureDef(nullFeatureInput);
          }
        }
        setLoadingBmeCat(false);
      }
    );
  }, []);

  function handleChangeSpecification(event) {
    let exportSpecificationId = event.target.value;
    let $target = $(event.currentTarget);
    let custom = $target.data('custom');
    let contentLang = $('#contentLangSelectElement').val() || null;
    setLoadingBmeCat(true);
    clearAfterChange();
    setNullFeatureDef('');
    setSpecVariantList([]);
    setSelectedVariant('');
    ajaxGet('getExportSpecificationVariants', {
      exportSpecificationId: exportSpecificationId,
      contentLanguage: contentLang
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let model = ajaxData.response.exportSpecifications;
        if (!model[0]) {
          model = null;

          if (custom) {
            handleChangeVariant(model, null, exportSpecificationId);
          } else {
            handleChangeVariant(model, exportSpecificationId);
          }
        }
        setSpecVariantList(model);
      }
      setLoadingBmeCat(false);
    });

    setSelectedSpec(exportSpecificationId);
    if (custom) {
      setCustomSpecDbId(exportSpecificationId);
    } else {
      setCustomSpecDbId('');
    }
  }

  function handleChangeVariant(value, exportSpecId, customSpecDbId) {
    let exportSpecificationId;
    if (exportSpecId) {
      exportSpecificationId = exportSpecId;
    } else {
      exportSpecificationId = getSelectedSpec();
    }

    let exportSpecificationVariantId = value;
    let customSpecificationId = null;

    if (customSpecDbId) {
      customSpecificationId = customSpecDbId;
      exportSpecificationId = null;
      exportSpecificationVariantId = null;
    }

    setLoadingBmeCat(true);
    clearAfterChange();
    ajaxGet('getHeaderAndFields', {
      exportSpecificationId: exportSpecificationId,
      exportSpecificationVariantId: exportSpecificationVariantId,
      customSpecDbId: customSpecificationId
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let headerFields = ajaxData.response.headerFields;
        let hierarchyInfos = ajaxData.response.hierarchyInfos;
        let customFields = ajaxData.response.customFields;
        let missingFeaturesFeatureValue =
          ajaxData.response.missingFeaturesFeatureValue;
        setHeaderFields(headerFields);
        setHierarchyInfos(hierarchyInfos);
        setCustomFields(customFields);
        if (missingFeaturesFeatureValue) {
          setNullFeatureDef(missingFeaturesFeatureValue);
          setNullFeatureValueDefinitionInput(missingFeaturesFeatureValue);
        }

        settingLoadedDataFields(headerFields, null, DATA_FIELD_TYPES.header);
        settingLoadedDataFields(
          hierarchyInfos,
          null,
          DATA_FIELD_TYPES.hierarchyInfo
        );
        settingLoadedDataFields(
          customFields,
          null,
          DATA_FIELD_TYPES.customField
        );

        let mandatoryFields = [];
        let mandatoryHeaderFields = [];
        customFields.forEach((content) => {
          let isEmpty = true;

          for (let keys in content.optionValues) {
            isEmpty = false;
            break; // exiting since we found that the object is not empty
          }

          if (content.optionValues && !isEmpty) {
            let optionModels = content.optionValues;
            let mandatory = content.mandatory;
            let hashCode = content.keyHashCode;
            if (mandatory) {
              let mandatoryField = {};
              mandatoryField.id = hashCode;
              mandatoryField.groupId = content.groupId;
              mandatoryFields.push(mandatoryField);
            }
            let selection = [];
            let isEmpty = true;

            for (let keys in optionModels) {
              isEmpty = false;
              break; // exiting since we found that the object is not empty
            }
            if (isEmpty) return;

            for (let optionKey in optionModels) {
              let optionValue = optionModels[optionKey];
              let selected = false;
              if (optionKey === content.defaultValueAlphanumeric) {
                selected = true;

                let singleSelection = {};
                let multiSelection = [];
                singleSelection.selectionValue = optionKey;
                singleSelection.label = optionValue;
                singleSelection.addToValueList = false;
                singleSelection.addedSelectionOption = false;
                if (
                  content.featureType ===
                  'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION'
                ) {
                  multiSelection.push(singleSelection);
                  setCustomFieldValues(hashCode, {
                    multiSelection: multiSelection
                  });
                } else {
                  setCustomFieldValues(hashCode, {
                    singleSelection: singleSelection
                  });
                }
              }
              let selectionItem = {
                selectionValue: optionKey,
                addToValueList: false,
                addedSelectionOption: false,
                label: optionValue,
                selected: selected
              };
              selection.push(selectionItem);
            }

            let value = {};

            value.selected = selection
              .filter((option) => option.selected)
              // eslint-disable-next-line no-unused-vars
              .map(({ selected, ...optionProps }) => optionProps);

            selection = selection.map(
              // eslint-disable-next-line no-unused-vars
              ({ selected, ...optionProps }) => optionProps
            );

            value.customOptions = selection.filter(
              (option) => option.addedSelectionOption
            );

            const standardOptions = selection.filter(
              (option) => !option.addedSelectionOption
            );

            value.standardOptions = standardOptions;
            setCustomFieldState(hashCode, value);
          } else {
            let mandatory = content.mandatory;
            let hashCode = content.keyHashCode;
            if (mandatory) {
              let mandatoryField = {};
              mandatoryField.id = hashCode;
              mandatoryField.groupId = content.groupId;
              mandatoryFields.push(mandatoryField);
            }
            if (content.defaultValueAlphanumeric) {
              setCustomFieldState(hashCode, content.defaultValueAlphanumeric);
              setCustomFieldValues(hashCode, {
                alphaNumericValue: content.defaultValueAlphanumeric
              });
            }
            if (content.defaultValueNumeric) {
              setCustomFieldState(hashCode, content.defaultValueNumeric);
              setCustomFieldValues(hashCode, {
                numericValue: content.defaultValueNumeric
              });
            }
          }
        });
        setMandatoryFieldIds(mandatoryFields);
        headerFields.forEach((content) => {
          let isEmpty = true;

          for (let keys in content.optionValues) {
            isEmpty = false;
            break; // exiting since we found that the object is not empty
          }

          if (content.optionValues && !isEmpty) {
            let optionModels = content.optionValues;

            let selection = [];
            let hashCode = content.keyHashCode;
            let mandatory = content.mandatory;
            if (mandatory) {
              let mandatoryHead = {};
              mandatoryHead.id = hashCode;
              mandatoryHead.groupId = content.groupId;
              mandatoryHeaderFields.push(mandatoryHead);
            }

            for (let optionKey in optionModels) {
              let optionValue = optionModels[optionKey];
              let selected = false;
              if (optionKey === content.defaultValueAlphanumeric) {
                selected = true;

                let singleSelection = {};
                let multiSelection = [];
                singleSelection.selectionValue = optionKey;
                singleSelection.label = optionValue;
                singleSelection.addToValueList = false;
                singleSelection.addedSelectionOption = false;

                if (
                  content.featureType ===
                  'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION'
                ) {
                  multiSelection.push(singleSelection);
                  setHeaderFieldValues(hashCode, {
                    multiSelection: multiSelection
                  });
                } else {
                  setHeaderFieldValues(hashCode, {
                    singleSelection: singleSelection
                  });
                }
              }
              let selectionItem = {
                selectionValue: optionKey,
                addToValueList: false,
                addedSelectionOption: false,
                label: optionValue,
                selected: selected
              };
              selection.push(selectionItem);
            }

            let value = {};

            value.selected = selection
              .filter((option) => option.selected)
              // eslint-disable-next-line no-unused-vars
              .map(({ selected, ...optionProps }) => optionProps);

            selection = selection.map(
              // eslint-disable-next-line no-unused-vars
              ({ selected, ...optionProps }) => optionProps
            );

            value.customOptions = selection.filter(
              (option) => option.addedSelectionOption
            );

            const standardOptions = selection.filter(
              (option) => !option.addedSelectionOption
            );

            value.standardOptions = standardOptions;
            setHeaderFieldState(hashCode, value);
          } else {
            let mandatory = content.mandatory;
            let hashCode = content.keyHashCode;
            if (mandatory) {
              let mandatoryHead = {};
              mandatoryHead.id = hashCode;
              mandatoryHead.groupId = content.groupId;
              mandatoryHeaderFields.push(mandatoryHead);
            }
            if (content.defaultValueAlphanumeric) {
              setHeaderFieldState(hashCode, content.defaultValueAlphanumeric);
              setHeaderFieldValues(hashCode, {
                alphaNumericValue: content.defaultValueAlphanumeric
              });
            }
            if (content.defaultValueNumeric) {
              setHeaderFieldState(hashCode, content.defaultValueNumeric);
              setHeaderFieldValues(hashCode, {
                numericValue: content.defaultValueNumeric
              });
            }
          }
          setMandatoryHeaderFieldIds(mandatoryHeaderFields);
        });
      }
      setLoadingBmeCat(false);
    });

    setSelectedVariant(exportSpecificationVariantId);
  }

  function saveInputValue(value) {
    setNullFeatureValueDefinitionInput(value);
  }

  function handleChangeClassification(event) {
    setClassification(event.target.value);
  }

  return (
    <div>
      <div style={{ margin: '2rem' }}>
        <InputLabel id="specListLabel">Export-Spezifikation</InputLabel>
        {Boolean(specificationList?.length) && (
          <Select
            labelId="specListLabel"
            id="specListSelect"
            value={selectedSpec}
            onChange={handleChangeSpecification}
            label="Export-Spezifikation"
            className={classes.bmeCatSelect}
          >
            <ListSubheader>Standard Spezifikationen</ListSubheader>
            {specificationList &&
              specificationList.map((content) => (
                <MenuItem key={content} value={content}>
                  {content}
                </MenuItem>
              ))}

            <ListSubheader>Individuelle Spezifikationen</ListSubheader>
            {customSpecList &&
              customSpecList.map((content) => (
                <MenuItem
                  key={content.value}
                  value={content.value}
                  data-custom={true}
                >
                  {content.label}
                </MenuItem>
              ))}
          </Select>
        )}
      </div>
      {Boolean(specVariantsList?.length) && (
        <div style={{ margin: '2rem' }}>
          <InputLabel id="variantLabel">Variante</InputLabel>
          <Select
            labelId="variantLabel"
            id="variantLabel"
            value={selectedVariant}
            onChange={(event) => handleChangeVariant(event.target.value)}
            label="Variante"
            className={classes.bmeCatSelect}
          >
            {specVariantsList &&
              specVariantsList.map((content) => (
                <MenuItem key={content} value={content}>
                  {content}
                </MenuItem>
              ))}
          </Select>
        </div>
      )}
      <div style={{ margin: '2rem' }}>
        <TextField
          label="Leere Merkmalwerte exportieren (Zeichen eingeben):"
          InputLabelProps={{
            disableAnimation: true,
            shrink: false,
            className: classes.title
          }}
          value={nullFeatureDef}
          onChange={(event) => {
            setNullFeatureDef(event.target.value);
          }}
          onBlur={(event) => saveInputValue(event.target.value)}
          className={classes.bmeCatInput}
        />
      </div>
      {Boolean(
        classificationMap && Object.entries(classificationMap).length
      ) && (
        <div style={{ margin: '2rem' }}>
          <InputLabel id="classificationListLabel">Klassifizierung</InputLabel>
          <Select
            labelId="classificationListLabelListLabel"
            id="classificationListLabelListSelect"
            value={classification}
            onChange={handleChangeClassification}
            label="Klassifizierung"
            className={classes.bmeCatSelect}
          >
            <MenuItem value="all">alle</MenuItem>
            {classificationMap &&
              Object.entries(classificationMap).map(([key, value]) => (
                <MenuItem key={key + value} value={key}>
                  {value}
                </MenuItem>
              ))}
          </Select>
        </div>
      )}
    </div>
  );
};
