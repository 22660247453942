import React, { useEffect } from 'react';
import ajaxGet from '../../services/ajaxGet.js';
import ajaxPost from '../../services/ajaxPost.js';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from '../PimTogoTheme';
import { makeStyles } from '@material-ui/core/styles';
import { TransferList } from './TransferList.jsx';

import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';

import { useImmer } from 'use-immer';

import PimSpinner from '../components/common/PimSpinner.jsx';

import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n.js';
import { Typography } from '@material-ui/core';

import Icon from '@material-ui/core/Icon';

import Checkbox from '@material-ui/core/Checkbox';
import {FormControlLabel} from '@material-ui/core';
import { BuildTwoTone } from '@material-ui/icons';
const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  errorText: {
    color: 'red',
    fontSize: '10px'
  },
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}));

export default function DataModelOverview() {
  const [specificationList, setSpecificationList] = useImmer([]);
  const [selectedSpecification, setSelectedSpecification] = useImmer(null);
  const [specificationModel, setSpecificationModel] = useImmer(null);
  const [loading, setLoading] = useImmer(false);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [manageOrgaGlobal, setManageOrgaGlobal] = useImmer(false);
  const { t } = useTranslation();

  const [saveList, setSaveList] = useImmer({});

  useEffect(() => {
    ajaxGet('getBmeCatSpecifications').then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let specList = ajaxData.response.specificationList;
        let manageOrgaGlobal = ajaxData.response.manageOrgaGlobal;
        setSpecificationList(specList);
        setManageOrgaGlobal(manageOrgaGlobal);
      }
    });
  }, []);

  const handleChange = (event) => {
    let specificationId = event.target.value;
    let contentLang = $('#contentLangSelectElement').val() || null;
    setSelectedSpecification(specificationId);
    ajaxGet('getBmeCatSpecification', {
      specificationId: specificationId,
      contentLanguage: contentLang
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setSpecificationModel(null);
        let model = ajaxData.response.specificationModel;
        setSpecificationModel(model);
        setSaveList({});
        let initSave = {};
        model.specification.specificationContent.forEach((element) => {
          model.leftLists[element.groupId].forEach((element) => {
            initSave[element.id] = element;
          });
        });
        setSaveList(initSave);
      }
    });
  };

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function saveAll() {
    setLoading(true);
    let send = [];
    let json = {};
    json.specificationId = selectedSpecification;
    for (let saveKey in saveList) {
      let listItem = saveList[saveKey];
      let finalListItem = {
        id: listItem.id,
        objectClass: listItem.objectClass
      };
      send.push(finalListItem);
    }
    json.listItems = send;
    console.log(json);
    ajaxPost('postSaveDataModel', null, json).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      setLoading(false);
      if (ajaxData.error) return;
    });
  }

  const addMandatoryFields = () => {
    let tempSpecificationModel = JSON.parse(JSON.stringify(specificationModel));
    let saveListTemp = JSON.parse(JSON.stringify(saveList));
    Object.assign([], tempSpecificationModel);
    Object.entries(tempSpecificationModel.rightLists).forEach((values, keys) => {
      if (values.length > 0) {
        var groupId = values[0];
        values[1].forEach(element => {
          if (element.mandatory && checkleftLists(tempSpecificationModel.leftLists[groupId], element.id)) {
            var tab = tempSpecificationModel.leftLists[groupId]
            var res_ = tempSpecificationModel.rightLists[groupId].filter((item) => item.id != element.id);
            tempSpecificationModel.rightLists[groupId] = res_;
            if (tab != undefined && tab != null) {
              tab = Object.assign([], tab); tab.push(element); tempSpecificationModel.leftLists[groupId] = tab;
              saveListTemp[element.id] = element;
              
            }
          }
        });
      }
    });
    setSaveList(saveListTemp)
    setSpecificationModel(tempSpecificationModel);
    // TOGO.Util.notify(t('bmeCatDataModel.mandatoryFieldsAdded'),false);
    TOGO.Util.notifyResponse(t('bmeCatDataModel.mandatoryFieldsAdded'), false);

  };


  const checkleftLists = (arrayList, feldID) => {
    if(arrayList == null || arrayList == undefined ) return false;
    if ( arrayList.length === 0) return true
      arrayList.forEach(element => {
        if(element.id == feldID) return false;
      });
      return true
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        {manageOrgaGlobal ? (
          <AutomaticUpdate setLoading={setLoading} t={t}></AutomaticUpdate>
        ) : (
          ''
        )}
        <div className={classes.center}>
          <FormControl
            style={{
              marginBottom: '4rem'
            }}
            className={classes.center}
          >
            <InputLabel id="specifictionSelectLabel">
              {t('bmeCatDataModel.specification')}
            </InputLabel>
            <Select
              labelId="specifictionSelectLabel"
              id="specificationSelect"
              value={!selectedSpecification ? '' : selectedSpecification}
              onChange={handleChange}
              style={{ width: '25rem' }}
            >
              {specificationList.map((SpecificationName) => (
                <MenuItem key={SpecificationName} value={SpecificationName}>
                  {SpecificationName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {specificationModel && (
          <div className={classes.center}>
              <button className="btn btn-success" onClick={addMandatoryFields}>
              {t('bmeCatDataModel.addMandatoryFields')}
            </button>
          </div>
        )}
        {specificationModel &&
          specificationModel.specification.specificationContent.map(
            (content) => (
              <MuiAccordion
                style={{
                  margin: '5px 0',
                  backgroundImage: 'linear-gradient(to right, #f29528, #fbdba7)'
                }}
                key={`${content.groupId}-${selectedSpecification}`}
                expanded={
                  expanded === `${content.groupId}-${selectedSpecification}`
                }
                onChange={handleExpand(
                  `${content.groupId}-${selectedSpecification}`
                )}
              >
                <MuiAccordionSummary
                  expandIcon={
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  }
                  aria-controls={`${content.groupId}-content`}
                  id={`${content.groupId}-header`}
                >
                  {content.groupId}
                </MuiAccordionSummary>
                <MuiAccordionDetails
                  style={{
                    background: 'white',
                    display: 'flow-root',
                    padding: '20px'
                  }}
                >               
                  <TransferList
                    groupId={content.groupId}
                    specificationModel={specificationModel}
                    setSpecificationModel={setSpecificationModel}
                    titleRight={selectedSpecification}
                    saveList={saveList}
                    setSaveList={setSaveList}
                  ></TransferList>
                </MuiAccordionDetails>
              </MuiAccordion>
            )
          )}
        {specificationModel && (
          <div className={classes.center} style={{ marginTop: '4rem' }}>
            <button className="btn btn-success" onClick={saveAll}>
              {t('bmeCatDataModel.saveDataModel')}
            </button>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

const AutomaticUpdate = ({ setLoading, t }) => {
  const [errorList, setErrorList] = React.useState([]);
  const [textError, setTextError] = React.useState([]);
  const [mediaError, setMediaError] = React.useState([]);
  const [fieldError, setFieldError] = React.useState([]);

  const classes = useStyles();
  const listItems = errorList.map((item) => item + ', ');
  const listText = textError.map((item) => item + ', ');
  const listMedia = mediaError.map((item) => item + ', ');
  const listField = fieldError.map((item) => item + ', ');

  function automaticUpdate() {
    setLoading(true);
    let contentLang = $('#contentLangSelectElement').val() || null;
    ajaxPost('postUpdateCustomDefinitions', {
      contentLanguage: contentLang
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      setLoading(false);
      if (ajaxData.error) return;
      let errorList = ajaxData.response.errorList;
      let textError = ajaxData.response.textError;
      let mediaError = ajaxData.response.mediaError;
      let fieldError = ajaxData.response.fieldError;
      setErrorList(errorList);
      setTextError(textError);
      setMediaError(mediaError);
      setFieldError(fieldError);
    });
  }

  function handleClose() {
    setErrorList([]);
  }

  function handleCloseObjectClass() {
    setFieldError([]);
  }

  function handleCloseMedia() {
    setMediaError([]);
  }

  function handleCloseText() {
    setTextError([]);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.center}>
        <button className="btn btn-success" onClick={automaticUpdate}>
          {t('bmeCatDataModel.automaticUpdate')}
        </button>
      </div>
      {errorList.length ? (
        <div
          style={{
            backgroundColor: 'red',
            borderRadius: '5px',
            position: 'relative',
            textAlign: 'center'
          }}
        >
          <Typography style={{ color: 'white', padding: '1rem' }}>
            {t('bmeCatDataModel.fieldError1')}
            {listItems}
            {t('bmeCatDataModel.fieldError2')}
          </Typography>
          <Icon
            className="fa fa-times-circle"
            style={{ position: 'absolute', right: '2px', top: '2px' }}
            aria-hidden="true"
            fontSize="small"
            onClick={handleClose}
          />
        </div>
      ) : (
        ''
      )}
      {textError.length ? (
        <div
          style={{
            backgroundColor: 'red',
            borderRadius: '5px',
            position: 'relative',
            textAlign: 'center'
          }}
        >
          <Typography style={{ color: 'white', padding: '1rem' }}>
            {t('bmeCatDataModel.textError1')}
            {listText}
            {t('bmeCatDataModel.errorObjectClass')}
          </Typography>
          <Icon
            className="fa fa-times-circle"
            style={{ position: 'absolute', right: '2px', top: '2px' }}
            aria-hidden="true"
            fontSize="small"
            onClick={handleCloseText}
          />
        </div>
      ) : (
        ''
      )}
      {mediaError.length ? (
        <div
          style={{
            backgroundColor: 'red',
            borderRadius: '5px',
            position: 'relative',
            textAlign: 'center'
          }}
        >
          <Typography style={{ color: 'white', padding: '1rem' }}>
            {t('bmeCatDataModel.mediaError1')}
            {listMedia}
            {t('bmeCatDataModel.errorObjectClass')}
          </Typography>
          <Icon
            className="fa fa-times-circle"
            style={{ position: 'absolute', right: '2px', top: '2px' }}
            aria-hidden="true"
            fontSize="small"
            onClick={handleCloseMedia}
          />
        </div>
      ) : (
        ''
      )}
      {fieldError.length ? (
        <div
          style={{
            backgroundColor: 'red',
            borderRadius: '5px',
            position: 'relative',
            textAlign: 'center'
          }}
        >
          <Typography style={{ color: 'white', padding: '1rem' }}>
            {t('bmeCatDataModel.fieldError1')}
            {listField}
            {t('bmeCatDataModel.errorObjectClass')}
          </Typography>
          <Icon
            className="fa fa-times-circle"
            style={{ position: 'absolute', right: '2px', top: '2px' }}
            aria-hidden="true"
            fontSize="small"
            onClick={handleCloseObjectClass}
          />
        </div>
      ) : (
        ''
      )}
    </ThemeProvider>
  );
};
