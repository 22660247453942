import React, { useState } from 'react';

import { ExportProvider } from './ExportContext';
import { ThemeProvider } from '@material-ui/styles';
import { DataManagementProvider } from '../articledata/datamanagement/DataManagementContext';
import { ExportTabs } from './ExportTabs';
import theme from '../PimTogoTheme';

export default function ExportOverview() {
  return (
    <ThemeProvider theme={theme}>
      <ExportProvider>
        <DataManagementProvider>
          <ExportTabs></ExportTabs>
        </DataManagementProvider>
      </ExportProvider>
    </ThemeProvider>
  );
}
