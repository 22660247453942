import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { FilePicker } from 'react-file-picker';

import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from '../../services/ajaxPost';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

export default function BMEcatExportSpecs() {
  const classes = useStyles();
  const [customSpecs, setCustomSpecs] = useState([]);

  useEffect(() => {
    ajaxGet('getBmeCatCustomSpecs').then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setCustomSpecs(ajaxData.response.exportCustomSpecList);
      }
    });
  }, []);

  const handleUpload = (file) => {
    var fileFormData = new FormData();
    fileFormData.append('file', file);

    ajaxPost('uploadBmeCatCustomSpec', {}, null, fileFormData).then(
      (ajaxData) => {
        let msg = ajaxData.response?.message || ajaxData.error;
        TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      }
    );
  };

  return (
    <>
      <FilePicker
        extensions={['json']}
        onChange={(FileObject) => handleUpload(FileObject)}
      >
        <button>
          <i className="fa fa-table" aria-hidden="true"></i>
          <span style={{ margin: '0 1rem' }}>Datei auswählen...</span>
        </button>
      </FilePicker>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="BMEcatExportSpecs table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Custom Spec Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customSpecs &&
              customSpecs.map((row) => (
                <TableRow key={row.value}>
                  <TableCell component="th" scope="row">
                    {/* <EditButton exportId={row.id}></EditButton>
                  <DeleteButton
                    exportId={row.id}
                    handleDeleteExport={handleDelete}
                  ></DeleteButton> */}
                  </TableCell>
                  <TableCell>{row.label}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
