export const ptdDefaultDataForProductEditor = {
  ROOT: {
    type: { resolvedName: 'Container' },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 5,
      containerFlexColumns: '1',
      dataSource: {
        source: 'ProductTypeDefinition',
        singleData: true,
        childOverride: false
      }
    },
    displayName: 'Container',
    custom: {},
    parent: null,
    hidden: false,
    nodes: [
      'Z-3xjgrHn',
      'rHzjIzIRZ',
      'EGcfVHvhs',
      'H_XpVBt7-',
      'OfJy_iIu5',
      'VxEdUV8-S'
    ],
    linkedNodes: {}
  },
  'Z-3xjgrHn': {
    type: { resolvedName: 'Title' },
    isCanvas: false,
    props: {
      text: {
        de: 'Texte',
        en: 'Texts',
        it: 'Testi',
        fr: 'Textes'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'XosjL0dBqg',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  rHzjIzIRZ: {
    type: { resolvedName: 'Container' },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'ProductTypeDefinition',
        singleData: true,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'XosjL0dBqg',
    hidden: false,
    nodes: ['CTdAh5c31'],
    linkedNodes: {}
  },
  CTdAh5c31: {
    type: { resolvedName: 'TextEditorSelector' },
    isCanvas: false,
    props: {
      textType: '',
      variant: 'formatted',
      bulkDataType: 'texts'
    },
    displayName: 'TextEditorSelector',
    custom: {},
    parent: 'rHzjIzIRZ',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  EGcfVHvhs: {
    type: { resolvedName: 'Title' },
    isCanvas: false,
    props: {
      text: {
        de: 'Medien',
        en: 'Media',
        it: 'Media',
        fr: 'Médias'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'XosjL0dBqg',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'H_XpVBt7-': {
    type: { resolvedName: 'MediaEditorSelector' },
    isCanvas: false,
    props: {
      mediaType: '',
      label: 'Medientyp auswählen...',
      bulkDataType: 'media'
    },
    displayName: 'MediaEditorSelector',
    custom: {},
    parent: 'XosjL0dBqg',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  OfJy_iIu5: {
    type: { resolvedName: 'Title' },
    isCanvas: false,
    props: {
      text: {
        de: 'Felder',
        en: 'Headline',
        it: 'Campi',
        fr: 'Champs'
      },
      fontSize: 20
    },
    displayName: 'Title',
    custom: {},
    parent: 'XosjL0dBqg',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  },
  'VxEdUV8-S': {
    type: { resolvedName: 'Container' },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 20,
      containerFlexColumns: '2',
      dataSource: {
        source: 'ProductTypeDefinition',
        singleData: true,
        childOverride: false
      },
      isTableCell: false
    },
    displayName: 'Container',
    custom: {},
    parent: 'XosjL0dBqg',
    hidden: false,
    nodes: ['LmvGFZTmx'],
    linkedNodes: {}
  },
  LmvGFZTmx: {
    type: { resolvedName: 'FieldEditorSelector' },
    isCanvas: false,
    props: {
      customFieldType: '',
      generalFieldType: '',
      label: 'Feldtyp auswählen...',
      bulkDataType: 'custom_fields'
    },
    displayName: 'FieldEditorSelector',
    custom: {},
    parent: 'VxEdUV8-S',
    hidden: false,
    nodes: [],
    linkedNodes: {}
  }
};
