import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Dialog,
  DialogTitle
} from '@material-ui/core';


import  { usePimStyles } from '../PimTogoTheme';
import SFTPConnectionConfigurationForm from '../sftpUser/SFTPConnectionConfigurationForm';

export const CreateSFTPConnectionConfiguration = ({
  openDialogSFTPConnectionConfiguration,
  handleCloseDialogSFTPConnectionConfiguration,
  initFormSFTPConnectionConfigurationState,
  toCreateSFTPConnectionConfiguration,
  sftpConnectionConfigurationList,
  searchsftpConnectionConfigurations
}) => {
  const { t } = useTranslation();
  const classesPim = usePimStyles();

  useEffect(() => {}, []);

  return (
    <Dialog
      aria-labelledby="create-instance-title"
      open={openDialogSFTPConnectionConfiguration}
      onClose={handleCloseDialogSFTPConnectionConfiguration}
      className={classesPim.dialog}
      transitionDuration={0}
    >
      <DialogTitle
        id="create-instance-title"
        className={classesPim.dialogTitle}
      >        {toCreateSFTPConnectionConfiguration ? t('managUsersSFTP.createTitle') : t('managUsersSFTP.editTitle') }

      </DialogTitle>
      <SFTPConnectionConfigurationForm
        handleCloseDialogSFTPConnectionConfiguration={handleCloseDialogSFTPConnectionConfiguration}
        sftpConnectionConfigurationList={sftpConnectionConfigurationList}
        initFormSFTPConnectionConfigurationState={initFormSFTPConnectionConfigurationState}
        toCreateSFTPConnectionConfiguration={toCreateSFTPConnectionConfiguration}
        searchsftpConnectionConfigurations={searchsftpConnectionConfigurations}
      />
    </Dialog>
  );
};

export default CreateSFTPConnectionConfiguration;
