import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const selectedStyle = {
  boxShadow: [
    'inset 0 1px 1px rgb(0 0 0 / 8%)',
    '0 0 8px rgb(102 175 233 / 60%)'
  ],
  outline: '2px #85b4ff dashed'
};

export const useStylesSelected = makeStyles(() => ({
  selected: selectedStyle
}));

const SelectedWrap = ({ isSelected, addClassName, children }) => {
  const classes = useStylesSelected();
  return (
    <div
      className={`${isSelected ? classes.selected : ''} ${
        addClassName ? addClassName : ''
      }`}
    >
      {children}
    </div>
  );
};
export default SelectedWrap;
