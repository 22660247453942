import React from "react";
import ProductCard from "./ProductCard";

const ProductList = ({products , addImage, changeStatusItem}) => {

  
  return (
    <div className="product-list">
      {products.filter(product=> !product.error).map(product => (
        <ProductCard changeStatusItem={changeStatusItem} product={product} key={product.id} addImage={addImage}/>
      ))}
    </div>
  );
}
 
export default ProductList;