import React, { useState, useEffect } from 'react';
import { useImmer } from 'use-immer';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  Popover,
  List,
  ListItem,
  ListItemText,
  Icon,
  makeStyles,
  Button as MuiButton,
  Radio,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

import EllipsisToolTip from 'ellipsis-tooltip-react-chan';

import { emptyEditor } from './Topbar';
import { standardEditor } from './standardEditor';

import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';

const URL_TAB_EDITOR = 'tabEditor/';

const useStyles = makeStyles((theme) => ({
  versionNameTextField: {
    position: 'relative',
    marginRight: '1rem'
  },
  menuWrap: {
    border: 'grey 1px solid',
    borderRadius: 3,
    padding: 0
  },
  menuText: {
    paddingTop: '2px',
    paddingBottom: '2px'
  },
  menuOpenButton: {
    backgroundColor: theme.palette.background.paper,
    margin: '0 2rem',
    width: '15rem'
  },
  menuCaret: {
    position: 'relative',
    left: '1.5rem',
    color: 'dimgrey'
  },
  menuTableContainer: {
    maxHeight: 440
  },
  standardEditorRow: {
    backgroundColor: 'pink'
  }
}));

export function getInstanceTypePayload(instanceType) {
  let objectClass = null;
  let subType = null;
  if (instanceType === 'Product') {
    objectClass = instanceType;
  } else {
    subType = instanceType;
  }

  return [objectClass, subType];
}

export default function VersionsManager({ editorQuery, editorActions }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [versionName, setVersionName] = useState('');
  const [versionsMenuAnchor, setVersionsMenuAnchor] = useState(null);
  const [versions, setVersions] = useImmer([]);
  const [selectedId, setSelectedId] = useState();
  const [appliedId, setAppliedId] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const instanceType = editorQuery.getOptions().rootDataSource.source;
    const [objectClass, customInstanceType] =
      getInstanceTypePayload(instanceType);

    const url = URL_TAB_EDITOR + 'getEditorConfigs';
    ajaxGet(url, {
      containingObjectClass: objectClass,
      customInstance: customInstanceType
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let loadedVersions = ajaxData.response.editorConfigDataResultList;
        loadedVersions.sort((a, b) => b.modified - a.modified);
        setVersions(loadedVersions);
        let appliedVersion = loadedVersions.find((version) => version.applied);
        if (appliedVersion) setAppliedId(appliedVersion.id);
      }
    });
  }, []);

  function createVersion(payload) {
    payload.configurationJson = editorQuery.serialize();

    const url = URL_TAB_EDITOR + 'createEditorConfig';
    ajaxPost(url, null, payload).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setVersions((draft) => {
          draft.unshift({
            id: ajaxData.response.id,
            name: payload.name,
            configurationJson: payload.configurationJson,
            containingObjectClass: payload.containingObjectClass,
            customInstance: payload.customInstance
          });

          setSelectedId(ajaxData.response.id);
        });
        TOGO.Util.notifyResponse(t('common.saved'), false);
      } else {
        TOGO.Util.notifyResponse(t('common.saveError'), true);
      }
    });
  }

  function updateVersion(payload) {
    const url = URL_TAB_EDITOR + 'updateEditorConfig';
    const id = payload.alreadyExistsId;
    const param = { id: id };
    payload.configurationJson = editorQuery.serialize();
    delete payload.alreadyExists;

    if (param.id === appliedId) {
      payload.applied = true;
    }

    ajaxPost(url, param, payload).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        const index = versions.findIndex((version) => version.id === id);
        setVersions((draft) => {
          draft.unshift(draft.splice(index, 1)[0]);
          draft[0].configurationJson = payload.configurationJson;
        });

        setSelectedId(id);
        TOGO.Util.notifyResponse(t('common.saved'), false);
      } else {
        TOGO.Util.notifyResponse(t('common.saveError'), true);
      }
    });
  }

  function checkVersion() {
    if (!versionName) return;
    const url = URL_TAB_EDITOR + 'checkExistsEditorConfig';

    const instanceType = editorQuery.getOptions().rootDataSource.source;
    const [objectClass, customInstanceType] =
      getInstanceTypePayload(instanceType);

    const payload = {
      name: versionName,
      containingObjectClass: objectClass,
      customInstance: customInstanceType
    };

    setLoading(true);

    ajaxPost(url, null, payload).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        if (ajaxData.response.alreadyExists) {
          payload.alreadyExistsId = ajaxData.response.id;
          TOGO.popupManager.confirmZebraDialog(
            t('tabeditor.versions.overwrite'),
            updateVersion,
            payload
          );
        } else {
          createVersion(payload);
        }
      }
      setError(ajaxData.error);
      setLoading(false);
    });
  }

  function openVersionsMenu(event) {
    setVersionsMenuAnchor(event.currentTarget);
  }

  function selectVersion(id, standardEditor) {
    if (standardEditor) {
      selectStandardEditor();
      return;
    }

    setSelectedId(id);
    setVersionsMenuAnchor(null);

    let loadedEditorState = null;
    const selectedVersion = versions.find((version) => id === version.id);

    if (selectedVersion) {
      loadedEditorState = selectedVersion.configurationJson;
      setVersionName(selectedVersion.name);
    }
    if (!loadedEditorState) {
      loadedEditorState = Object.assign({}, emptyEditor);
      const rootDataSourceValue =
        editorQuery.getOptions().rootDataSource.source;
      loadedEditorState.ROOT.props.dataSource.source = rootDataSourceValue;
    }

    editorActions.deserialize(loadedEditorState);
  }

  function selectStandardEditor() {
    setVersionsMenuAnchor(null);

    let loadedEditorState = Object.assign({}, standardEditor);

    editorActions.deserialize(loadedEditorState);
  }

  function selectAppliedVersion(targetVersion) {
    const url = URL_TAB_EDITOR + 'applyEditorConfig';
    const param = { id: targetVersion.id };
    const payload = {
      containingObjectClass: targetVersion.containingObjectClass,
      customInstance: targetVersion.customInstanceType
    };

    ajaxPost(url, param, payload).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setAppliedId(targetVersion.id);
      }
    });
  }

  function resetAppliedVersion() {
    const url = URL_TAB_EDITOR + 'applyEditorConfig';
    const instanceType = editorQuery.getOptions().rootDataSource.source;
    const [objectClass, customInstanceType] =
      getInstanceTypePayload(instanceType);
    const payload = {
      containingObjectClass: objectClass,
      customInstance: customInstanceType
    };

    ajaxPost(url, null, payload).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setAppliedId(null);
      }
    });
  }

  function deleteVersion(id) {
    TOGO.popupManager.confirmZebraDialog(
      t('tabeditor.versions.delete'),
      deleteVersionConfirmed,
      id
    );
  }

  function deleteVersionConfirmed(id) {
    const url = URL_TAB_EDITOR + 'deleteEditorConfig';
    ajaxPost(url, { id: id }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        setVersions(versions.filter((version) => id !== version.id));

        if (id === selectedId) {
          setSelectedId(null);
        }
        if (id === appliedId) {
          setAppliedId(null);
        }
      }
    });
  }

  function handleClose() {
    setVersionsMenuAnchor(null);
  }

  function downloadStandardEditor() {
    const standardEditorJson = JSON.stringify(standardEditor, null, 2);
    let tempDownloadElement = document.createElement('a');
    tempDownloadElement.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(standardEditorJson)
    );
    tempDownloadElement.setAttribute('download', 'standardEditor.json');
    tempDownloadElement.style.display = 'none';
    document.body.appendChild(tempDownloadElement);

    tempDownloadElement.click();

    document.body.removeChild(tempDownloadElement);
  }

  return (
    <>
      <Grid item>
        <TextField
          className={classes.versionNameTextField}
          id="tabeditor-version-name"
          label={t('tabeditor.versions.name')}
          value={versionName}
          onChange={(event) => {
            setVersionName(event.target.value);
          }}
        />
      </Grid>
      <Grid item>
        <MuiButton
          className={classes.button}
          size="small"
          variant="outlined"
          color="inherit"
          onClick={checkVersion}
        >
          {t('common.save')}
        </MuiButton>
      </Grid>
      <Grid item>
        <div className={classes.menuOpenButton}>
          <List
            component="nav"
            aria-label="Tab Editor Version"
            className={classes.menuWrap}
            style={
              versionsMenuAnchor
                ? {
                    borderColor: '#0066ff'
                  }
                : null
            }
          >
            <ListItem
              button
              className={classes.menuText}
              style={
                versionsMenuAnchor
                  ? {
                      color: '#0066ff'
                    }
                  : null
              }
              aria-describedby="tab-editor-version-list-menu"
              aria-label="Version"
              onClick={openVersionsMenu}
            >
              <ListItemText
                style={{ textAlign: 'center' }}
                primary={t('tabeditor.versions.versions')}
              />
              <Icon className={'fa fa-caret-down ' + classes.menuCaret} />
            </ListItem>
          </List>
          <Popover
            id="tab-editor-version-list-menu"
            open={Boolean(versionsMenuAnchor)}
            anchorEl={versionsMenuAnchor}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <TableContainer className={classes.menuTableContainer}>
              <Table stickyHeader aria-label="tab-editor-version-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      {t('tabeditor.versions.name')}
                    </TableCell>
                    <TableCell align="center">{t('common.edit')}</TableCell>
                    <TableCell align="center">{t('common.delete')}</TableCell>
                    <TableCell align="center">{t('common.download')}</TableCell>
                    <TableCell align="center">
                      {t('tabeditor.versions.applied')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {versions.map((version) => (
                    <TableRow
                      key={version.id}
                      selected={version.id === selectedId}
                      hover={false}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        style={{ maxWidth: '20rem' }}
                      >
                        <EllipsisToolTip>{version.name}</EllipsisToolTip>
                      </TableCell>
                      <TableCell align="center">
                        <MuiButton onClick={() => selectVersion(version.id)}>
                          <Icon className="fa fa-pencil fa-2x" />
                        </MuiButton>
                      </TableCell>
                      <TableCell align="center">
                        <MuiButton onClick={() => deleteVersion(version.id)}>
                          <Icon className="fa fa-trash-o fa-2x" />
                        </MuiButton>
                      </TableCell>
                      <TableCell align="center">
                        <MuiButton
                          href={
                            'tabEditor/downloadEditorConfig?id=' + version.id
                          }
                          download
                        >
                          <Icon className="fa fa-download fa-2x" />
                        </MuiButton>
                      </TableCell>
                      <TableCell align="center">
                        <Radio
                          checked={version.id === appliedId}
                          onClick={() => selectAppliedVersion(version)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow hover={false} className={classes.standardEditorRow}>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                      style={{ maxWidth: '20rem' }}
                    >
                      <EllipsisToolTip>Standard</EllipsisToolTip>
                    </TableCell>
                    <TableCell align="center">
                      <MuiButton onClick={() => selectVersion(null, true)}>
                        <Icon className="fa fa-pencil fa-2x" />
                      </MuiButton>
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center">
                      <MuiButton onClick={downloadStandardEditor}>
                        <Icon className="fa fa-download fa-2x" />
                      </MuiButton>
                    </TableCell>
                    <TableCell align="center">
                      <Radio
                        checked={appliedId === null}
                        onClick={resetAppliedVersion}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Popover>
        </div>
      </Grid>
    </>
  );
}
