import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: '#ffc600',
    fontFamily: '"Roboto", sans-serif'
  }
}));

const MissingDefinition = (defTitle) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.content}>
      {t('tabeditor.tools.missingDef', defTitle)}
    </div>
  );
};

export default MissingDefinition;
