import React from 'react';

import { useDataManagement } from '../../datamanagement/DataManagementContext';
import { contentTypes } from '../DataSourceContent';

import { TableDisplay } from './TableDisplay';
import { BulkDataTypes as FieldBulkDataTypes } from '../tools/FieldEditorSelector';
import { getFeatureKey } from '../../datamanagement/ArticleFeatureEditor';

export const MultipleInstanceTable = ({
  multipleInstanceData,
  instanceType,
  parentNode,
  parentInstanceEditor,
  nodesContent
}) => {
  const { getVariableContentDefs, getFeatureDefs } = useDataManagement();
  const linkedNodes = parentNode.linkedNodes;
  let columns = [...parentNode.props.columns];
  const nodeIdInCollapseRow = linkedNodes.collapseTableRow;

  if (!multipleInstanceData) return null;

  addBulkData(
    columns,
    multipleInstanceData,
    getVariableContentDefs,
    getFeatureDefs
  );

  columns
    .filter((column) => column.bulkData)
    .forEach((column) => {
      const bulkNodeId = 'bulkData_' + column.dataId;
      if (bulkNodeId in nodesContent) return;

      const bulkDataNode = {
        type: {},
        props: {},
        nodes: [],
        linkedNodes: {}
      };
      let contentId = column.bulkData.contentIdentifier;
      bulkDataNode.type.resolvedName = 'FieldEditorSelector';
      switch (column.bulkData.type) {
        case contentTypes.GeneralField:
          bulkDataNode.props.generalFieldType = contentId;
          break;
        case contentTypes.CustomField:
          bulkDataNode.props.customFieldType = contentId;
          break;
        case contentTypes.Features:
          bulkDataNode.props.featureKey = contentId;
          break;
        default:
          break;
      }

      nodesContent[bulkNodeId] = bulkDataNode;
    });

  const instanceRows = multipleInstanceData?.childrenInstances?.map(createRow);

  function createRow(instanceData) {
    const cells = columns.map((column) => {
      const cell = {
        wrapperId: column.dataId
      };
      if (column.bulkData) {
        let bulkNodeId = 'bulkData_' + column.dataId;
        cell.nodeId = bulkNodeId;
      } else {
        cell.nodeId = linkedNodes[column.dataId];
      }
      return cell;
    });

    return {
      instanceData: instanceData,
      instanceId: instanceData.id,
      cells: cells
    };
  }

  const variableContentDefs = getVariableContentDefs(
    multipleInstanceData?.instanceType
  );

  columns = columns.map((column) => {
    if (column.bulkData || !column.category) {
      return column;
    }

    /*  column.category.customFieldType ||
        column.category.generalFieldType ||
        column.category.mediaType ||
        column.category.textType;
    */
    let columnDef;

    if (column.category.customFieldType) {
      columnDef = variableContentDefs.customFieldDefs.find(
        (def) => def.value === column.category.customFieldType
      );
    }

    if (column.category.generalFieldType) {
      columnDef = variableContentDefs.generalFieldDefs.find(
        (def) => def.value === column.category.generalFieldType
      );
      const columnWithLabel = {
        ...column,
        header: { missingContentLang: null, text: columnDef.label }
      };
      return columnWithLabel;
    }

    if (column.category.mediaType) {
      columnDef = variableContentDefs.mediumDefs.find(
        (def) => def.value === column.category.mediaType
      );
    }

    if (column.category.textType) {
      columnDef = variableContentDefs.textDefs.find(
        (def) => def.value === column.category.textType
      );
    }

    if (!columnDef) {
      return column;
    }

    const columnWithLabel = { ...column, header: columnDef.contentLabel };
    return columnWithLabel;
  });

  return (
    <TableDisplay
      instanceType={instanceType}
      parentInstanceEditor={parentInstanceEditor}
      columns={columns}
      initialInstanceRows={instanceRows}
      createRow={createRow}
      nodesContent={nodesContent}
      nodeIdInCollapseRow={nodeIdInCollapseRow}
      orientation={parentNode.props.orientation}
    ></TableDisplay>
  );
};

function addBulkData(
  columns,
  multipleInstanceData,
  getVariableContentDefs,
  getFeatureDefs
) {
  let bulkDataIndexes = columns.reduce((bulkIndexes, column, index) => {
    let supportedBulkDataType = column.category?.bulkDataType;
    if (
      supportedBulkDataType &&
      ([
        FieldBulkDataTypes.general_fields,
        FieldBulkDataTypes.custom_fields,
        FieldBulkDataTypes.features
      ].includes(supportedBulkDataType) ||
        supportedBulkDataType.startsWith(
          FieldBulkDataTypes.custom_fields_group
        ))
    ) {
      bulkIndexes.push(index);
    }
    return bulkIndexes;
  }, []);
  if (!bulkDataIndexes.length) return;

  const variableContentDefs = getVariableContentDefs(
    multipleInstanceData.instanceType
  );

  let i = 0;
  while (i < bulkDataIndexes.length) {
    const bulkDataIndex = bulkDataIndexes[i];
    const bulkDataColumn = columns[bulkDataIndex];
    let increment;

    switch (bulkDataColumn.category.bulkDataType) {
      case FieldBulkDataTypes.general_fields:
        increment = addGeneralFieldsInBulk(
          columns,
          bulkDataIndex,
          variableContentDefs
        );
        break;
      case FieldBulkDataTypes.custom_fields:
        increment = addCustomFieldsInBulk(
          columns,
          bulkDataIndex,
          variableContentDefs
        );
        break;
      case FieldBulkDataTypes.features:
        increment = addFeaturesInBulk(columns, bulkDataIndex, getFeatureDefs);
        break;

      default:
        if (
          bulkDataColumn.category.bulkDataType.startsWith(
            FieldBulkDataTypes.custom_fields_group
          )
        ) {
          const groupId = bulkDataColumn.category.bulkDataType.split(
            FieldBulkDataTypes.custom_fields_group
          )[1];
          increment = addCustomFieldsInBulk(
            columns,
            bulkDataIndex,
            variableContentDefs,
            groupId
          );
        }
    }

    bulkDataIndexes = bulkDataIndexes.map((curBulkIndex) => {
      return curBulkIndex + increment;
    });

    i += 1;
  }
}

function addGeneralFieldsInBulk(columns, bulkDataIndex, variableContentDefs) {
  const allGeneralFieldsColumn = columns[bulkDataIndex];
  const generalFieldDefs = variableContentDefs?.generalFieldDefs || [];
  const generalFieldColumns = generalFieldDefs
    .filter(
      (generalFieldDef) =>
        ![FieldBulkDataTypes.features, 'keyword'].includes(
          generalFieldDef.value
        )
    )
    .map((generalFieldDef) => ({
      dataId: allGeneralFieldsColumn.dataId + generalFieldDef.value,
      header: { missingContentLang: null, text: generalFieldDef.label },
      bulkData: {
        type: contentTypes.GeneralField,
        contentIdentifier: generalFieldDef.value
      }
    }));

  columns.splice(bulkDataIndex, 1, ...generalFieldColumns);
  return generalFieldColumns.length - 1;
}

function addCustomFieldsInBulk(
  columns,
  bulkDataIndex,
  variableContentDefs,
  groupId
) {
  const allCustomFieldsColumn = columns[bulkDataIndex];
  let customFieldDefs = variableContentDefs?.customFieldDefs || [];

  if (groupId) {
    customFieldDefs = customFieldDefs
      .filter((def) => groupId == def.groupId)
      .sort((a, b) => {
        if (a.groupOrder === b.groupOrder) {
          return 0;
        }
        // nulls sort after anything else
        else if (a.groupOrder === null) {
          return 1;
        } else if (b.groupOrder === null) {
          return -1;
        } else {
          return a.groupOrder > b.groupOrder ? 1 : -1;
        }
      });
  }

  const customFieldColumns = customFieldDefs.map((customFieldDef) => ({
    dataId: allCustomFieldsColumn.dataId + customFieldDef.value,
    header: customFieldDef.contentLabel,
    bulkData: {
      type: contentTypes.CustomField,
      contentIdentifier: customFieldDef.value
    }
  }));

  columns.splice(bulkDataIndex, 1, ...customFieldColumns);
  return customFieldColumns.length - 1;
}

function addFeaturesInBulk(columns, bulkDataIndex, getFeatureDefs) {
  const features = getFeatureDefs();

  const featureColumn = columns[bulkDataIndex];
  const featureColumns = features.map((feature) => ({
    dataId:
      featureColumn.dataId +
      feature.classificationIdentifier +
      feature.classIdentifier +
      feature.featureIdentifier,
    header: feature.featureHeader,
    bulkData: {
      type: contentTypes.Features,
      contentIdentifier: getFeatureKey(feature)
    }
  }));

  columns.splice(bulkDataIndex, 1, ...featureColumns);
  return featureColumns.length - 1;
}
