import ajaxGet from 'src/services/ajaxGet';

const emptyVariableContentData = {
  textDefs: [],
  mediumDefs: [],
  fieldDefs: [],
  instanceDefs: [],
  articleDataDefs: []
};

const dataSourceVariableContent = {
  Product: null,
  Article: null
};

export const contentTypes = {
  Text: 'Text',
  Medium: 'Medium',
  CustomField: 'CustomField',
  GeneralField: 'GeneralField',
  Features: 'Features',
  Instance: 'Instance'
};

function getNewDataSourceContent(dataSource) {
  const params = {
    instanceDataSource: dataSource,
    contentLanguage: $('#contentLangSelectElement').val() || null
  };

  let content;
  const call = ajaxGet('tabEditor/variableContentDef', params).then(
    (ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        content = ajaxData.response.variableContentData;
      } else {
        content = emptyVariableContentData;
      }
      dataSourceVariableContent[dataSource] = content;
    }
  );

  return call.then(() => {
    return content;
  });
}

function getDataSourceContent(dataSource) {
  if (!(dataSource in dataSourceVariableContent)) {
    dataSourceVariableContent[dataSource] = null;
  }

  let content = dataSourceVariableContent[dataSource];
  return content;
}

function setComponentDefsOfContent(contentType, setComponentDefs, content) {
  switch (contentType) {
    case contentTypes.Text:
      setComponentDefs(content.textDefs);
      break;
    case contentTypes.Medium:
      setComponentDefs(content.mediumDefs);
      break;
    case contentTypes.CustomField:
      setComponentDefs(content.customFieldDefs);
      break;
    case contentTypes.GeneralField:
      setComponentDefs(content.generalFieldDefs);
      break;
    case contentTypes.Instance:
      setComponentDefs(content.instanceDefs);
      break;

    default:
      break;
  }
}

export function setContentDefs(nodeMap, nodeId, setComponentDefs, contentType) {
  const parentId = nodeMap(nodeId)?.get().data.parent;
  if (!parentId) return;
  const parentDataSource = nodeMap(parentId)?.get().data.props.dataSource;

  let content = getDataSourceContent(parentDataSource.source);
  if (!content) {
    getNewDataSourceContent(parentDataSource.source).then((newContent) => {
      setComponentDefsOfContent(contentType, setComponentDefs, newContent);
    });
  } else {
    setComponentDefsOfContent(contentType, setComponentDefs, content);
  }
}
