import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n';

import { useEditor, useNode } from '@craftjs/core';

import { TableDataContext } from './Table';

import {
  Paper,
  FormControl,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';

import SelectedWrap from './SelectedWrap';
import { contentTypes, setContentDefs } from '../DataSourceContent';

export const useStyles = makeStyles(() => ({
  root: {
    '& > .editorMockup': {
      margin: '1rem 1rem'
    }
  },
  two: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flex: '1 0 100%'
    },
    '& > .editorMockup': {
      flex: '1 0 48%',
      margin: '0 5px'
    }
  }
}));

export const Container = ({
  background,
  padding,
  dataSource,
  containerFlexColumns,
  children
}) => {
  const classes = useStyles();
  const [isInTableContext] = useContext(TableDataContext);

  const {
    connectors: { connect, drag },
    id,
    actions: { setProp }
  } = useNode();
  const {
    isSelected,
    query: { node: nodeMap }
  } = useEditor((state) => ({
    isSelected: state.events.selected === id
  }));
  const parentId = nodeMap(id)?.get().data.parent;

  //geht nicht. war für das cursor symbol gedacht:
  function handleDragOver(e) {
    let dt = e.dataTransfer;
    if (dt) {
      e.dataTransfer.dropEffect = 'copy';
    }
  }

  useEffect(() => {
    if (!parentId) return;

    if (!dataSource || !dataSource.childOverride) {
      const parentDataSource = nodeMap(parentId)?.get().data.props.dataSource;

      if (parentDataSource) {
        const newDataSource = {
          source: parentDataSource.source,
          singleData: parentDataSource.singleData,
          childOverride: false
        };
        setProp((props) => (props.dataSource = newDataSource), 500);
      }
    }

    setProp((props) => (props.isTableCell = isInTableContext), 500);
  }, [parentId]);

  // const [checked, setChecked] = useState(false);

  // function handleCheck() {
  //   setChecked(!checked);
  // }

  return (
    <SelectedWrap isSelected={isSelected}>
      <Paper
        ref={(ref) => connect(drag(ref))}
        style={{
          background,
          padding: `${padding}px`,
          marginTop: '1rem',
          marginBottom: '1rem'
        }}
        onDragOver={(e) => handleDragOver(e)}
        className={`${classes.root} ${
          containerFlexColumns === '2' ? classes.two : null
        }`}
      >
        {/* <input
          type="checkbox"
          onChange={handleCheck}
          defaultChecked={checked}
        /> */}
        {children}
      </Paper>
    </SelectedWrap>
  );
};
Container.propTypes = {
  background: PropTypes.any,
  padding: PropTypes.any,
  dataSource: PropTypes.any,
  isTableCell: PropTypes.bool,
  children: PropTypes.any,
  containerFlexColumns: PropTypes.any
};

export const ContainerSettings = () => {
  const { t } = useTranslation();
  const [instanceDefs, setInstanceDefs] = useState(null);
  const {
    background,
    containerFlexColumns,
    padding,
    dataSource,
    parentId,
    isRoot,
    actions: { setProp },
    id
  } = useNode((node) => ({
    background: node.data.props.background,
    containerFlexColumns: node.data.props.containerFlexColumns,
    padding: node.data.props.padding,
    dataSource: node.data.props.dataSource,
    parentId: node.data.parent,
    isRoot: node.id === 'ROOT'
  }));
  const {
    query: { node: nodeMap, getOptions: getQueryOptions },
    actions: { delete: deleteNode }
  } = useEditor();
  const rootDataSource = getQueryOptions().rootDataSource;
  const hasRootDataSourceOption =
    rootDataSource &&
    parentId &&
    rootDataSource.source ==
      nodeMap(parentId)?.get().data.props.dataSource.source;

  useEffect(() => {
    setContentDefs(nodeMap, id, setInstanceDefs, contentTypes.Instance);
  }, []);

  function changeDataSource(event) {
    const dataSource = {
      source: event.target.value,
      singleData: false,
      childOverride: true
    };
    setProp((props) => {
      props.dataSource = dataSource;
    });

    const childIds = nodeMap(id)?.descendants();
    if (childIds) {
      childIds.map((childId) => {
        deleteNode(childId);
      });
    }
  }

  const handleChange = (event) => {
    setProp((props) => (props.containerFlexColumns = event.target.value));
  };

  return (
    <>
      {!isRoot && instanceDefs && !!instanceDefs.length && (
        <FormControl variant="outlined" component="fieldset">
          <InputLabel id="datasource-select-outlined-label">
            {t('tabeditor.settings.dataSource')}
          </InputLabel>
          <Select
            labelId="datasource-select-outlined-label"
            id="datasource-select-outlined"
            label={t('tabeditor.settings.dataSource')}
            defaultValue={''}
            value={dataSource.source}
            onChange={changeDataSource}
          >
            {hasRootDataSourceOption && (
              <MenuItem disabled value={rootDataSource.source}>
                <em>{rootDataSource.label}</em>
              </MenuItem>
            )}
            {instanceDefs.map((instanceDef) => (
              <MenuItem key={instanceDef.value} value={instanceDef.value}>
                {instanceDef.label || instanceDef.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">
          {t('tabeditor.settings.background')}
        </FormLabel>
        <NodeColorPicker
          color={background}
          setNodeProp={setProp}
        ></NodeColorPicker>
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">
          {t('tabeditor.settings.padding')}
        </FormLabel>
        <Slider
          defaultValue={padding}
          onChange={(_, value) =>
            setProp((props) => (props.padding = value), 500)
          }
        />
      </FormControl>
      <FormControl fullWidth={true} margin="normal" component="fieldset">
        <FormLabel component="legend">
          {t('tabeditor.settings.columns')}
        </FormLabel>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          value={containerFlexColumns}
          onChange={handleChange}
        >
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="1"
            labelPlacement="top"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label="2"
            labelPlacement="top"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export const ContainerDefaultProps = {
  background: '#ffffff',
  padding: 10,
  containerFlexColumns: '1',
  dataSource: {
    source: '',
    childOverride: false
  }
};

Container.craft = {
  name: i18n.t('tabeditor.tools.container'),
  props: ContainerDefaultProps,
  related: {
    settings: ContainerSettings
  },
  rules: {
    canMoveIn: containerMoveInRule
  }
};

export function containerMoveInRule(incomingNode, currentNode, nodeMap) {
  const curDataSource = currentNode.data.props.dataSource;
  if (!curDataSource) return false;

  const incomingIsEditor = incomingNode.data.name.includes('Editor');
  if (curDataSource.singleData == false && incomingIsEditor) {
    return false;
  }

  const incomingNodeParentId = incomingNode.data.parent;
  if (!incomingNodeParentId) {
    return true;
  }
  if (currentNode.id === incomingNodeParentId) {
    return true;
  }

  const curDataSourceValue = curDataSource?.source;
  let incomingNodeParentDataSourceValue;
  if (incomingNodeParentId) {
    incomingNodeParentDataSourceValue =
      nodeMap(incomingNodeParentId)?.get().data.props.dataSource?.source;
  }

  return (
    curDataSourceValue &&
    incomingNodeParentDataSourceValue &&
    curDataSourceValue === incomingNodeParentDataSourceValue
  );
}

export const ContainerRules = {};

const useColorPickerStyles = makeStyles({
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px'
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer'
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
    bottom: 33,
    right: 273
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  }
});

export const NodeColorPicker = ({ color, setNodeProp }) => {
  const classes = useColorPickerStyles();
  const [isDisplay, setIsDisplay] = useState(false);

  return (
    <div>
      {isDisplay ? (
        <div className={classes.popover}>
          <div
            className={classes.cover}
            onClick={() => {
              setIsDisplay(false);
            }}
          />
          <SketchPicker
            color={color}
            onChange={(color) => {
              setNodeProp((props) => (props.background = color.hex), 500);
            }}
          />
        </div>
      ) : null}
      <div
        className={classes.swatch}
        onClick={() => {
          setIsDisplay(!isDisplay);
        }}
      >
        <div className={classes.color} style={{ background: color }} />
      </div>
    </div>
  );
};
