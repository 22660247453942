import React from 'react';

export const TitleDisplay = ({ text, fontSize, textAlign }) => {
  return (
    <div>
      <p style={{ fontSize: `${fontSize}px`, textAlign, marginTop: '2rem' }}>
        {text[document.documentElement.lang]}
      </p>
    </div>
  );
};
