import React, { createContext, useContext, useState } from 'react';
import { useImmer } from 'use-immer';
import ajaxPost from 'src/services/ajaxPost';
import ajaxGet from 'src/services/ajaxGet';

export const DATA_FIELD_TYPES = {
  header: 'header',
  hierarchyInfo: 'hierarchyInfo',
  customField: 'customField'
};

const ExportContext = createContext(null);

const ExportProvider = ({ children }) => {
  const [contentLang, setContentLang] = useState(null);
  const [productStructure, setProductStructure] = useState('');
  const [shopId, setShopId] = useState('');
  const [catalogId, setCatalogId] = useState('');
  const [ctxId, setCtxId] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [articleColumn, setArticleColumn] = useState('');
  const [internUsers, setInternUsers] = useState([]);
  const [customerAdresses, setCustomerAdresses] = useState([]);
  const [mediaExportOption, setMediaExportOption] = useState('noMedia');
  const [exportCycle, setExportCycle] = useState('ONCE');
  const [weekday, setWeekday] = useState('MONDAY');
  const [startDateExport, setStartDateExport] = useState('');
  const [selectedSpec, setSelectedSpec] = useState('');
  const [selectedVariant, setSelectedVariant] = useState('');
  const [nullFeatureValueDefinitionInput, setNullFeatureValueDefinitionInput] =
    useState('');
  const [classification, setClassification] = useState('all');
  const [headerFields, setHeaderFields] = useImmer([]);
  const [hierarchyInfos, setHierarchyInfos] = useImmer([]);
  const [customFields, setCustomFields] = useImmer([]);
  const [headerFieldValues, setHeaderFieldValues] = useImmer({});
  const [hierarchyInfoValues, setHierarchyInfoValues] = useImmer({});
  const [headerFieldState, setHeaderFieldState] = useImmer({});
  const [hierarchyInfoState, setHierarchyInfoState] = useImmer({});
  const [customFieldValues, setCustomFieldValues] = useImmer({});
  const [customFieldState, setCustomFieldState] = useImmer({});
  const [exportName, setExportName] = useImmer(null);
  const [mandatoryFieldIds, setMandatoryFieldIds] = useState([]);
  const [mandatoryHeaderFieldIds, setMandatoryHeaderFieldIds] = useState([]);
  const [mandatoryHierarchyInfoIds, setMandatoryHierarchyInfoIds] = useState(
    []
  );
  const [loadedExports, setLoadedExports] = useImmer([]);
  const [specificationList, setSpecificationList] = useState([]);
  const [specVariantsList, setSpecVariantsList] = useState([]);
  const [exportId, setExportId] = useState(null);
  const [exportDefinitionId, setExportDefinitionId] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [loadingBmeCat, setLoadingBmeCat] = useState(false);
  const [columns, setColumns] = useImmer(null);
  const [articleChoice, setArticleChoice] = useImmer('0');
  const [oldExportName, setOldExportName] = useState('');
  const [articleChooseError, setArticleChooseError] = useState(false);
  const [bmeCatConfigError, setBmeCatConfigError] = useState(false);
  const [headerFieldsError, setHeaderFieldsError] = useState(false);
  const [hierarchyInfosError, setHierarchyInfosError] = useState(false);
  const [customFieldsError, setCustomFieldsError] = useState(false);
  const [exportConfError, setExportConfError] = useState(false);
  const [selectServerError, setSelectServerError] = useState(false);

  const [headerErrorGroupIds, setHeaderErrorGroupIds] = useImmer({});
  const [hierarchyInfoErrorGroupIds, setHierarchyInfoErrorGroupIds] = useImmer(
    {}
  );
  const [customErrorGroupIds, setCustomErrorGroupIds] = useImmer({});
  const [customSpecList, setCustomSpecList] = useState([]);
  const [customSpecDbId, setCustomSpecDbId] = useState('');
  const [regExErrorList, setRegExErrorList] = useState([]);

  const [connectionType, setConnectionType] =  useState('fileDownload');
  const [sftpConnectionConfigurationId, setSftpConnectionConfigurationId] = useState('');


  return (
    <ExportContext.Provider
      value={[
        contentLang,
        setContentLang,
        productStructure,
        setProductStructure,
        shopId,
        setShopId,
        catalogId,
        setCatalogId,
        ctxId,
        setCtxId,
        fileName,
        setFileName,
        articleColumn,
        setArticleColumn,
        internUsers,
        setInternUsers,
        customerAdresses,
        setCustomerAdresses,
        mediaExportOption,
        setMediaExportOption,
        exportCycle,
        setExportCycle,
        weekday,
        setWeekday,
        startDateExport,
        setStartDateExport,
        selectedSpec,
        setSelectedSpec,
        selectedVariant,
        setSelectedVariant,
        classification,
        setClassification,
        nullFeatureValueDefinitionInput,
        setNullFeatureValueDefinitionInput,
        headerFields,
        setHeaderFields,
        hierarchyInfos,
        setHierarchyInfos,
        customFields,
        setCustomFields,
        headerFieldValues,
        setHeaderFieldValues,
        hierarchyInfoValues,
        setHierarchyInfoValues,
        headerFieldState,
        setHeaderFieldState,
        hierarchyInfoState,
        setHierarchyInfoState,
        customFieldValues,
        setCustomFieldValues,
        customFieldState,
        setCustomFieldState,
        exportName,
        setExportName,
        mandatoryFieldIds,
        setMandatoryFieldIds,
        mandatoryHeaderFieldIds,
        setMandatoryHeaderFieldIds,
        mandatoryHierarchyInfoIds,
        setMandatoryHierarchyInfoIds,
        loadedExports,
        setLoadedExports,
        specificationList,
        setSpecificationList,
        specVariantsList,
        setSpecVariantsList,
        exportId,
        setExportId,
        exportDefinitionId,
        setExportDefinitionId,
        tabValue,
        setTabValue,
        loadingBmeCat,
        setLoadingBmeCat,
        columns,
        setColumns,
        articleChoice,
        setArticleChoice,
        oldExportName,
        setOldExportName,
        articleChooseError,
        setArticleChooseError,
        bmeCatConfigError,
        setBmeCatConfigError,
        headerFieldsError,
        setHeaderFieldsError,
        hierarchyInfosError,
        setHierarchyInfosError,
        customFieldsError,
        setCustomFieldsError,
        exportConfError,
        setExportConfError,
        headerErrorGroupIds,
        setHeaderErrorGroupIds,
        hierarchyInfoErrorGroupIds,
        setHierarchyInfoErrorGroupIds,
        customErrorGroupIds,
        setCustomErrorGroupIds,
        customSpecList,
        setCustomSpecList,
        customSpecDbId,
        setCustomSpecDbId,
        regExErrorList,
        setRegExErrorList,
        connectionType,
        setConnectionType,
        sftpConnectionConfigurationId,
        setSftpConnectionConfigurationId,
        selectServerError,
        setSelectServerError,
      ]}
    >
      {children}
    </ExportContext.Provider>
  );
};

const useExport = () => {
  const [
    contentLang,
    setContentLang,
    productStructure,
    setProductStructure,
    shopId,
    setShopId,
    catalogId,
    setCatalogId,
    ctxId,
    setCtxId,
    fileName,
    setFileName,
    articleColumn,
    setArticleColumn,
    internUsers,
    setInternUsers,
    customerAdresses,
    setCustomerAdresses,
    mediaExportOption,
    setMediaExportOption,
    exportCycle,
    setExportCycle,
    weekday,
    setWeekday,
    startDateExport,
    setStartDateExport,
    selectedSpec,
    setSelectedSpec,
    selectedVariant,
    setSelectedVariant,
    classification,
    setClassification,
    nullFeatureValueDefinitionInput,
    setNullFeatureValueDefinitionInput,
    headerFields,
    setHeaderFields,
    hierarchyInfos,
    setHierarchyInfos,
    customFields,
    setCustomFields,
    headerFieldValues,
    setHeaderFieldValues,
    hierarchyInfoValues,
    setHierarchyInfoValues,
    headerFieldState,
    setHeaderFieldState,
    hierarchyInfoState,
    setHierarchyInfoState,
    customFieldValues,
    setCustomFieldValues,
    customFieldState,
    setCustomFieldState,
    exportName,
    setExportName,
    mandatoryFieldIds,
    setMandatoryFieldIds,
    mandatoryHeaderFieldIds,
    setMandatoryHeaderFieldIds,
    mandatoryHierarchyInfoIds,
    setMandatoryHierarchyInfoIds,
    loadedExports,
    setLoadedExports,
    specificationList,
    setSpecificationList,
    specVariantsList,
    setSpecVariantsList,
    exportId,
    setExportId,
    exportDefinitionId,
    setExportDefinitionId,
    tabValue,
    setTabValue,
    loadingBmeCat,
    setLoadingBmeCat,
    columns,
    setColumns,
    articleChoice,
    setArticleChoice,
    oldExportName,
    setOldExportName,
    articleChooseError,
    setArticleChooseError,
    bmeCatConfigError,
    setBmeCatConfigError,
    headerFieldsError,
    setHeaderFieldsError,
    hierarchyInfosError,
    setHierarchyInfosError,
    customFieldsError,
    setCustomFieldsError,
    exportConfError,
    setExportConfError,
    headerErrorGroupIds,
    setHeaderErrorGroupIds,
    hierarchyInfoErrorGroupIds,
    setHierarchyInfoErrorGroupIds,
    customErrorGroupIds,
    setCustomErrorGroupIds,
    customSpecList,
    setCustomSpecList,
    customSpecDbId,
    setCustomSpecDbId,
    regExErrorList,
    setRegExErrorList,
    connectionType,
    setConnectionType,
    sftpConnectionConfigurationId,
    setSftpConnectionConfigurationId,
    selectServerError,
    setSelectServerError,
  ] = useContext(ExportContext);

  function handleGetProductStructure() {
    return productStructure;
  }

  function handleSetProductStructure(productStructureId) {
    setProductStructure(productStructureId);
  }

  function handleGetShopId() {
    return shopId;
  }

  function handleSetShopId(newShopId) {
    setShopId(newShopId);
  }

  function handleGetCatalogId() {
    return catalogId;
  }

  function handleSetCatalogId(newCatalogId) {
    setCatalogId(newCatalogId);
  }

  function handleGetCtxId() {
    return ctxId;
  }

  function handleSetCtxId(newCtxId) {
    setCtxId(newCtxId);
  }

  function handleGetFileName() {
    return fileName;
  }

  function handleSetFileName(newFileName) {
    setFileName(newFileName);
  }

  function handleGetArticleColumn() {
    return articleColumn;
  }

  function handleSetArticleColumn(newArticleColumn) {
    setArticleColumn(newArticleColumn);
  }

  function handleGetInternUsers() {
    return internUsers;
  }

  function handleSetInternUsers(newInternUsers) {
    setInternUsers(newInternUsers);
  }

  function handleGetCustomerAdresses() {
    return customerAdresses;
  }

  function handleSetCustomerAdresses(newCustomerAdresses) {
    setCustomerAdresses(newCustomerAdresses);
  }

  function handleGetMediaExportOption() {
    return mediaExportOption;
  }

  function handleSetMediaExportOption(newMediaExportOption) {
    setMediaExportOption(newMediaExportOption);
  }

  function handleGetExportCycle() {
    return exportCycle;
  }

  function handleSetExportCycle(newExportCycle) {
    setExportCycle(newExportCycle);
  }

  function handleGetWeekday() {
    return weekday;
  }

  function handleSetWeekday(newWeekday) {
    setWeekday(newWeekday);
  }

  function handleGetStartDateExport() {
    return startDateExport;
  }

  function handleSetStartDateExport(newStartDateExport) {
    setStartDateExport(newStartDateExport);
  }

  function handleGetSelectedSpec() {
    return selectedSpec;
  }

  function handleSetSelectedSpec(newSelectedSpec) {
    setSelectedSpec(newSelectedSpec);
  }

  function handleGetCustomSpecDbId() {
    return customSpecDbId;
  }

  function handleSetCustomSpecDbId(id) {
    setCustomSpecDbId(id);
  }

  function handleGetSelectedVariant() {
    return selectedVariant;
  }

  function handleSetSelectedVariant(newSelectedVariant) {
    setSelectedVariant(newSelectedVariant);
  }

  function handleGetClassification() {
    return classification;
  }

  function handleSetClassification(newClassification) {
    setClassification(newClassification);
  }
  function handleGetNullFeatureValueDefinitionInput() {
    return nullFeatureValueDefinitionInput;
  }

  function handleSetNullFeatureValueDefinitionInput(
    newNullFeatureValueDefinitionInput
  ) {
    setNullFeatureValueDefinitionInput(newNullFeatureValueDefinitionInput);
  }

  function handleGetHeaderFields() {
    return headerFields;
  }

  function handleSetHeaderFields(newHeaderFields) {
    setHeaderFields(newHeaderFields);
  }

  function handleGetHierarchyInfos() {
    return hierarchyInfos;
  }

  function handleSetHierarchyInfos(hierarchyInfos) {
    setHierarchyInfos(hierarchyInfos);
  }

  function handleGetCustomFields() {
    return customFields;
  }

  function handleSetCustomFields(newCustomFields) {
    setCustomFields(newCustomFields);
  }

  function handleGetHeaderErrorGroupIds() {
    return headerErrorGroupIds;
  }

  function handleSetHeaderErrorGroupIds(groupId, value) {
    if (value) {
      setHeaderErrorGroupIds((draft) => {
        draft[groupId] = value;
      });
    } else {
      setHeaderErrorGroupIds((draft) => {
        delete draft[groupId];
      });
    }
  }

  function handleGetHierarchyInfoErrorGroupIds() {
    return hierarchyInfoErrorGroupIds;
  }

  function handleSetHierarchyInfoErrorGroupIds(groupId, value) {
    if (value) {
      setHierarchyInfoErrorGroupIds((draft) => {
        draft[groupId] = value;
      });
    } else {
      setHierarchyInfoErrorGroupIds((draft) => {
        delete draft[groupId];
      });
    }
  }

  function handleGetCustomErrorGroupIds() {
    return customErrorGroupIds;
  }

  function handleSetCustomErrorGroupIds(groupId, value) {
    if (value) {
      setCustomErrorGroupIds((draft) => {
        draft[groupId] = value;
      });
    } else {
      setCustomErrorGroupIds((draft) => {
        delete draft[groupId];
      });
    }
  }

  function handleGetHeaderFieldValues() {
    return headerFieldValues;
  }

  function handleSetHeaderFieldValues(headerIdentifier, valuePayload) {
    setHeaderFieldValues((draft) => {
      if (valuePayload) {
        draft[headerIdentifier] = valuePayload;
      } else {
        delete draft[headerIdentifier];
      }
    });
  }

  function handleSetHeaderFieldValuesBulk(cachedValues) {
    if (Object.keys(cachedValues).length === 0) return;

    setHeaderFieldValues((draft) => {
      for (const headerIdentifier in cachedValues) {
        const valuePayload = cachedValues[headerIdentifier];
        if (valuePayload) {
          draft[headerIdentifier] = valuePayload;
        } else {
          delete draft[headerIdentifier];
        }
      }
    });
  }

  function handleGetHierarchyInfoValues() {
    return hierarchyInfoValues;
  }

  function handleSetHierarchyInfoValues(infoIdentifier, valuePayload) {
    setHierarchyInfoValues((draft) => {
      if (valuePayload) {
        draft[infoIdentifier] = valuePayload;
      } else {
        delete draft[infoIdentifier];
      }
    });
  }

  function handleSetHierarchyInfoValuesBulk(cachedValues) {
    if (Object.keys(cachedValues).length === 0) return;

    setHierarchyInfoValues((draft) => {
      for (const infoIdentifier in cachedValues) {
        const valuePayload = cachedValues[infoIdentifier];
        if (valuePayload) {
          draft[infoIdentifier] = valuePayload;
        } else {
          delete draft[infoIdentifier];
        }
      }
    });
  }

  function handleGetHeaderFieldState(identifier) {
    return headerFieldState[identifier];
  }

  function handleSetHeaderFieldState(identifier, value) {
    setHeaderFieldState((draft) => {
      draft[identifier] = value;
    });
  }

  function handleSetHeaderFieldStatesBulk(cachedValues) {
    if (Object.keys(cachedValues).length === 0) return;

    setHeaderFieldState((draft) => {
      for (const identifier in cachedValues) {
        const value = cachedValues[identifier];
        draft[identifier] = value;
      }
    });
  }

  function handleGetHierarchyInfoState(identifier) {
    return hierarchyInfoState[identifier];
  }

  function handleSetHierarchyInfoState(identifier, value) {
    setHierarchyInfoState((draft) => {
      draft[identifier] = value;
    });
  }

  function handleSetHierarchyInfoStatesBulk(cachedValues) {
    if (Object.keys(cachedValues).length === 0) return;

    setHierarchyInfoState((draft) => {
      for (const identifier in cachedValues) {
        const value = cachedValues[identifier];
        draft[identifier] = value;
      }
    });
  }

  function handleGetCustomFieldValues() {
    return customFieldValues;
  }

  function handleSetCustomFieldValues(identifier, valuePayload) {
    setCustomFieldValues((draft) => {
      if (valuePayload) {
        draft[identifier] = valuePayload;
      } else {
        delete draft[identifier];
      }
    });
  }

  function handleSetCustomFieldValuesBulk(cachedValues) {
    if (Object.keys(cachedValues).length === 0) return;

    setCustomFieldValues((draft) => {
      for (const identifier in cachedValues) {
        const valuePayload = cachedValues[identifier];
        if (valuePayload) {
          draft[identifier] = valuePayload;
        } else {
          delete draft[identifier];
        }
      }
    });
  }

  function handleGetCustomFieldState(identifier) {
    return customFieldState[identifier];
  }

  function handleSetCustomFieldState(identifier, value) {
    setCustomFieldState((draft) => {
      draft[identifier] = value;
    });
  }

  function handleSetCustomFieldStatesBulk(cachedValues) {
    if (Object.keys(cachedValues).length === 0) return;

    setCustomFieldState((draft) => {
      for (const identifier in cachedValues) {
        const value = cachedValues[identifier];
        draft[identifier] = value;
      }
    });
  }

  function handleGetExportName() {
    return exportName;
  }

  function handleSetExportName(newExportName) {
    setExportName(newExportName);
  }

  function handleSetMandatoryFieldIds(newMandatoryFieldIds) {
    if (!newMandatoryFieldIds.length) return;

    setMandatoryFieldIds(newMandatoryFieldIds);
  }

  function handleGetMadatoryFieldIds() {
    return mandatoryFieldIds;
  }

  function handleSetMandatoryHeaderFieldIds(newMandatoryHeaderFieldIds) {
    if (!newMandatoryHeaderFieldIds.length) return;

    setMandatoryHeaderFieldIds(newMandatoryHeaderFieldIds);
  }

  function handleGetMandatoryHeaderFieldIds() {
    return mandatoryHeaderFieldIds;
  }

  function handleSetMandatoryHierarchyInfoIds(newMandatoryHierarchyInfoIds) {
    if (!newMandatoryHierarchyInfoIds.length) return;

    setMandatoryHierarchyInfoIds(newMandatoryHierarchyInfoIds);
  }

  function handleGetMandatoryHierarchyInfoIds() {
    return mandatoryHierarchyInfoIds;
  }

  function handleDeleteCustomFieldValue(hashCode) {
    setCustomFieldValues((draft) => {
      delete draft[hashCode];
    });
  }

  function handleDeleteHeaderFieldValue(hashCode) {
    setHeaderFieldValues((draft) => {
      delete draft[hashCode];
    });
  }

  function handleGetLoadedExports() {
    return loadedExports;
  }

  function handleGetOldExportName() {
    return oldExportName;
  }

  function hanldeSetOldExportName(name) {
    setOldExportName(name);
  }

  function handleSetLoadedExports(newLoadedExports) {
    let exports = [];
    newLoadedExports.forEach((content) => {
      let exportName = content.exportDefinitionName;
      let cycle = content.cycle.label;
      let internUsers = '';
      let customers = '';
      let id = content.id;
      content.orgaUserReceivers.forEach((content) => {
        internUsers += content.label + '\n';
      });
      content.customerAddressReceivers.forEach((content) => {
        customers += content.label + '\n';
      });
      exports.push({ id, internUsers, customers, exportName, cycle });
    });
    setLoadedExports(exports);
  }

  function handleDeleteLoadedExportEntry(exportId) {
    loadedExports.forEach((content, index) => {
      if (content.id === exportId) {
        loadedExports.splice(index, 1);
        return;
      }
    });
  }

  function handleGetSpecificationList() {
    return specificationList;
  }

  function handleSetSpecificationList(newSpecList) {
    setSpecificationList(newSpecList);
  }

  function handleGetCustomSpecList() {
    return customSpecList;
  }

  function handleSetCustomSpecList(newCustomSpecList) {
    setCustomSpecList(newCustomSpecList);
  }

  function handleGetSpecVariantList() {
    return specVariantsList;
  }

  function handleSetSpecVariantList(newVariantList) {
    setSpecVariantsList(newVariantList);
  }

  function handleGetTabValue() {
    return tabValue;
  }

  function handleSetTabValue(value) {
    setTabValue(value);
  }

  function handleGetLoadingBmeCat() {
    return loadingBmeCat;
  }

  function handleSetLoadingBmeCat(loading) {
    setLoadingBmeCat(loading);
  }

  function handleGetColumns() {
    return columns;
  }

  function hanldeSetColumns(newColumns) {
    setColumns(newColumns);
  }

  function handleSetArticleChoice(choice) {
    setArticleChoice(choice);
  }

  function handleGetArticleChoice() {
    return articleChoice;
  }

  function isMandatoryFilled(arr, obj) {
    for (let k of arr)
      if (!(k.id in obj)) {
        return false;
      }
    return true;
  }

  function headerMandatoryErrors(arr, obj) {
    for (let k of arr)
      if (!(k.id in obj)) {
        handleSetHeaderErrorGroupIds(k.groupId, k.groupId);
      }
  }

  function hierarchyInfoMandatoryErrors(arr, obj) {
    for (let k of arr)
      if (!(k.id in obj)) {
        handleSetHierarchyInfoErrorGroupIds(k.groupId, k.groupId);
      }
  }

  function customMandatoryErrors(arr, obj) {
    for (let k of arr)
      if (!(k.id in obj)) {
        handleSetCustomErrorGroupIds(k.groupId, k.groupId);
      }
  }

  function handleGetExportId() {
    return exportId;
  }

  function handleGetExportDefId() {
    return exportDefinitionId;
  }

  function handleClearAfterChange() {
    resetErrors();
    setCustomFields([]);
    setHeaderFields([]);
    setHierarchyInfos([]);
    setCustomFieldState({});
    setCustomFieldValues({});
    setHeaderFieldState({});
    setHierarchyInfoState({});
    setHeaderFieldValues({});
    setHierarchyInfoValues({});
    setNullFeatureValueDefinitionInput('');
  }

  function handleResetAll() {
    resetErrors();
    setCustomFields([]);
    setHeaderFields([]);
    setHierarchyInfos([]);
    setCustomFieldState({});
    setCustomFieldValues({});
    setHeaderFieldState({});
    setHeaderFieldValues({});
    setHierarchyInfoState({});
    setHierarchyInfoValues({});
    setClassification('all');
    setNullFeatureValueDefinitionInput('');
    setArticleChoice(0);
    setArticleColumn('');
    setCatalogId('');
    setColumns([]);
    setCtxId('');
    setCustomerAdresses([]);
    setExportCycle('');
    setExportDefinitionId('');
    setExportId('');
    setExportName('');
    setFileName('');
    setInternUsers([]);
    setMandatoryFieldIds([]);
    setMandatoryHeaderFieldIds([]);
    setMandatoryHierarchyInfoIds([]);
    setMediaExportOption('');
    setProductStructure('');
    setSelectedSpec('');
    setSelectedVariant('');
    setCustomSpecDbId('');
    setShopId('');
    setSpecVariantsList([]);
    setSpecificationList([]);
    setCustomSpecList([]);
    setStartDateExport('');
    setWeekday('');
    setOldExportName('');
    setRegExErrorList([]);
  }

  function handleGetArticleChooseError() {
    return articleChooseError;
  }

  function handleSetArticleChooseError(value) {
    setArticleChooseError(value);
  }

  function handleGetBmeCatConfigError() {
    return bmeCatConfigError;
  }

  function handleSetBmeCatConfigError(value) {
    setBmeCatConfigError(value);
  }

  function handleGetHeaderFieldsError() {
    return headerFieldsError;
  }

  function handleSetHeaderFieldsError(value) {
    setHeaderFieldsError(value);
  }

  function handleGetHierarchyInfosError() {
    return hierarchyInfosError;
  }

  function handleSetHierarchyInfosError(value) {
    setHierarchyInfosError(value);
  }

  function handleGetCustomFieldsError() {
    return customFieldsError;
  }

  function handleSetCustomFieldsError(value) {
    setCustomFieldsError(value);
  }

  function handleGetExportConfError() {
    return exportConfError;
  }

  function handleSetExportConfError(value) {
    setExportConfError(value);
  }

  function handleSetSftpConnectionConfigurationId(id) {
    setSftpConnectionConfigurationId(id);
    if (id){
      setConnectionType('SFTP');
    }else{ 
      setConnectionType('fileDownload');
    } 
  }

  function handleGetSftpConnectionConfigurationId() {
    if (sftpConnectionConfigurationId){
      return sftpConnectionConfigurationId;
    }else {
      return "";
    }
  }

  function handleSetConnectionType(value) {
    setConnectionType(value);
  }

  function handleGetConnectionType() {
    return connectionType;
  }
  
  function handleSetSelectServerError(value) {
    setSelectServerError(value);
  }

  function handleGetSelectServerError() {
    return selectServerError;
  }
  

  function resetErrors() {
    setArticleChooseError(false);
    setBmeCatConfigError(false);
    setHeaderFieldsError(false);
    setHierarchyInfosError(false);
    setCustomFieldsError(false);
    setExportConfError(false);
    setSelectServerError(false);
    setHeaderErrorGroupIds({});
    setHierarchyInfoErrorGroupIds({});
    setCustomErrorGroupIds({});
    setRegExErrorList([]);
  }

  function handleGetRegExErrorList() {
    return regExErrorList;
  }

  function handleSetRegExErrorList(id) {
    let pos = regExErrorList.indexOf(id);
    if (pos == -1) {
      regExErrorList.push(id);
    }
  }

  function handleDeleteFromRegExErrorList(id) {
    let pos = regExErrorList.indexOf(id);
    if (pos != -1) {
      regExErrorList.splice(pos, 1);
    }
  }

  function handleSaveExport() {
    let url = 'exportSaveAll';

    resetErrors();

    if (ctxId) {
      url = url + '?ctx=' + ctxId;
    } else {
      let contextId = $('#react-BMEcatExportOverview').data('ctx-id');
      url = url + '?ctx=' + contextId;
    }

    let exportDefinitionData = {};
    let bmeCatSpecExportClientPojo = {};

    if (!catalogId && !productStructure && !articleColumn && !shopId) {
      exportDefinitionData.allArticlesExport = true;
    }

    if (articleChoice === 1 && !fileName) {
      TOGO.Util.notifyResponse(
        'Bitte wählen Sie eine Exceldatei aus und die Artikelnummer Spalte.',
        true
      );
      handleSetArticleChooseError(true);
      return;
    }

    if (articleColumn) {
      let mappingList = [];
      let mapping = {};
      mapping.sourceColumn = articleColumn;
      mapping.generalField = 'ARTICLE_NUMBER';
      mappingList.push(mapping);
      exportDefinitionData.mappingList = JSON.stringify(mappingList);
    } else if (fileName) {
      TOGO.Util.notifyResponse(
        'Bitte wählen Sie die Spalte für die Artikelnummer.',
        true
      );
      handleSetArticleChooseError(true);
      return;
    }

    if (articleChoice === 2 && !productStructure) {
      TOGO.Util.notifyResponse(
        'Bitte wählen Sie eine Produktstruktur aus.',
        true
      );
      handleSetArticleChooseError(true);
      return;
    }

    if (articleChoice === 3 && !catalogId) {
      TOGO.Util.notifyResponse('Bitte wählen Sie einen Katalog aus.', true);
      handleSetArticleChooseError(true);
      return;
    }

    if (articleChoice === 4 && !shopId) {
      TOGO.Util.notifyResponse('Bitte wählen Sie einen Shop aus.', true);
      handleSetArticleChooseError(true);
      return;
    }

    if (catalogId) {
      exportDefinitionData.catalogId = catalogId;
    } else if (productStructure) {
      exportDefinitionData.productStructurePath = productStructure;
    } else if (shopId) {
      exportDefinitionData.shopId = shopId;
    }

    if (exportName) {
      exportDefinitionData.exportDefinitionName = exportName;
    } else if (exportCycle != 'ONCE') {
      TOGO.Util.notifyResponse('Bitte setzten Sie einen Exportnamen.', true);
      handleSetExportConfError(true);
      return;
    }

    if (selectedSpec) {
      if (customSpecDbId) {
        bmeCatSpecExportClientPojo.customSpecDbId = customSpecDbId;
      } else {
        bmeCatSpecExportClientPojo.specificationName = selectedSpec;
      }
    } else {
      TOGO.Util.notifyResponse(
        'Bitte wählen Sie eine Spezifikation aus.',
        true
      );
      handleSetBmeCatConfigError(true);
      return;
    }

    // TO DO
    if (connectionType =="SFTP" && ( sftpConnectionConfigurationId == "" || sftpConnectionConfigurationId == null )) {
      TOGO.Util.notifyResponse('Bitte wählen Sie einen SFTP-Server aus.', true);
      handleSetSelectServerError(true);
      return;    }
    
    if (selectedVariant) {
      bmeCatSpecExportClientPojo.specificationVariant = selectedVariant;
    }
    if (!selectedVariant && specVariantsList && specVariantsList.length) {
      TOGO.Util.notifyResponse('Bitte wählen Sie eine Variante aus.', true);
      handleSetBmeCatConfigError(true);
      return;
    }

    
    if (nullFeatureValueDefinitionInput) {
      bmeCatSpecExportClientPojo.nullFeatureValueDefinition =
        nullFeatureValueDefinitionInput;
    }

    if (classification && classification != 'all') {
      bmeCatSpecExportClientPojo.classificationIdentifier = classification;
    }

    if (headerFieldValues) {
      bmeCatSpecExportClientPojo.headerFieldValues = headerFieldValues;
    }

    if (hierarchyInfoValues) {
      bmeCatSpecExportClientPojo.hierarchyInfoValues = hierarchyInfoValues;
    }

    if (customFieldValues) {
      bmeCatSpecExportClientPojo.articleDataCustomFieldValues =
        customFieldValues;
    }

    let data = {
      exportDefinition: exportDefinitionData,
      exportFormats: ['BMECAT'],
      bmeCatSpecExportClientPojo: bmeCatSpecExportClientPojo,
      contentLanguage: $('#contentLangSelectElement').val() || null
    };

    if (exportId && exportDefinitionId) {
      data.exportId = exportId;
      data.exportDefinitionId = exportDefinitionId;
    }

    if (internUsers) {
      data.orgaUserReceivers = internUsers;
    }

    if (customerAdresses) {
      data.customerAddressReceivers = customerAdresses;
    }

    if (connectionType) {
      data.connectionType = connectionType;
    }
    
    if (sftpConnectionConfigurationId) {
      data.sftpConnectionConfigurationId = sftpConnectionConfigurationId
    }

    if (connectionType !='SFTP'){
      data.sftpConnectionConfigurationId = null; 
    }

    if (mediaExportOption === 'noMedia') {
      data.mediaExportOption = false;
      data.allMediaExportOption = false;
    } else if (mediaExportOption === 'mappedMedia') {
      data.mediaExportOption = true;
      data.allMediaExportOption = false;
    } else if (mediaExportOption === 'allMedia') {
      data.mediaExportOption = true;
      data.allMediaExportOption = true;
    }

    if (exportCycle) {
      data.cycles = exportCycle;
    }

    if (weekday) {
      data.weekday = weekday;
    }

    if (startDateExport) {
      let date = startDateExport.split('-');
      let exportDate = {};
      exportDate.year = date[0];
      exportDate.month = date[1];
      exportDate.day = date[2];
      data.clientDate = exportDate;
    }

    let headerFieldsFilled = isMandatoryFilled(
      mandatoryHeaderFieldIds,
      headerFieldValues
    );
    let hierarchyInfosFilled = isMandatoryFilled(
      mandatoryHierarchyInfoIds,
      hierarchyInfoValues
    );
    let customFieldsFilled = isMandatoryFilled(
      mandatoryFieldIds,
      customFieldValues
    );

    if (!headerFieldsFilled) {
      handleSetHeaderFieldsError(true);
      headerMandatoryErrors(mandatoryHeaderFieldIds, headerFieldValues);
    }
    if (!hierarchyInfosFilled) {
      handleSetHierarchyInfosError(true);
      hierarchyInfoMandatoryErrors(
        mandatoryHierarchyInfoIds,
        hierarchyInfoValues
      );
    }
    if (!customFieldsFilled) {
      handleSetCustomFieldsError(true);
      customMandatoryErrors(mandatoryFieldIds, customFieldValues);
    }

    if (headerFieldsFilled && hierarchyInfosFilled && customFieldsFilled) {
      handleSetLoadingBmeCat(true);
      ajaxPost(url, null, data).then((ajaxData) => {
        let msg = ajaxData.response?.message || ajaxData.error;
        TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
        if (ajaxData.error) {
          if (
            ajaxData.httpRequestResponseText.responseText.indexOf(
              'Invalid context'
            ) !== -1
          ) {
            if (fileName) {
              TOGO.popupManager.zebraInfo(
                TOGO.ArticleDataMessages.exportReupload
              );
            } else {
              ajaxGet('getNewCtxId', {}).then((ajaxData) => {
                if (!ajaxData.error && ajaxData.response) {
                  let ctxId = ajaxData.response.ctxId;
                  if (ctxId) {
                    setCtxId(ctxId);
                  }
                  TOGO.popupManager.zebraInfo(
                    'Context-Id wurde erneuert. Bitte speichern Sie erneut.'
                  );
                }
              });
            }
            handleSetLoadingBmeCat(false);
            return;
          } else {
            handleSetLoadingBmeCat(false);
            return;
          }
        } else {
          let contentLang = $('#contentLangSelectElement').val() || null;
          ajaxGet('getSavedBMECatExports', {
            contentLanguage: contentLang
          }).then((ajaxData) => {
            if (!ajaxData.error && ajaxData.response) {
              let exports = ajaxData.response.exportPojos;
              handleSetLoadedExports(exports);
            } else {
              handleSetLoadingBmeCat(false);
            }
          });
          handleSetLoadingBmeCat(false);
          if (exportCycle !== 'ONCE') {
            let exportDefId = ajaxData.response.newExportDefinitionId;
            let exportConfigurationId = ajaxData.response.overviewItems[0].id;
            let oldExportName =
              ajaxData.response.overviewItems[0].exportDefinitionName;
            setExportDefinitionId(exportDefId);
            setExportId(exportConfigurationId);
            setOldExportName(oldExportName);
          }
        }
      });

      if (exportCycle == 'ONCE') {
        let msg =
          'Der einmalige Export wird erstellt. Wenn der Vorgang abgeschlossen ist, erhält der Empfänger eine E-Mail mit dem Link zum Download.';
        TOGO.popupManager.zebraInfo(msg);
      }
    } else {
      TOGO.Util.notifyResponse(
        'Pflichtfelder bitte ausfüllen. Export wurde nicht gespeichert.',
        true
      );
    }
  }

  function handleEditExport(exportData) {
    let exportConfiguration = exportData.exportConfiguration;
    let exportDefinition = exportData.exportDefinition;
    let bmeCatSpecConfig = exportDefinition.bmeCatSpecExportData;
    let productStructurePath = exportDefinition.productStructurePath;
    let shopId = exportDefinition.shopId;
    let catalogId = exportDefinition.catalogId;
    let customSpecDb = bmeCatSpecConfig.customSpecDbId;
    let specification;
    if (customSpecDb) {
      specification = customSpecDb;
    } else {
      specification = bmeCatSpecConfig.specificationName;
    }
    let variant = bmeCatSpecConfig.specificationVariant;
    let nullFeatureValueDefinition =
      bmeCatSpecConfig.nullFeatureValueDefinition;
    let classificationIdentifier = bmeCatSpecConfig.classificationIdentifier;
    let headerFieldValues = exportData.headerFieldValues;
    let hierarchyInfoValues = exportData.hierarchyInfoValues;
    let articleDataCustomFields = bmeCatSpecConfig.articleDataCustomFields;
    let exportName = exportDefinition.name;
    let orgaUserReceivers = exportConfiguration.orgaUserReceivers;
    let customerAddressReceivers = exportConfiguration.customerAddressReceivers;
    let mediumExport = exportConfiguration.mediumExport;
    let allMediaExport = exportConfiguration.allMediaExport;
    let cycles = exportConfiguration.cycles;
    let id = exportConfiguration.id;
    let definitionId = exportDefinition.id;
    let weekday = exportData.weekday;
    let startDate = exportData.startDate;
    let sftpConnectionConfigurationId = exportDefinition.sftpConnectionConfigurationId;
    let xlsxResult = exportData.xlsxResult;


    if (startDate) {
      let startDateString =
        startDate.year + '-' + startDate.month + '-' + startDate.dayOfMonth;
      setStartDateExport(startDateString);
    }

    if (weekday) {
      if (weekday.value) {
        handleSetWeekday(weekday.value);
      }
    }

    if (xlsxResult) {
      handleSetFileName(xlsxResult.fileName);
      let map = xlsxResult.map;
      hanldeSetColumns(map);
      let exportColumnFeatureMappings =
        exportDefinition.exportColumnFeatureMappings;
      exportColumnFeatureMappings.forEach((content) => {
        if (content.generalField && content.generalField === 'ARTICLE_NUMBER') {
          setArticleColumn(content.sourceColumn.toString());
        }
      });
      setArticleChoice(1);
    }

    let adresses = [];
    if (customerAddressReceivers) {
      customerAddressReceivers.forEach((content) => {
        adresses.push(content.id);
      });
    }

    setExportId(id);
    setExportDefinitionId(definitionId);
    handleSetExportCycle(cycles);
    handleSetCustomerAdresses(adresses);
    handleSetInternUsers(orgaUserReceivers);
    handleSetExportName(exportName);
    handleSetClassification(classificationIdentifier);
    handleSetCatalogId(catalogId);
    handleSetProductStructure(productStructurePath);
    handleSetShopId(shopId);
    handleSetSelectedSpec(specification);
    handleSetCustomSpecDbId(customSpecDb);
    handleSetSelectedVariant(variant);
    hanldeSetOldExportName(exportName);
    handleSetSftpConnectionConfigurationId(sftpConnectionConfigurationId)

    if (!xlsxResult) {
      if (catalogId) {
        setArticleChoice(3);
      } else if (productStructurePath) {
        setArticleChoice(2);
      } else if (shopId) {
        setArticleChoice(4);
      }
    }

    if (!mediumExport && !allMediaExport) {
      handleSetMediaExportOption('noMedia');
    } else if (mediumExport && !allMediaExport) {
      handleSetMediaExportOption('mappedMedia');
    } else if (mediumExport && allMediaExport) {
      handleSetMediaExportOption('allMedia');
    }

    handleSetLoadingBmeCat(true);
    let contentLang = $('#contentLangSelectElement').val() || null;
    if (variant) {
      ajaxGet('getExportSpecificationVariants', {
        exportSpecificationId: specification,
        contentLanguage: contentLang
      }).then((ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          let model = ajaxData.response.exportSpecifications;
          handleSetSpecVariantList(model);
        }
      });
    }
    if (customSpecDb) {
      specification = null;
    }

    ajaxGet('getHeaderAndFields', {
      exportSpecificationId: specification,
      exportSpecificationVariantId: variant,
      customSpecDbId: customSpecDb
    }).then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        let headerFields = ajaxData.response.headerFields;
        let hierarchyInfos = ajaxData.response.hierarchyInfos;
        let customFields = ajaxData.response.customFields;
        handleSetCustomFields(customFields);
        handleSetHeaderFields(headerFields);
        handleSetHierarchyInfos(hierarchyInfos);

        settingLoadedDataFields(
          headerFields,
          headerFieldValues,
          DATA_FIELD_TYPES.header
        );

        settingLoadedDataFields(
          hierarchyInfos,
          hierarchyInfoValues,
          DATA_FIELD_TYPES.hierarchyInfo
        );

        settingLoadedDataFields(
          customFields,
          articleDataCustomFields,
          DATA_FIELD_TYPES.customField
        );
      }
      handleSetLoadingBmeCat(false);
    });

    handleSetNullFeatureValueDefinitionInput(nullFeatureValueDefinition);
    handleSetTabValue(0);
    TOGO.Util.notifyResponse('Export ' + exportName + ' wurde geladen.', false);
  }

  function settingLoadedDataFields(dataFields, loadedValues, dataFieldType) {
    let mandatoryFields = [];
    let cachedDataFieldValues = {};
    let cachedDataFieldStates = {};

    dataFields.forEach((content) => {
      let hashCode = content.keyHashCode;
      let mandatory = content.mandatory;
      if (mandatory) {
        let mandatoryField = {};
        mandatoryField.id = hashCode;
        mandatoryField.groupId = content.groupId;
        mandatoryFields.push(mandatoryField);
      }

      if (
        content.optionValues &&
        Object.keys(content.optionValues).length > 0
      ) {
        let optionModels = content.optionValues;
        let multiSelection = [];
        let selection = [];

        let dataFieldValue = null;
        if (loadedValues) {
          dataFieldValue = loadedValues[hashCode];
        } else {
          //TODO: load default values for selections:
        }

        for (let optionKey in optionModels) {
          let optionValue = optionModels[optionKey];
          let selected = false;
          if (dataFieldValue) {
            if (dataFieldValue.featureValueIdentifier) {
              if (optionKey === dataFieldValue.featureValueIdentifier) {
                selected = true;
                let singleSelection = {};

                singleSelection.selectionValue = optionKey;
                singleSelection.label = optionValue;
                singleSelection.addToValueList = false;
                singleSelection.addedSelectionOption = false;
                cachedDataFieldValues[hashCode] = {
                  singleSelection: singleSelection
                };
              }
            } else if (dataFieldValue.selections) {
              let selections = dataFieldValue.selections;

              selections.forEach((content) => {
                if (content.featureValueIdentifier) {
                  if (optionKey === content.featureValueIdentifier) {
                    selected = true;
                    let selection = {};

                    selection.selectionValue = optionKey;
                    selection.label = optionValue;
                    selection.addToValueList = false;
                    selection.addedSelectionOption = false;

                    multiSelection.push(selection);
                  }
                }
              });
            }
          }

          let selectionItem = {
            selectionValue: optionKey,
            addToValueList: false,
            addedSelectionOption: false,
            label: optionValue,
            selected: selected
          };
          selection.push(selectionItem);
        }

        if (multiSelection.length) {
          cachedDataFieldValues[hashCode] = {
            multiSelection: multiSelection
          };
        }

        let value = {};

        value.selected = selection
          .filter((option) => option.selected)
          .map(({ selected, ...optionProps }) => optionProps);

        selection = selection.map(
          ({ selected, ...optionProps }) => optionProps
        );

        value.customOptions = selection.filter(
          (option) => option.addedSelectionOption
        );

        const standardOptions = selection.filter(
          (option) => !option.addedSelectionOption
        );

        value.standardOptions = standardOptions;
        cachedDataFieldStates[hashCode] = value;
      } else {
        let dataFieldValue = null;
        if (loadedValues) {
          dataFieldValue = loadedValues[hashCode];
        } else {
          if (content.defaultValueAlphanumeric) {
            dataFieldValue = content.defaultValueAlphanumeric;
          }
          if (content.defaultValueNumeric) {
            dataFieldValue = content.defaultValueNumeric;
          }
        }

        if (dataFieldValue) {
          let value = dataFieldValue.value;
          if (!value && dataFieldValue.languageValues) {
            const contentLang = $('#contentLangSelectElement').val() || null;
            value = dataFieldValue.languageValues[contentLang];
          }

          let contentForSet = null;
          switch (content.featureType) {
            case 'FEATURE_TYPE_BOOLEAN':
              contentForSet = { booleanValue: value };
              break;
            case 'FEATURE_TYPE_NUMERIC':
              contentForSet = { numericValue: value };
              break;
            case 'FEATURE_TYPE_LOCAL_DATE':
              contentForSet = { dateValue: value };
              break;
            case 'FEATURE_TYPE_ALPHANUMERIC_NON_LOCALIZED':
            case 'FEATURE_TYPE_ALPHANUMERIC':
              contentForSet = { alphaNumericValue: value };
              break;
          }

          cachedDataFieldValues[hashCode] = contentForSet;
          cachedDataFieldStates[hashCode] = value;
        }
      }
    });

    switch (dataFieldType) {
      case DATA_FIELD_TYPES.header: {
        handleSetHeaderFieldValuesBulk(cachedDataFieldValues);
        handleSetHeaderFieldStatesBulk(cachedDataFieldStates);
        handleSetMandatoryHeaderFieldIds(mandatoryFields);
        break;
      }
      case DATA_FIELD_TYPES.hierarchyInfo: {
        handleSetHierarchyInfoValuesBulk(cachedDataFieldValues);
        handleSetHierarchyInfoStatesBulk(cachedDataFieldStates);
        handleSetMandatoryHierarchyInfoIds(mandatoryFields);
        break;
      }
      case DATA_FIELD_TYPES.customField: {
        handleSetCustomFieldValuesBulk(cachedDataFieldValues);
        handleSetCustomFieldStatesBulk(cachedDataFieldStates);
        handleSetMandatoryFieldIds(mandatoryFields);
        break;
      }
    }
  }

  return {
    getProductStructure: handleGetProductStructure,
    setProductStructure: handleSetProductStructure,
    getShopId: handleGetShopId,
    setShopId: handleSetShopId,
    getCatalogId: handleGetCatalogId,
    setCatalogId: handleSetCatalogId,
    getCtxId: handleGetCtxId,
    setCtxId: handleSetCtxId,
    getFileName: handleGetFileName,
    setFileName: handleSetFileName,
    getArticleColumn: handleGetArticleColumn,
    setArticleColumn: handleSetArticleColumn,
    getInternUsers: handleGetInternUsers,
    setInternUsers: handleSetInternUsers,
    getCustomerAdresses: handleGetCustomerAdresses,
    setCustomerAdresses: handleSetCustomerAdresses,
    getMediaExportOption: handleGetMediaExportOption,
    setMediaExportOption: handleSetMediaExportOption,
    getExportCycle: handleGetExportCycle,
    setExportCycle: handleSetExportCycle,
    getWeekday: handleGetWeekday,
    setWeekday: handleSetWeekday,
    getStartDateExport: handleGetStartDateExport,
    setStartDateExport: handleSetStartDateExport,
    getSelectedSpec: handleGetSelectedSpec,
    setSelectedSpec: handleSetSelectedSpec,
    getSelectedVariant: handleGetSelectedVariant,
    setSelectedVariant: handleSetSelectedVariant,
    getClassification: handleGetClassification,
    setClassification: handleSetClassification,
    getNullFeatureValueDefinitionInput:
      handleGetNullFeatureValueDefinitionInput,
    setNullFeatureValueDefinitionInput:
      handleSetNullFeatureValueDefinitionInput,
    getHeaderFields: handleGetHeaderFields,
    setHeaderFields: handleSetHeaderFields,
    getCustomFields: handleGetCustomFields,
    setCustomFields: handleSetCustomFields,
    getHeaderFieldValues: handleGetHeaderFieldValues,
    setHeaderFieldValues: handleSetHeaderFieldValues,
    getHierarchyInfoValues: handleGetHierarchyInfoValues,
    setHierarchyInfoValues: handleSetHierarchyInfoValues,
    getHierarchyInfos: handleGetHierarchyInfos,
    setHierarchyInfos: handleSetHierarchyInfos,
    getHeaderFieldState: handleGetHeaderFieldState,
    setHeaderFieldState: handleSetHeaderFieldState,
    getHierarchyInfoState: handleGetHierarchyInfoState,
    setHierarchyInfoState: handleSetHierarchyInfoState,
    getCustomFieldValues: handleGetCustomFieldValues,
    setCustomFieldValues: handleSetCustomFieldValues,
    getCustomFieldState: handleGetCustomFieldState,
    setCustomFieldState: handleSetCustomFieldState,
    getExportName: handleGetExportName,
    setExportName: handleSetExportName,
    saveExport: handleSaveExport,
    setMandatoryFieldIds: handleSetMandatoryFieldIds,
    getMandatoryFieldIds: handleGetMadatoryFieldIds,
    setMandatoryHeaderFieldIds: handleSetMandatoryHeaderFieldIds,
    getMandatoryHierarchyInfoIds: handleGetMandatoryHierarchyInfoIds,
    setMandatoryHierarchyInfoIds: handleSetMandatoryHierarchyInfoIds,
    getMandatoryHeaderFieldIds: handleGetMandatoryHeaderFieldIds,
    deleteCustomFieldValue: handleDeleteCustomFieldValue,
    deleteHeaderFieldValue: handleDeleteHeaderFieldValue,
    getLoadedExports: handleGetLoadedExports,
    setLoadedExports: handleSetLoadedExports,
    deleteLoadedExportEntry: handleDeleteLoadedExportEntry,
    setEditExport: handleEditExport,
    settingLoadedDataFields,
    getSpecificationList: handleGetSpecificationList,
    setSpecificationList: handleSetSpecificationList,
    getSpecVariantList: handleGetSpecVariantList,
    setSpecVariantList: handleSetSpecVariantList,
    getTabValue: handleGetTabValue,
    setTabValue: handleSetTabValue,
    getLoadingBmeCat: handleGetLoadingBmeCat,
    setLoadingBmeCat: handleSetLoadingBmeCat,
    getColumns: handleGetColumns,
    setColumns: hanldeSetColumns,
    getExportDefId: handleGetExportDefId,
    getExportId: handleGetExportId,
    getArticleChoice: handleGetArticleChoice,
    setArticleChoice: handleSetArticleChoice,
    clearAfterChange: handleClearAfterChange,
    resetAll: handleResetAll,
    getOldExportName: handleGetOldExportName,
    setOldExportName: hanldeSetOldExportName,
    getArticleChooseError: handleGetArticleChooseError,
    setArticleChooseError: handleSetArticleChooseError,
    getBmeCatConfigError: handleGetBmeCatConfigError,
    setBmeCatConfigError: handleSetBmeCatConfigError,
    getHeaderFieldsError: handleGetHeaderFieldsError,
    setHeaderFieldsError: handleSetHeaderFieldsError,
    getHierarchyInfosError: handleGetHierarchyInfosError,
    setHierarchyInfosError: handleSetHierarchyInfosError,
    getCustomFieldsError: handleGetCustomFieldsError,
    setCustomFieldsError: handleSetCustomFieldsError,
    getExportConfError: handleGetExportConfError,
    setExportConfError: handleSetExportConfError,
    getHeaderErrorGroupIds: handleGetHeaderErrorGroupIds,
    getHierarchyInfoErrorGroupIds: handleGetHierarchyInfoErrorGroupIds,
    getCustomErrorGroupIds: handleGetCustomErrorGroupIds,
    getCustomSpecList: handleGetCustomSpecList,
    setCustomSpecList: handleSetCustomSpecList,
    setCustomSpecDbId: handleSetCustomSpecDbId,
    getCustomSpecDbId: handleGetCustomSpecDbId,
    getRegExErrorList: handleGetRegExErrorList,
    setRegExErrorList: handleSetRegExErrorList,
    deleteFromRegExErrorList: handleDeleteFromRegExErrorList,
    setSftpConnectionConfigurationId:handleSetSftpConnectionConfigurationId,
    getSftpConnectionConfigurationId:handleGetSftpConnectionConfigurationId,
    getConnectionType :handleGetConnectionType,
    setConnectionType:handleSetConnectionType,
    getSelectServerError:handleGetSelectServerError,
    setSelectServerError:handleSetSelectServerError,
    getContentLang: () => contentLang,
    setContentLang: setContentLang
  };
};

export { ExportProvider, useExport };
