import React from 'react';

import {default as MuiAccordion}from '@material-ui/core/Accordion';
import {default as MuiAccordionSummary}from '@material-ui/core/AccordionSummary';
import {default as MuiAccordionDetails}from '@material-ui/core/AccordionDetails';

export const AccordionDisplay = ({ nodeKey, accordionTitle, background, padding, children }) => {

  return (
      <MuiAccordion
        style={{ margin: '5px 0', background }}
      >
        <MuiAccordionSummary
          expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
          aria-controls={`${nodeKey}-content`}
          id={`${nodeKey}-header`}
        >
          <p>{accordionTitle}</p>
        </MuiAccordionSummary>
        <MuiAccordionDetails
          style={{ background: "white", display: "flow-root", padding: `${padding}px` }}
        >
          {children} 
        </MuiAccordionDetails>
      </MuiAccordion>
  );
};

