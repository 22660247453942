import React from 'react';
import { Element, useNode } from '@craftjs/core';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import {
  Paper,
  FormControl,
  FormLabel,
  Grid,
  Box,
  Slider
} from '@material-ui/core';

import { Container } from './Container';
import { Title } from './Title';

export const CardPart = ({ children }) => {
  const {
    connectors: { connect }
  } = useNode();
  return (
    <div
      ref={connect}
      className="CardPart"
      style={{
        padding: '10px',
        marginBottom: '10px',
        borderBottom: '1px solid #eee',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}
    >
      {children}
    </div>
  );
};
CardPart.propTypes = {
  children: PropTypes.any
};
CardPart.craft = {
  rules: {
    canMoveIn: (incomingNode, currentNode) => {
      // return incomingNode.data.type === Title && currentNode.data.nodes.length < 1
      return currentNode.data.nodes.length < 5;
    }
  }
};

export const Card = ({ parts, background, padding = 20 }) => {
  return (
    <>
      <Container background={background} padding={padding}>
        <AppBar
          position="static"
          style={{
            color: 'blue'
          }}
        ></AppBar>
        {parts ? (
          <>
            {parts.map((item) => (
              <Element canvas is={CardPart} id={item.id + 'text'} key={item.id}>
                <Title text={item.id + 'title'} fontSize={20} />
              </Element>
            ))}
          </>
        ) : null}
      </Container>
    </>
  );
};
Card.propTypes = {
  parts: PropTypes.any,
  background: PropTypes.any,
  padding: PropTypes.any
};

export const CardSettings = () => {
  const {
    background,
    padding,
    actions: { setProp }
  } = useNode((node) => ({
    background: node.data.props.background,
    padding: node.data.props.padding
  }));

  return (
    <>
      <Grid item>
        <Paper style={{ marginBottom: '20px' }}>
          <FormControl size="small" component="fieldset">
            <FormLabel component="legend">Bearbeiten</FormLabel>
            <button
              className="btn btn-success iconButton"
              onClick={() => {
                setProp((props) => props.parts.push({ id: '4' }));

                //setList(list.concat(value));
              }}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              <span>Hinzufügen</span>
            </button>
          </FormControl>
        </Paper>
      </Grid>
      <Grid item>
        <Paper style={{ marginBottom: '20px' }}>
          <FormControl fullWidth={true} margin="normal" component="fieldset">
            <FormLabel component="legend">Padding</FormLabel>
            <Slider
              defaultValue={padding}
              onChange={(_, value) =>
                setProp((props) => (props.padding = value), 500)
              }
            />
          </FormControl>
        </Paper>
      </Grid>
    </>
  );
};
CardSettings.propTypes = {
  parts: PropTypes.any
};
export const CardDefaultProps = {
  parts: [{ id: '1' }, { id: '2' }, { id: '3' }],
  background: '#ffffff',
  padding: 3
};
Card.craft = {
  props: CardDefaultProps,
  related: {
    settings: CardSettings
  }
};
