export const countries = {
	de: {

		"AX": "Aaland-Inseln",
		"AF": "Afghanistan",
		"EG": "Ägypten",
		"AL": "Albanien",
		"DZ": "Algerien",
		"VI": "Amerikanische Jungferninseln",
		"AS": "Amerikanisch-Samoa",
		"AD": "Andorra",
		"AO": "Angola",
		"AI": "Anguilla",
		"AQ": "Antarktis",
		"AG": "Antigua und Barbuda",
		"GQ": "Äquatorial-Guinea",
		"AR": "Argentinien",
		"AM": "Armenien",
		"AW": "Aruba",
		"AZ": "Aserbaidschan",
		"ET": "Äthiopien",
		"AU": "Australien",
		"BS": "Bahamas",
		"BH": "Bahrain",
		"BD": "Bangladesch",
		"BB": "Barbados",
		"BY": "Belarus",
		"BE": "Belgien",
		"BZ": "Belize",
		"BJ": "Benin",
		"BM": "Bermuda",
		"BT": "Bhutan",
		"BO": "Bolivien",
		"BQ": "Bonaire",
		"BA": "Bosnien und Herzegowina",
		"BW": "Botsuana",
		"BV": "Bouvet-Insel",
		"BR": "Brasilien",
		"VG": "Britische Jungferninseln",
		"IO": "Britische Territorien im Indischen Ozean",
		"BN": "Brunei",
		"BG": "Bulgarien",
		"BF": "Burkina Faso",
		"BI": "Burundi",
		"CL": "Chile",
		"CN": "China",
		"CK": "Cook-Inseln",
		"CR": "Costa Rica",
		"CW": "Curaçao",
		"DK": "Dänemark",
		"CD": "Demokratische Republik Kongo",
		"DE": "Deutschland",
		"DM": "Dominica",
		"DO": "Dominikanische Republik",
		"DJ": "Dschibuti",
		"EC": "Ecuador",
		"CI": "Elfenbeinküste",
		"SV": "El Salvador",
		"ER": "Eritrea",
		"EE": "Estland",
		"FK": "Falkland-Inseln",
		"FO": "Färöer-Inseln",
		"FJ": "Fidschi",
		"FI": "Finnland",
		"FR": "Frankreich",
		"TF": "Französische Südgebiete",
		"GF": "Französisch-Guayana",
		"PF": "Französisch-Polynesien",
		"GA": "Gabun",
		"GM": "Gambia",
		"GE": "Georgien",
		"GH": "Ghana",
		"GI": "Gibraltar",
		"GD": "Grenada",
		"GR": "Griechenland",
		"GL": "Grönland",
		"GP": "Guadeloupe",
		"GU": "Guam",
		"GT": "Guatemala",
		"GG": "Guernsey",
		"GN": "Guinea",
		"GW": "Guinea-Bissau",
		"GY": "Guyana",
		"HT": "Haiti",
		"HM": "Heard- und McDonald-Inseln",
		"HN": "Honduras",
		"HK": "Hongkong",
		"IN": "Indien",
		"ID": "Indonesien",
		"IQ": "Irak",
		"IR": "Iran",
		"IE": "Irland",
		"IS": "Island",
		"IM": "Isle of Man",
		"IL": "Israel",
		"IT": "Italien",
		"JM": "Jamaika",
		"JP": "Japan",
		"YE": "Jemen",
		"JE": "Jersey",
		"JO": "Jordanien",
		"KY": "Kaiman-Inseln",
		"KH": "Kambodscha",
		"CM": "Kamerun",
		"CA": "Kanada",
		"CV": "Kap Verde",
		"KZ": "Kasachstan",
		"QA": "Katar",
		"KE": "Kenia",
		"KG": "Kirgistan",
		"KI": "Kiribati",
		"CC": "Kokos-Inseln",
		"CO": "Kolumbien",
		"KM": "Komoren",
		"CG": "Kongo",
		"HR": "Kroatien",
		"CU": "Kuba",
		"KW": "Kuwait",
		"LA": "Laos",
		"LS": "Lesotho",
		"LV": "Lettland",
		"LB": "Libanon",
		"LR": "Liberia",
		"LY": "Libyen",
		"LI": "Liechtenstein",
		"LT": "Litauen",
		"LU": "Luxemburg",
		"MO": "Macao",
		"MG": "Madagaskar",
		"MW": "Malawi",
		"MY": "Malaysia",
		"MV": "Maldiven",
		"ML": "Mali",
		"MT": "Malta",
		"MA": "Marokko",
		"MH": "Marshall-Inseln",
		"MQ": "Martinique",
		"MR": "Mauretanien",
		"MU": "Mauritius",
		"YT": "Mayotte",
		"MK": "Mazedonien",
		"MX": "Mexiko",
		"FM": "Mikronesien",
		"MD": "Moldau",
		"MC": "Monaco",
		"MN": "Mongolei",
		"ME": "Montenegro",
		"MS": "Montserrat",
		"MZ": "Mosambik",
		"MM": "Myanmar",
		"NA": "Namibia",
		"NR": "Nauru",
		"NP": "Nepal",
		"NC": "Neukaledonien",
		"NZ": "Neuseeland",
		"NI": "Nicaragua",
		"NL": "Niederlande",
		"AN": "Niederländische Antillen",
		"NE": "Niger",
		"NG": "Nigeria",
		"NU": "Niue",
		"KP": "Nordkorea",
		"MP": "Nördliche Mariannen-Inseln",
		"NF": "Norfolk-Insel",
		"NO": "Norwegen",
		"OM": "Oman",
		"AT": "Österreich",
		"PK": "Pakistan",
		"PS": "Palästina",
		"PW": "Palau",
		"PA": "Panama",
		"PG": "Papua-Neuguinea",
		"PY": "Paraguay",
		"PE": "Peru",
		"PH": "Philippinen",
		"PN": "Pitcairn",
		"PL": "Polen",
		"PT": "Portugal",
		"PR": "Puerto Rico",
		"RE": "Reunion",
		"RW": "Ruanda",
		"RO": "Rumänien",
		"RU": "Russland",
		"BL": "Saint Barthélemy",
		"KN": "Saint Kitts und Nevis",
		"ZM": "Sambia",
		"WS": "Samoa",
		"SM": "San Marino",
		"ST": "Sao Tome und Principe",
		"SA": "Saudi-Arabien",
		"SE": "Schweden",
		"CH": "Schweiz",
		"SN": "Senegal",
		"RS": "Serbien",
		"SC": "Seychellen",
		"SL": "Sierra Leone",
		"ZW": "Simbabwe",
		"SG": "Singapur",
		"SX": "Sint Maarten (Niederländischer Teil)",
		"SK": "Slowakei",
		"SI": "Slowenien",
		"SB": "Solomon-Inseln",
		"SO": "Somalia",
		"ES": "Spanien",
		"LK": "Sri Lanka",
		"SH": "St. Helena",
		"LC": "St. Lucia",
		"MF": "St. Martin",
		"PM": "St. Pierre und Miquelon",
		"VC": "St. Vincent und die Grenadinen",
		"ZA": "Südafrika",
		"SD": "Sudan",
		"GS": "Süd-Georgia und die südlichen Sandwich-Inseln",
		"KR": "Südkorea",
		"SS": "Südsudan",
		"SR": "Suriname",
		"SJ": "Svalbard und Jan Mayen",
		"SZ": "Swasiland",
		"SY": "Syrien",
		"TJ": "Tadschikistan",
		"TW": "Taiwan",
		"TZ": "Tansania",
		"TH": "Thailand",
		"TL": "Timor-Leste",
		"TG": "Togo",
		"TK": "Tokelau",
		"TO": "Tonga",
		"TT": "Trinidad und Tobago",
		"TD": "Tschad",
		"CZ": "Tschechische Republik",
		"TN": "Tunesien",
		"TR": "Türkei",
		"TM": "Turkmenistan",
		"TC": "Turks- und Caicos-Inseln",
		"TV": "Tuvalu",
		"UM": "Übrige Inseln im Pazifik der USA",
		"UG": "Uganda",
		"UA": "Ukraine",
		"HU": "Ungarn",
		"UY": "Uruguay",
		"UZ": "Usbekistan",
		"VU": "Vanuatu",
		"VA": "Vatikanstadt",
		"VE": "Venezuela",
		"AE": "Vereinigte Arabische Emirate",
		"GB": "Vereinigtes Königreich",
		"US": "Vereinigte Staaten von Amerika",
		"VN": "Vietnam",
		"WF": "Wallis und Futuna",
		"CX": "Weihnachtsinsel",
		"EH": "Westsahara",
		"CF": "Zentralafrikanische Republik",
		"CY": "Zypern"
	},
	en: {
		"AD": "Andorra",
		"AE": "United Arab Emirates",
		"AF": "Afghanistan",
		"AG": "Antigua and Barbuda ",
		"AI": "Anguilla ",
		"AL": "Albania ",
		"AM": "Armenia ",
		"AN": "Netherlands Antilles ",
		"AO": "Angola ",
		"AQ": "Antarctica ",
		"AR": "Argentina ",
		"AS": "American Samoa ",
		"AT": "Austria ",
		"AU": "Australia ",
		"AW": "Aruba ",
		"AX": "Åland Islands ",
		"AZ": "Azerbaijan ",
		"BA": "Bosnia and Herzegovina ",
		"BB": "Barbados ",
		"BD": "Bangladesh ",
		"BE": "Belgium ",
		"BF": "Burkina Faso ",
		"BG": "Bulgaria ",
		"BH": "Bahrain ",
		"BI": "Burundi ",
		"BJ": "Benin ",
		"BL": "Saint Barthélemy ",
		"BM": "Bermuda ",
		"BN": "Brunei ",
		"BO": "Bolivia ",
		"BQ": "Bonaire, Sint Eustatius and Saba ",
		"BR": "Brazil ",
		"BS": "Bahamas ",
		"BT": "Bhutan ",
		"BV": "Bouvet Island ",
		"BW": "Botswana ",
		"BY": "Belarus ",
		"BZ": "Belize ",
		"CA": "Canada ",
		"CC": "Cocos Islands ",
		"CD": "The Democratic Republic Of Congo ",
		"CF": "Central African Republic ",
		"CG": "Congo ",
		"CH": "Switzerland ",
		"CI": "Côte d'Ivoire ",
		"CK": "Cook Islands ",
		"CL": "Chile ",
		"CM": "Cameroon ",
		"CN": "China ",
		"CO": "Colombia ",
		"CR": "Costa Rica ",
		"CU": "Cuba ",
		"CV": "Cape Verde ",
		"CW": "Curaçao ",
		"CX": "Christmas Island ",
		"CY": "Cyprus ",
		"CZ": "Czech Republic ",
		"DE": "Germany ",
		"DJ": "Djibouti ",
		"DK": "Denmark ",
		"DM": "Dominica ",
		"DO": "Dominican Republic ",
		"DZ": "Algeria ",
		"EC": "Ecuador ",
		"EE": "Estonia ",
		"EG": "Egypt ",
		"EH": "Western Sahara ",
		"ER": "Eritrea ",
		"ES": "Spain ",
		"ET": "Ethiopia ",
		"FI": "Finland ",
		"FJ": "Fiji ",
		"FK": "Falkland Islands ",
		"FM": "Micronesia ",
		"FO": "Faroe Islands ",
		"FR": "France ",
		"GA": "Gabon ",
		"GB": "United Kingdom ",
		"GD": "Grenada ",
		"GE": "Georgia ",
		"GF": "French Guiana ",
		"GG": "Guernsey ",
		"GH": "Ghana ",
		"GI": "Gibraltar ",
		"GL": "Greenland ",
		"GM": "Gambia ",
		"GN": "Guinea ",
		"GP": "Guadeloupe ",
		"GQ": "Equatorial Guinea ",
		"GR": "Greece ",
		"GS": "South Georgia And The South Sandwich Islands ",
		"GT": "Guatemala ",
		"GU": "Guam ",
		"GW": "Guinea-Bissau ",
		"GY": "Guyana ",
		"HK": "Hong Kong ",
		"HM": "Heard Island And McDonald Islands ",
		"HN": "Honduras ",
		"HR": "Croatia ",
		"HT": "Haiti ",
		"HU": "Hungary ",
		"ID": "Indonesia ",
		"IE": "Ireland ",
		"IL": "Israel ",
		"IM": "Isle Of Man ",
		"IN": "India ",
		"IO": "British Indian Ocean Territory ",
		"IQ": "Iraq ",
		"IR": "Iran ",
		"IS": "Iceland ",
		"IT": "Italy ",
		"JE": "Jersey ",
		"JM": "Jamaica ",
		"JO": "Jordan ",
		"JP": "Japan ",
		"KE": "Kenya ",
		"KG": "Kyrgyzstan ",
		"KH": "Cambodia ",
		"KI": "Kiribati ",
		"KM": "Comoros ",
		"KN": "Saint Kitts And Nevis ",
		"KP": "North Korea ",
		"KR": "South Korea ",
		"KW": "Kuwait ",
		"KY": "Cayman Islands ",
		"KZ": "Kazakhstan ",
		"LA": "Laos ",
		"LB": "Lebanon ",
		"LC": "Saint Lucia ",
		"LI": "Liechtenstein ",
		"LK": "Sri Lanka ",
		"LR": "Liberia ",
		"LS": "Lesotho ",
		"LT": "Lithuania ",
		"LU": "Luxembourg ",
		"LV": "Latvia ",
		"LY": "Libya ",
		"MA": "Morocco ",
		"MC": "Monaco ",
		"MD": "Moldova ",
		"ME": "Montenegro ",
		"MF": "Saint Martin ",
		"MG": "Madagascar ",
		"MH": "Marshall Islands ",
		"MK": "Macedonia ",
		"ML": "Mali ",
		"MM": "Myanmar ",
		"MN": "Mongolia ",
		"MO": "Macao ",
		"MP": "Northern Mariana Islands ",
		"MQ": "Martinique ",
		"MR": "Mauritania ",
		"MS": "Montserrat ",
		"MT": "Malta ",
		"MU": "Mauritius ",
		"MV": "Maldives ",
		"MW": "Malawi ",
		"MX": "Mexico ",
		"MY": "Malaysia ",
		"MZ": "Mozambique ",
		"NA": "Namibia ",
		"NC": "New Caledonia ",
		"NE": "Niger ",
		"NF": "Norfolk Island ",
		"NG": "Nigeria ",
		"NI": "Nicaragua ",
		"NL": "Netherlands ",
		"NO": "Norway ",
		"NP": "Nepal ",
		"NR": "Nauru ",
		"NU": "Niue ",
		"NZ": "New Zealand ",
		"OM": "Oman ",
		"PA": "Panama ",
		"PE": "Peru ",
		"PF": "French Polynesia ",
		"PG": "Papua New Guinea ",
		"PH": "Philippines ",
		"PK": "Pakistan ",
		"PL": "Poland ",
		"PM": "Saint Pierre And Miquelon ",
		"PN": "Pitcairn ",
		"PR": "Puerto Rico ",
		"PS": "Palestine ",
		"PT": "Portugal ",
		"PW": "Palau ",
		"PY": "Paraguay ",
		"QA": "Qatar ",
		"RE": "Reunion ",
		"RO": "Romania ",
		"RS": "Serbia ",
		"RU": "Russia ",
		"RW": "Rwanda ",
		"SA": "Saudi Arabia ",
		"SB": "Solomon Islands ",
		"SC": "Seychelles ",
		"SD": "Sudan ",
		"SE": "Sweden ",
		"SG": "Singapore ",
		"SH": "Saint Helena ",
		"SI": "Slovenia ",
		"SJ": "Svalbard And Jan Mayen ",
		"SK": "Slovakia ",
		"SL": "Sierra Leone ",
		"SM": "San Marino ",
		"SN": "Senegal ",
		"SO": "Somalia ",
		"SR": "Suriname ",
		"SS": "South Sudan ",
		"ST": "Sao Tome And Principe ",
		"SV": "El Salvador ",
		"SX": "Sint Maarten (Dutch part) ",
		"SY": "Syria ",
		"SZ": "Swaziland ",
		"TC": "Turks And Caicos Islands ",
		"TD": "Chad ",
		"TF": "French Southern Territories ",
		"TG": "Togo ",
		"TH": "Thailand ",
		"TJ": "Tajikistan ",
		"TK": "Tokelau ",
		"TL": "Timor-Leste ",
		"TM": "Turkmenistan ",
		"TN": "Tunisia ",
		"TO": "Tonga ",
		"TR": "Turkey ",
		"TT": "Trinidad and Tobago ",
		"TV": "Tuvalu ",
		"TW": "Taiwan ",
		"TZ": "Tanzania ",
		"UA": "Ukraine ",
		"UG": "Uganda ",
		"UM": "United States Minor Outlying Islands ",
		"US": "United States ",
		"UY": "Uruguay ",
		"UZ": "Uzbekistan ",
		"VA": "Vatican ",
		"VC": "Saint Vincent And The Grenadines ",
		"VE": "Venezuela ",
		"VG": "British Virgin Islands ",
		"VI": "U.S. Virgin Islands ",
		"VN": "Vietnam ",
		"VU": "Vanuatu ",
		"WF": "Wallis And Futuna ",
		"WS": "Samoa ",
		"YE": "Yemen ",
		"YT": "Mayotte ",
		"ZA": "South Africa ",
		"ZM": "Zambia ",
		"ZW": "Zimbabwe"
	}
}
