import React, { useState, useEffect } from 'react';

import i18n from 'src/i18n';

import LinearProgress from '@material-ui/core/LinearProgress';

import { useDataManagement } from './DataManagementContext';
import { OptionsEditor, AddOptionSetupChoice } from './OptionsEditor';

import ajaxGet from 'src/services/ajaxGet';
import ajaxPost from 'src/services/ajaxPost';

export const KeywordsEditor = ({ instanceEditor }) => {
  const {
    getGlobalKeywords,
    setGlobalKeywords,
    addGlobalKeyword,
    getSelectedKeywordsState,
    setSelectedKeywordsState,
    getKeywordOptionsState,
    setGlobalsToKeywordOptionsState,
    addCustomKeywordOptionsState
  } = useDataManagement();
  const instanceId = instanceEditor.instanceId;
  const globalKeywords = getGlobalKeywords();
  const keywordOptions = getKeywordOptionsState(instanceId);
  const selectedKeywords = getSelectedKeywordsState(instanceId);

  const saveUrlPart =
    instanceEditor.instanceType === 'Product'
      ? 'selectKeywordsProduct'
      : 'selectKeywordsArticle';
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const addKeywordMsg = i18n.t('instanceEditor.keywords.addOption');
  const addGlobalKeywordsMsg = i18n.t('instanceEditor.keywords.globalKeywords');
  const addCustomKeywordMsg = i18n.t('instanceEditor.keywords.customKeywords');
  const customKeywordsGroupLabel = i18n.t(
    'instanceEditor.keywords.customKeywords'
  );

  useEffect(() => {
    if (globalKeywords === null) {
      ajaxGet('dataManagement/globalKeywords', {
        contentLanguage: instanceEditor.contentLang
      }).then((ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          const newGlobalKeywords = ajaxData.response.globalKeywords.map(
            (globalKeyword) => ({
              selectionValue: globalKeyword.value,
              addToValueList: false,
              addedSelectionOption: false,
              label: globalKeyword.label
            })
          );

          setGlobalKeywords(newGlobalKeywords);
          setGlobalsToKeywordOptionsState(instanceId, newGlobalKeywords);
        }

        setError(ajaxData.error);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (globalKeywords) {
      setGlobalsToKeywordOptionsState(instanceId, globalKeywords);
    }
  }, [globalKeywords?.length]);

  function saveKeywords(keywordsToSave, keywordToAdd) {
    if (keywordToAdd) {
      keywordsToSave.push(keywordToAdd);
    }

    const params = { contentLanguage: instanceEditor.contentLang };
    const payload = {
      instanceId: instanceEditor.instanceId,
      multiSelection: keywordsToSave
    };

    ajaxPost('dataManagement/' + saveUrlPart, params, payload).then(
      (ajaxData) => {
        let response = ajaxData.response;
        TOGO.Util.notifyResponse(response.message, !response.success);

        if (!ajaxData.error && response) {
          if (keywordToAdd) {
            keywordToAdd.label = keywordToAdd.selectionValue;

            if (keywordToAdd.addToValueList) {
              keywordToAdd.selectionValue = response.id;
              keywordToAdd.addToValueList = false;
              keywordToAdd.addedSelectionOption = false;
              addGlobalKeyword(keywordToAdd);
            } else {
              addCustomKeywordOptionsState(instanceId, keywordToAdd);
            }
          }

          setSelectedKeywordsState(instanceId, keywordsToSave);
        }

        if (ajaxData.error) {
          setError(ajaxData.error);
        }
      }
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  } else if (loading) {
    return <LinearProgress color="secondary" />;
  } else {
    return (
      <div className="keyword-editor instanceEditorComponent">
        <OptionsEditor
          options={keywordOptions}
          selectedOptions={selectedKeywords}
          saveChanges={saveKeywords}
          title={'Keywords'}
          isMultiSelection={true}
          mandatory={false}
          addOptionSetup={AddOptionSetupChoice.question}
          addOptionMsg={addKeywordMsg}
          addCommonOptionsMsg={addGlobalKeywordsMsg}
          addCustomOptionMsg={addCustomKeywordMsg}
          customOptionsGroupLabel={customKeywordsGroupLabel}
        />
      </div>
    );
  }
};
