import React, { createContext, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography
} from '@material-ui/core';

import { InstanceTabEditor } from './InstanceTabEditor';
import theme from '../../PimTogoTheme';

const useErrorStyles = makeStyles({
  root: {
    minWidth: 275
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
});

function ErrorFallback({ error, resetErrorBoundary }) {
  const classes = useErrorStyles();
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Grid item xs={12}>
        <Card className={classes.root} role="alert">
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              {t('common.error')}
            </Typography>
            <Typography variant="body2" component="p">
              {t('common.errorHappened')}
            </Typography>
            <pre>{error.message}</pre>
          </CardContent>
          <CardActions>
            <Button size="small" onClick={resetErrorBoundary}>
              {t('common.errorTryAgain')}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export const TabEditorContext = createContext([]);

export default function TabEditor() {
  const [lang, setLang] = useState(document.documentElement.lang);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        // onReset={() => {
        //   // reset the state of your app so the error doesn't happen again
        // }}
      >
        <TabEditorContext.Provider value={[lang, setLang]}>
          <InstanceTabEditor instanceType={{ objectClass: 'Product' }} />
        </TabEditorContext.Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
