import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ajaxGet from 'src/services/ajaxGet';
import { useExport } from './ExportContext';
import { withStyles } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& th': {
      border: '1px solid rgba(224, 224, 224, 1)'
    },
    '& td': {
      border: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  head: {
    backgroundColor: '#eaeaea',

    '& .MuiTableCell-head': {
      fontWeight: 'bold'
    }
  }
});

export const ExportTable = () => {
  const classes = useStyles();
  const { getLoadedExports, setLoadedExports } = useExport();

  let exports = getLoadedExports();

  function handleDelete(exportId) {
    ajaxGet('deleteExport', { exportId: exportId }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
      let contentLang = $('#contentLangSelectElement').val() || null;
      ajaxGet('getSavedBMECatExports', { contentLanguage: contentLang }).then(
        (ajaxData) => {
          if (!ajaxData.error && ajaxData.response) {
            let exports = ajaxData.response.exportPojos;
            setLoadedExports(exports);
          }
        }
      );
    });
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.head}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Export</TableCell>
            <TableCell>Export-Rhythmus</TableCell>
            <TableCell>Interne User</TableCell>
            <TableCell>Kundenadressen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exports.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row" style={{ width: '17rem' }}>
                <EditButton exportId={row.id}></EditButton>
                <DeleteButton
                  exportId={row.id}
                  handleDeleteExport={handleDelete}
                ></DeleteButton>
              </TableCell>
              <TableCell>{row.exportName}</TableCell>
              <TableCell>{row.cycle}</TableCell>
              <TableCell>{row.internUsers}</TableCell>
              <TableCell>{row.customers}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ColorButton = withStyles(() => ({
  root: {
    color: 'white',
    backgroundColor: '#F29528 !important',
    borderColor: '#cf7c00 !important',
    '&:hover': {
      backgroundColor: '#F29528 !important'
    }
  }
}))(Button);

const DeleteButton = ({ exportId, handleDeleteExport }) => {
  return (
    <ColorButton
      variant="contained"
      onClick={() =>
        TOGO.popupManager.confirmZebraDialog(
          'Wollen Sie den Export wirklich löschen?',
          handleDeleteExport,
          exportId
        )
      }
    >
      <Icon className="fa fa-trash-o" aria-hidden="true" />
    </ColorButton>
  );
};

const EditButton = ({ exportId }) => {
  const { setEditExport, resetAll } = useExport();

  function handleClick() {
    let contentLang = $('#contentLangSelectElement').val() || null;
    resetAll();
    ajaxGet('editBmeCatExport', {
      exportId: exportId,
      contentLanguage: contentLang
    }).then((ajaxData) => {
      let msg = ajaxData.response?.message || ajaxData.error;
      TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
      if (ajaxData.error) return;
      let exportData = {};
      exportData.exportConfiguration = ajaxData.response.exportConfiguration;
      exportData.exportDefinition = ajaxData.response.exportDefinition;
      exportData.weekday = ajaxData.response.weekday;
      exportData.startDate = ajaxData.response.startDate;
      exportData.xlsxResult = ajaxData.response.xlsxResult;
      exportData.headerFieldValues = ajaxData.response.headerFieldValues;
      exportData.hierarchyInfoValues = ajaxData.response.hierarchyInfoValues;
      setEditExport(exportData);
    });
  }

  return (
    <ColorButton
      variant="contained"
      onClick={() => handleClick()}
      style={{ marginRight: '0.5rem' }}
    >
      <Icon className="fa fa-pencil-square-o" aria-hidden="true" />
    </ColorButton>
  );
};
