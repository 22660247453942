import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { BMEcatExportOverview } from './BMEcatExportOverview';
import ajaxGet from 'src/services/ajaxGet';
import { ExportTable } from './ExportTable';
import { useExport } from './ExportContext';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'black',
      height: 5,
      top: 43
    },
    '& .MuiTab-textColorPrimary.Mui-selected': {
      opacity: 1,
      fontWeight: 'bold'
    }
  },
  tabsNoShadow: {
    boxShadow:
      '0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%) !important'
  },
  tabTitleEdit: {
    cursor: 'text'
  }
});

export const ExportTabs = () => {
  const classes = useStyles();
  const { setLoadedExports, getTabValue, setTabValue } = useExport();

  let value = getTabValue();

  useEffect(() => {
    let contentLang = $('#contentLangSelectElement').val() || null;
    ajaxGet('getSavedBMECatExports', { contentLanguage: contentLang }).then(
      (ajaxData) => {
        if (!ajaxData.error && ajaxData.response) {
          let exports = ajaxData.response.exportPojos;
          setLoadedExports(exports);
        }
      }
    );
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        className={classes.tabs}
      >
        <Tab
          label="BMEcat-Export-Editor"
          {...a11yProps(0)}
          style={{ color: 'black' }}
        />
        <Tab
          label="BMEcat-Export-Übersicht"
          {...a11yProps(1)}
          style={{ color: 'black' }}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <BMEcatExportOverview></BMEcatExportOverview>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ExportTable></ExportTable>
      </TabPanel>
    </Paper>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}
