import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { default as MuiAccordion } from '@material-ui/core/Accordion';
import { default as MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
import { default as MuiAccordionDetails } from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ChooseArticle } from './ChooseArticle';
import { ExportConfiguration } from './ExportConfiguration';
import { BMEcatConfiguration } from './BMEcatConfiguration';
import { useExport } from './ExportContext';
import { ExportHeaderFieldEditor } from './ExportHeaderFieldEditor';
import { ExportHierarchyInfoEditor } from './ExportHierarchyInfoEditor';
import { ExportCustomFieldEditor } from './ExportCustomFieldEditor';

export const useBMEcatStyles = makeStyles(() => ({
  '@keyframes bmeCatErrorAccordion': {
    '0%': {
      backgroundImage: 'linear-gradient(to right, #f29528, #fbdba7)'
    },
    '25%': {
      backgroundColor: 'red',
      backgroundImage: 'none'
    },
    '50%': {
      backgroundImage: 'linear-gradient(to right, #f29528, #fbdba7)'
    },
    '75%': {
      backgroundColor: 'red',
      backgroundImage: 'none'
    },
    '100%': {
      backgroundImage: 'linear-gradient(to right, #f29528, #fbdba7)'
    }
  },
  title: {
    top: '-2rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  },
  bmeCatSelect: {
    minWidth: '20rem'
  },
  bmeCatInput: {
    minWidth: '20rem'
  },
  accordion: {
    margin: '5px 0',
    backgroundImage: 'linear-gradient(to right, #f29528, #fbdba7)'
  },
  accordionError: {
    margin: '5px 0',
    backgroundColor: 'red',
    animationName: '$bmeCatErrorAccordion',
    animationDuration: '4s',
    transition: 'background-color 1s, background-image 1s'
  },
  accordionSummary: {
    minHeight: 30,
    maxHeight: 30,
    '&.Mui-expanded': {
      minHeight: 30,
      maxHeight: 30
    }
  }
}));

export const BMEcatExportOverview = () => {
  const [expanded, setExpanded] = useState(false);
  const [secondExpanded, setSecondExpanded] = useState(false);
  const {
    getHeaderFields,
    getHierarchyInfos,
    getCustomFields,
    saveExport,
    getExportName,
    setExportName,
    getExportCycle,
    getExportDefId,
    getExportId,
    getOldExportName,
    getArticleChooseError,
    getBmeCatConfigError,
    getHeaderFieldsError,
    getHierarchyInfosError,
    getCustomFieldsError,
    getExportConfError,
    getHeaderErrorGroupIds,
    getHierarchyInfoErrorGroupIds,
    getCustomErrorGroupIds,
    getRegExErrorList,
    getLoadingBmeCat,
  } = useExport();
  const { t } = useTranslation();
  const classes = useBMEcatStyles();

  let headerFields = getHeaderFields();
  let hierarchyInfos = getHierarchyInfos();
  let customFields = getCustomFields();
  let cycle = getExportCycle();

  let headerErrors = getHeaderErrorGroupIds();
  let hierarchyInfoErrors = getHierarchyInfoErrorGroupIds();
  let customErrors = getCustomErrorGroupIds();
  let regExErrorList = getRegExErrorList();
  let loadingBmeCat = getLoadingBmeCat();

  const [inputState, setInputState] = useState(getExportName());

  const headerGroups = {};
  headerFields.forEach((header) => {
    let group = headerGroups[header.groupId];
    if (!group) {
      group = [header];
      headerGroups[header.groupId] = group;
    } else {
      group.push(header);
    }
  });

  const hierarchyInfosGroups = {};
  hierarchyInfos.forEach((hierarchyInfo) => {
    let group = hierarchyInfosGroups[hierarchyInfo.groupId];
    if (!group) {
      group = [hierarchyInfo];
      hierarchyInfosGroups[hierarchyInfo.groupId] = group;
    } else {
      group.push(hierarchyInfo);
    }
  });

  const customFieldGroups = {};
  customFields.forEach((field) => {
    let group = customFieldGroups[field.groupId];
    if (!group) {
      group = [field];
      customFieldGroups[field.groupId] = group;
    } else {
      group.push(field);
    }
  });

  for (let groupId in headerGroups) {
    const headers = headerGroups[groupId];
    headers.sort(function (a, b) {
      return a.groupOrder - b.groupOrder;
    });
  }

  for (let groupId in hierarchyInfosGroups) {
    const hierarchyInfoElements = hierarchyInfosGroups[groupId];
    hierarchyInfoElements.sort(function (a, b) {
      return a.groupOrder - b.groupOrder;
    });
  }

  for (let groupId in customFieldGroups) {
    const fields = customFieldGroups[groupId];
    fields.sort(function (a, b) {
      return a.groupOrder - b.groupOrder;
    });
  }

  const handleExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSecondExpand = (panel) => (event, isExpanded) => {
    setSecondExpanded(isExpanded ? panel : false);
  };

  function handleChangeExportName(value) {
    setExportName(value);
  }

  function handleSaveExportPopup() {
    let exportCycle = getExportCycle();
    let exportId = getExportId();
    let exportDefId = getExportDefId();
    let exportName = getExportName();
    let oldExportName = getOldExportName();

    if (
      exportCycle !== 'ONCE' &&
      exportId &&
      exportDefId &&
      exportName == oldExportName
    ) {
      TOGO.popupManager.confirmZebraDialog(
        t('export.overwrite', { exportName: exportName }),
        saveExport
      );
    } else {
      saveExport();
    }
  }

  return (
    <div>
      <MuiAccordion
        expanded={expanded === 0}
        onChange={handleExpand(0)}
        className={
          getArticleChooseError() ? classes.accordionError : classes.accordion
        }
      >
        <MuiAccordionSummary
          expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
          aria-controls={`${0}-content`}
          id={`${0}-header`}
          className={classes.accordionSummary}
        >
          {t('export.chooseArticle')}
        </MuiAccordionSummary>
        <MuiAccordionDetails
          style={{
            background: 'white',
            display: 'flow-root',
            padding: '20px'
          }}
        >
          <ChooseArticle />
        </MuiAccordionDetails>
      </MuiAccordion>
      <MuiAccordion
        expanded={expanded === 1}
        onChange={handleExpand(1)}
        className={
          getBmeCatConfigError() ? classes.accordionError : classes.accordion
        }
      >
        <MuiAccordionSummary
          expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
          aria-controls={`${1}-content`}
          id={`${1}-header`}
          className={classes.accordionSummary}
        >
          {t('bmeCatExport.generalSettings')}
        </MuiAccordionSummary>
        <MuiAccordionDetails
          style={{
            background: 'white',
            display: 'flow-root',
            padding: '20px'
          }}
        >
          <BMEcatConfiguration />
        </MuiAccordionDetails>
      </MuiAccordion>
      {loadingBmeCat && <LinearProgress color="secondary" />}
      {headerFields.length > 0 && (
        <MuiAccordion
          expanded={expanded === 2}
          onChange={handleExpand(2)}
          className={
            getHeaderFieldsError() ? classes.accordionError : classes.accordion
          }
        >
          <MuiAccordionSummary
            expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
            aria-controls={`${2}-content`}
            id={`${2}-header`}
            className={classes.accordionSummary}
          >
            {t('bmeCatExport.header')}
          </MuiAccordionSummary>
          <MuiAccordionDetails
            style={{
              background: 'white',
              display: 'flow-root',
              padding: '20px'
            }}
          >
            {headerFields &&
              Object.entries(headerGroups).map(([key, value], index) => (
                <MuiAccordion
                  expanded={secondExpanded === index}
                  onChange={handleSecondExpand(index)}
                  className={
                    key in headerErrors
                      ? classes.accordionError
                      : classes.accordion
                  }
                  key={key}
                >
                  <MuiAccordionSummary
                    expandIcon={
                      <i className="fa fa-caret-down" aria-hidden="true"></i>
                    }
                    aria-controls={`${index}-content`}
                    id={`${index}-header`}
                    className={classes.accordionSummary}
                  >
                    {key}
                  </MuiAccordionSummary>
                  <MuiAccordionDetails
                    style={{
                      background: 'white',
                      display: 'flow-root',
                      padding: '20px'
                    }}
                  >
                    {value.map((content) => (
                      <ExportHeaderFieldEditor
                        key={content.keyHashCode}
                        headerField={content}
                      ></ExportHeaderFieldEditor>
                    ))}
                  </MuiAccordionDetails>
                </MuiAccordion>
              ))}
          </MuiAccordionDetails>
        </MuiAccordion>
      )}
      {hierarchyInfos.length > 0 && (
        <MuiAccordion
          expanded={expanded === 3}
          onChange={handleExpand(3)}
          className={
            getHierarchyInfosError()
              ? classes.accordionError
              : classes.accordion
          }
        >
          <MuiAccordionSummary
            expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
            aria-controls={`${3}-content`}
            id={`${3}-header`}
            className={classes.accordionSummary}
          >
            {t('bmeCatExport.hierarchySystem')}
          </MuiAccordionSummary>
          <MuiAccordionDetails
            style={{
              background: 'white',
              display: 'flow-root',
              padding: '20px'
            }}
          >
            {hierarchyInfos &&
              Object.entries(hierarchyInfosGroups).map(
                ([key, value], index) => (
                  <MuiAccordion
                    expanded={secondExpanded === index}
                    onChange={handleSecondExpand(index)}
                    className={
                      key in hierarchyInfoErrors
                        ? classes.accordionError
                        : classes.accordion
                    }
                    key={key}
                  >
                    <MuiAccordionSummary
                      expandIcon={
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      }
                      aria-controls={`${index}-content`}
                      id={`${index}-header`}
                      className={classes.accordionSummary}
                    >
                      {key}
                    </MuiAccordionSummary>
                    <MuiAccordionDetails
                      style={{
                        background: 'white',
                        display: 'flow-root',
                        padding: '20px'
                      }}
                    >
                      {value.map((content) => (
                        <ExportHierarchyInfoEditor
                          key={content.keyHashCode}
                          hierarchyInfo={content}
                        ></ExportHierarchyInfoEditor>
                      ))}
                    </MuiAccordionDetails>
                  </MuiAccordion>
                )
              )}
          </MuiAccordionDetails>
        </MuiAccordion>
      )}
      {customFields.length > 0 && (
        <MuiAccordion
          expanded={expanded === 4}
          onChange={handleExpand(4)}
          className={
            getCustomFieldsError() ? classes.accordionError : classes.accordion
          }
        >
          <MuiAccordionSummary
            expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
            aria-controls={`${4}-content`}
            id={`${4}-header`}
            className={classes.accordionSummary}
          >
            Artikel-Daten
          </MuiAccordionSummary>
          <MuiAccordionDetails
            style={{
              background: 'white',
              display: 'flow-root',
              padding: '20px'
            }}
          >
            {Object.entries(customFieldGroups).map(([key, value], index) => (
              <MuiAccordion
                expanded={secondExpanded === index}
                onChange={handleSecondExpand(index)}
                className={
                  key in customErrors
                    ? classes.accordionError
                    : classes.accordion
                }
                key={key}
              >
                <MuiAccordionSummary
                  expandIcon={
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  }
                  aria-controls={`${index}-content`}
                  id={`${index}-header`}
                  className={classes.accordionSummary}
                >
                  {key}
                </MuiAccordionSummary>
                <MuiAccordionDetails
                  style={{
                    background: 'white',
                    display: 'flow-root',
                    padding: '20px'
                  }}
                >
                  {value.map((content) => (
                    <ExportCustomFieldEditor
                      key={content.keyHashCode}
                      customField={content}
                    ></ExportCustomFieldEditor>
                  ))}
                </MuiAccordionDetails>
              </MuiAccordion>
            ))}
          </MuiAccordionDetails>
        </MuiAccordion>
      )}
      <MuiAccordion
        expanded={expanded === 5}
        onChange={handleExpand(5)}
        className={
          getExportConfError() ? classes.accordionError : classes.accordion
        }
      >
        <MuiAccordionSummary
          expandIcon={<i className="fa fa-caret-down" aria-hidden="true"></i>}
          aria-controls={`${5}-content`}
          id={`${5}-header`}
          className={classes.accordionSummary}
        >
          {t('export.exportSettings')}
        </MuiAccordionSummary>
        <MuiAccordionDetails
          style={{
            background: 'white',
            display: 'flow-root',
            padding: '20px'
          }}
        >
          <ExportConfiguration />
        </MuiAccordionDetails>
      </MuiAccordion>
      <div style={{ display: 'inline-flex', margin: '2rem 0' }}>
        {cycle && cycle !== 'ONCE' ? (
          <div style={{ margin: '0 1rem' }}>
            <TextField
              id="exportName"
              label="Exportname"
              placeholder="Name eingeben..."
              className={classes.bmeCatInput}
              onChange={(event) => {
                setInputState(event.target.value);
              }}
              onBlur={(event) => handleChangeExportName(event.target.value)}
              value={inputState}
            />
          </div>
        ) : (
          ''
        )}
        {regExErrorList.length ? (
          <Tooltip title="Die eingegebenen Daten entsprechen nicht den Vorgaben">
            <div>
              <Button
                variant="contained"
                onClick={() => handleSaveExportPopup()}
                style={{ margin: '1rem 0' }}
                disabled
              >
                {cycle === 'ONCE' ? 'Exportieren' : 'Export Speichern'}
              </Button>
            </div>
          </Tooltip>
        ) : (
          <Button
            variant="contained"
            onClick={() => handleSaveExportPopup()}
            style={{ margin: '1rem 0' }}
          >
            {cycle === 'ONCE' ? 'Exportieren' : 'Export Speichern'}
          </Button>
        )}
      </div>
    </div>
  );
};
