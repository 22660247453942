import renderReactComponentInTarget from '../renderReactComponentInTarget';

import TabEditor from 'src/jsx/articledata/tabeditor/TabEditor.jsx';
import InstanceEditor from 'src/jsx/articledata/datamanagement/InstanceEditor.jsx';
import CustomerAdresses from 'src/jsx/user/CustomerAdresses.jsx';
import Users from 'src/jsx/user/Users.jsx';
import Organizations from 'src/jsx/user/Organizations.jsx';
import SFTPConnectionConfiguration from 'src/jsx/sftpUser/SFTPConnectionConfiguration';
import DataModelOverview from 'src/jsx/bmecatconfig/DataModelOverview.jsx';
import BMEcatExportSpecs from 'src/jsx/bmecatconfig/BMEcatExportSpecs.jsx'
import ExportOverview from 'src/jsx/bmecatexport/ExportOverview.jsx';
import PtdDefaultDataOverview from 'src/jsx/producttype/PtdDefaultDataOverview';
import ShopConfigOverview from 'src/jsx/shop/ShopConfigOverview.jsx';

import 'src/i18n';

renderReactComponentInTarget(
  InstanceEditor,
  '#react-InstanceEditor',
  'togo.datamanagement.loadproduct'
);
renderReactComponentInTarget(TabEditor, '#react-TabEditor');
renderReactComponentInTarget(CustomerAdresses, '#react-CustomerAdresses');
renderReactComponentInTarget(Users, '#react-Users');
renderReactComponentInTarget(Organizations, '#react-Organizations');
renderReactComponentInTarget(DataModelOverview, '#react-DataModelOverview');
renderReactComponentInTarget(BMEcatExportSpecs, '#react-BMEcatExportSpecs');
renderReactComponentInTarget(ExportOverview, '#react-BMEcatExportOverview');
renderReactComponentInTarget(PtdDefaultDataOverview, '#react-PtdDefaultDataOverview');
renderReactComponentInTarget(ShopConfigOverview, '#react-ShopConfigOverview');
renderReactComponentInTarget(SFTPConnectionConfiguration, '#react-SFTPConfigOverview');
