import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Alphanumeric } from './Alphanumeric';
import { Numeric } from './Numeric';
import { Bool } from './Bool';
import { Date } from './Date';
import { Selection } from './Selection';
import { Range } from './Range';
import { CalculatedFeatrue } from './CalculatedFeatrue';
import { ToogleStandardValues } from './ToogleStandardValues';

export const useTitleStyles = makeStyles(() => ({
  title: {
    top: '-2rem',
    left: '-1rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  },
  titleSelect: {
    top: '-1rem',
    left: '-1rem',
    position: 'relative',
    fontSize: '1.5rem !important',
    fontFamily: "'Roboto', sans-serif !important",
    color: '#636363 !important'
  },
  datepickerStyle: {
    maxWidth: '25rem',
    width: '250px',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  },
  booleanInputStyle: {
    maxWidth: '25rem',
    width: '250px',
    '& > .MuiInputBase-root': {
      height: '33px'
    }
  }
}));

export function FeatureComponent({
  featureInfo,
  value,
  setValueState,
  saveChangedValue,
  loading,
  error,
  setBlockDefaultData,
  resetBlockDefaultData,
  isBlockDefaultData
}) {
  if (featureInfo?.readOnly) {
    return (
      <CalculatedFeatrue
        featureInfo={featureInfo}
        text={value}
        setValueState={setValueState}
        saveChangedValue={saveChangedValue}
        loading={loading}
        error={error}
      ></CalculatedFeatrue>
    );
  }

  const featureType = featureInfo.featureComponent.featureType;

  switch (featureType) {
    case 'FEATURE_TYPE_ALPHANUMERIC':
    case 'FEATURE_TYPE_ALPHANUMERIC_NON_LOCALIZED':
      return (
        <div className="defaultColor">
          <Alphanumeric
            featureInfo={featureInfo}
            text={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          ></Alphanumeric>
          {(featureInfo.isDefaultData || isBlockDefaultData) &&
          !featureInfo.isCreateInstance ? (
            <ToogleStandardValues
              isCustomField={true}
              setBlockDefaultData={setBlockDefaultData}
              resetBlockDefaultData={resetBlockDefaultData}
              isBlockDefaultData={isBlockDefaultData}
            ></ToogleStandardValues>
          ) : (
            ''
          )}
        </div>
      );
    case 'FEATURE_TYPE_NUMERIC':
      return (
        <div className="defaultColor">
          <Numeric
            featureInfo={featureInfo}
            number={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          ></Numeric>
          {(featureInfo.isDefaultData || isBlockDefaultData) &&
          !featureInfo.isCreateInstance ? (
            <ToogleStandardValues
              isCustomField={true}
              setBlockDefaultData={setBlockDefaultData}
              resetBlockDefaultData={resetBlockDefaultData}
              isBlockDefaultData={isBlockDefaultData}
            ></ToogleStandardValues>
          ) : (
            ''
          )}
        </div>
      );
    case 'FEATURE_TYPE_BOOLEAN':
      return (
        <div className="defaultColor">
          <Bool
            featureInfo={featureInfo}
            value={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          ></Bool>
          {(featureInfo.isDefaultData || isBlockDefaultData) &&
          !featureInfo.isCreateInstance ? (
            <ToogleStandardValues
              isCustomField={true}
              setBlockDefaultData={setBlockDefaultData}
              resetBlockDefaultData={resetBlockDefaultData}
              isBlockDefaultData={isBlockDefaultData}
            ></ToogleStandardValues>
          ) : (
            ''
          )}
        </div>
      );
    case 'FEATURE_TYPE_LOCAL_DATE':
      return (
        <div className="defaultColor">
          <Date
            featureInfo={featureInfo}
            date={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          ></Date>
          {(featureInfo.isDefaultData || isBlockDefaultData) &&
          !featureInfo.isCreateInstance ? (
            <ToogleStandardValues
              isCustomField={true}
              setBlockDefaultData={setBlockDefaultData}
              resetBlockDefaultData={resetBlockDefaultData}
              isBlockDefaultData={isBlockDefaultData}
            ></ToogleStandardValues>
          ) : (
            ''
          )}
        </div>
      );
    case 'FEATURE_TYPE_ALPHANUMERIC_SELECTION':
    case 'FEATURE_TYPE_ALPHANUMERIC_MULTI_SELECTION':
      return (
        <div className="defaultColor">
          <Selection
            featureInfo={featureInfo}
            value={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          ></Selection>
          {(featureInfo.isDefaultData || isBlockDefaultData) &&
          !featureInfo.isCreateInstance ? (
            <ToogleStandardValues
              isCustomField={true}
              setBlockDefaultData={setBlockDefaultData}
              resetBlockDefaultData={resetBlockDefaultData}
              isBlockDefaultData={isBlockDefaultData}
            ></ToogleStandardValues>
          ) : (
            ''
          )}
        </div>
      );
    case 'FEATURE_TYPE_NUMERIC_RANGE':
      return (
        <div className="defaultColor">
          <Range
            featureType={featureType}
            featureInfo={featureInfo}
            values={value}
            setValueState={setValueState}
            saveChangedValue={saveChangedValue}
            loading={loading}
            error={error}
          ></Range>
          {(featureInfo.isDefaultData || isBlockDefaultData) &&
          !featureInfo.isCreateInstance ? (
            <ToogleStandardValues
              isCustomField={true}
              setBlockDefaultData={setBlockDefaultData}
              resetBlockDefaultData={resetBlockDefaultData}
              isBlockDefaultData={isBlockDefaultData}
            ></ToogleStandardValues>
          ) : (
            ''
          )}
        </div>
      );
  }
}
