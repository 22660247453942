import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, LinearProgress } from '@material-ui/core';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  abcEditorInTable: {
    maxWidth: '25rem',
    width: '16rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    },
    '& > .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& > .MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '& > .MuiInput-underline:hover': {
      borderBottom: 'none !important'
    },
    '& > .MuiInput-underline:focus': {
      outline: ' none !important'
    }
  }
}));

export const CalculatedFeatrue = ({
  featureInfo,
  text,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();

  const classes = useStyles();

  text = text == null ? '' : text;
  text = featureInfo.formula == null ? text : featureInfo.formula;
  const [inputState, setInputState] = useState(text);
  const [blurState, setBlurState] = useState(text);

  const title = isInTableContext ? null : featureInfo.title;

  const mandatory = featureInfo.mandatory;
  const isMandatoryError = mandatory && !text;
  const isDefaultData = featureInfo.isDefaultData;
  const readOnly = featureInfo.readOnly;

  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && !isDefaultData) {
    helper = t('common.errorMandatory');
  }

  useEffect(() => {
    setInputState(text);
  }, [text]);

  function saveValue(newValue) {
    if (!newValue) {
      if (mandatory && !isDefaultData) return;
      newValue = null;
    }

    if (blurState == newValue || (!blurState && !newValue) || loading) return;
    setBlurState(newValue);

    setValueState(newValue);
    saveChangedValue(newValue, { alphaNumericValue: newValue });
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  return (
    <TextField
      InputProps={{ readOnly: readOnly }}
      label={title}
      InputLabelProps={{
        shrink: false,
        disableAnimation: true,
        className: classesTitle.title
      }}
      className={
        isInTableContext
          ? classes.abcEditorInTable
          : classesTitle.datepickerStyle
      }
      variant={isInTableContext ? 'standard' : 'outlined'}
      value={inputState}
      error={Boolean(error) || (isMandatoryError && !isDefaultData)}
      FormHelperTextProps={{ component: 'div' }}
      helperText={helper}
      onChange={(event) => {
        if (!readOnly) {
          setInputState(event.target.value);
        }
      }}
      onBlur={(event) => saveValue(event.target.value)}
      onMouseEnter={showError}
    />
  );
};
