import React from 'react';
import { useEditor } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Button as MaterialButton, Icon } from '@material-ui/core';

export const SettingsPanel = () => {
  const { t } = useTranslation();

  const { actions, selected } = useEditor((state, query) => {
    const currentNodeId = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        dataSource: state.nodes[currentNodeId].data.props.dataSource,
        settings: state.nodes[currentNodeId].related?.settings,
        isDeletable: query.node(currentNodeId).isDeletable()
      };
    }

    return {
      selected
    };
  });

  function deleteSelectedNode() {
    if (selected.id) {
      actions.delete(selected.id);
    }
  }

  return selected ? (
    <Box bgcolor="rgba(0, 0, 0, 0.06)" mt={2} px={2} py={2}>
      <Grid container direction="column" spacing={0}>
        {selected.settings && React.createElement(selected.settings)}
        {selected.isDeletable ? (
          <MaterialButton
            variant="contained"
            onClick={deleteSelectedNode}
            style={{
              margin: '2rem',
              color: 'white',
              backgroundColor: '#d9534f',
              borderColor: '#d43f3a'
            }}
          >
            <Icon className="fa fa-trash-o fa-2x" />
            <span>{t('common.delete')}</span>
          </MaterialButton>
        ) : null}
      </Grid>
    </Box>
  ) : null;
};
