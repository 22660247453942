import React, { useContext, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@material-ui/core/TextField';

import { useDataManagement } from './DataManagementContext';
import { useCreateInstance } from './create-instances/CreateInstanceContext';
import { TableDataContext } from '../tabeditor/tools/Table';
import { useTitleStyles } from './featurecomponents/FeatureComponent';

import ajaxPost from 'src/services/ajaxPost';

export const GeneralFieldEditor = React.memo(
  ({ generalFieldType, instanceEditor }) => {
    const { t } = useTranslation();
    const { getGeneralFieldState, setGeneralFieldState } = useDataManagement();
    const {
      isCreatingInstance,
      isDuplicateInstance,
      setCreationParameter,
      setCreationComponentRef
    } = useCreateInstance();
    const [isInTableContext] = useContext(TableDataContext);
    const classesTitle = useTitleStyles();

    const instanceId = instanceEditor.instanceId;
    const generalFieldValue = getGeneralFieldState(
      instanceId,
      generalFieldType
    );

    const [error, setError] = useState('');
    const [inputState, setInputState] = useState(generalFieldValue);
    const [blurState, setBlurState] = useState(generalFieldValue);

    const componentRef = useRef(null);
    const creatingInstance = isCreatingInstance();
    const duplicateInstance = isDuplicateInstance();

    useEffect(() => {
      if (creatingInstance && !duplicateInstance) {
        setCreationComponentRef(
          generalFieldType,
          componentRef,
          isMandatoryError
        );
      }
    }, [isMandatoryError]);

    let title;
    let url = 'dataManagement/';
    let resetUrl = url;
    let mandatory = false;
    const params = { articleId: instanceId };

    switch (generalFieldType) {
      case 'articleNumber':
        title = t('data.articleNumber');
        url += 'editArticleNumber';
        params.articleNumber = inputState;
        mandatory = true;
        break;
      default:
        return null;
    }

    const isMandatoryError = mandatory && !inputState;
    let helper = '';
    if (isMandatoryError) {
      helper = t('common.errorMandatory');
    }

    function changeGeneralField(event) {
      const newVal = event.target.value;
      setInputState(newVal);
    }

    function saveGeneralField() {
      let curVal = null;
      if (inputState) {
        curVal = inputState;
      }

      if ([blurState, ''].includes(curVal)) return;
      setBlurState(curVal);

      if (creatingInstance) {
        saveCreateValue(curVal);
        return;
      }

      if (isMandatoryError) return;

      setGeneralFieldState(instanceId, generalFieldType, curVal);
      if (curVal === null) {
        resetGeneralFieldValue();
      } else {
        saveGeneralFieldValue();
      }
    }

    function saveGeneralFieldValue() {
      ajaxPost(url, params).then((ajaxData) => {
        let response = ajaxData.response;

        if (!ajaxData.error && response) {
          TOGO.Util.notifyResponse(response.message, !response.success);
        } else {
          setError(ajaxData.error);
        }
      });
    }

    function resetGeneralFieldValue() {
      ajaxPost(resetUrl, null, [instanceId]).then((ajaxData) => {
        let response = ajaxData.response;
        TOGO.Util.notifyResponse(response.message, !response.success);
        if (!ajaxData.error) {
          setBlurState(null);
        }
      });
    }

    function saveCreateValue(curVal) {
      setCreationParameter(generalFieldType, curVal);
    }

    function showError() {
      if (!error) return;
      TOGO.Util.notifyResponse(error, true);
    }

    return (
      <div
        ref={componentRef}
        className="instanceEditorComponent general-field-editor"
      >
        <TextField
          label={isInTableContext ? null : title}
          InputLabelProps={{
            shrink: false,
            disableAnimation: true,
            className: classesTitle.titleSelect
          }}
          size="small"
          variant={isInTableContext ? 'standard' : 'outlined'}
          value={inputState != null ? inputState : ''}
          error={Boolean(error) || isMandatoryError}
          FormHelperTextProps={{ component: 'div' }}
          helperText={helper}
          onChange={changeGeneralField}
          onBlur={saveGeneralField}
          onMouseEnter={showError}
        />
      </div>
    );
  }
);
GeneralFieldEditor.displayName = 'GeneralFieldEditor';
