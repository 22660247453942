import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useEditor } from '@craftjs/core';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Icon,
  Button as MuiButton,
  makeStyles
} from '@material-ui/core';

import { TabEditorContext } from './TabEditor';
import { productInstanceType } from './InstanceTabEditor';
import VersionsManager from './VersionsManager';
import PimSpinner from '../../components/common/PimSpinner';
import ajaxGet from '../../../services/ajaxGet';

const useStyles = makeStyles(() => ({
  rootInstanceSelect: {
    minWidth: 120,
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  historyButton: {
    margin: '0 1.3rem'
  }
}));

export const emptyEditor = {
  ROOT: {
    type: { resolvedName: 'Container' },
    isCanvas: true,
    props: {
      background: '#ffffff',
      padding: 5,
      dataSource: { source: null, childOverride: false }
    },
    displayName: 'Container',
    custom: {},
    parent: null,
    hidden: false,
    nodes: [],
    linkedNodes: {}
  }
};


export const Topbar = () => {
  const [lang, setLang] = useContext(TabEditorContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const { actions, query } = useEditor();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [instanceDefs, setInstanceDefs] = useState([productInstanceType]);

  useEffect(() => {
    setError(null);
    setLoading(false);

    ajaxGet('tabEditor/allCustomInstanceDef').then((ajaxData) => {
      if (!ajaxData.error && ajaxData.response) {
        const instanceTypes = [productInstanceType].concat(
          ajaxData.response.variableContentData.instanceDefs
        );
        setInstanceDefs(instanceTypes);
      }
      setError(ajaxData.error);
      setLoading(false);
    });
  }, []);

  function instanceTypeChange(e) {
    const val = e.target.value;
    if (val) {
      const instanceDef = instanceDefs.find((def) => def.value === val);
      actions.setOptions((options) => {
        options.rootDataSource = {
          source: val,
          label: instanceDef?.label || val
        };
      });

      let newEditorState = JSON.parse(JSON.stringify(emptyEditor));
      newEditorState.ROOT.props.dataSource.source = val;
      actions.deserialize(newEditorState);
    }
  }

  function langChange(e) {
    const val = e.target.value;
    setLang(val);
  }

  if (error) {
    return <div>Error: {error}</div>;
  } else if (loading) {
    return <PimSpinner />;
  } else {
    return (
      <Grid container alignItems="center">
        <Grid item xs>
          <FormControl
            className={classes.rootInstanceSelect}
            variant="outlined"
            size="small"
          >
            <Select
              id="tab-editor-instance-type"
              value={query.getOptions().rootDataSource.source}
              onChange={instanceTypeChange}
            >
              {instanceDefs.map((instanceDef) => (
                <MenuItem key={instanceDef.value} value={instanceDef.value}>
                  {instanceDef.label || instanceDef.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <FormControl variant="outlined" size="small">
            <Select
              id="tab-editor-instance-type"
              value={lang}
              onChange={langChange}
            >
              <MenuItem value="de">{t('common.german')}</MenuItem>
              <MenuItem value="en">{t('common.english')}</MenuItem>
              <MenuItem value="it">{t('common.italian')}</MenuItem>
              <MenuItem value="fr">{t('common.french')}</MenuItem>              
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <MuiButton
            className={classes.historyButton}
            size="small"
            variant="outlined"
            onClick={() => {
              actions.history.undo();
            }}
          >
            <Icon className="fa fa-undo" />
          </MuiButton>
        </Grid>
        <Grid item xs>
          <MuiButton
            className={classes.historyButton}
            size="small"
            variant="outlined"
            onClick={() => {
              actions.history.redo();
            }}
          >
            <Icon className="fa fa-undo fa-flip-horizontal" />
          </MuiButton>
        </Grid>
        <VersionsManager
          editorQuery={query}
          editorActions={actions}
        ></VersionsManager>
      </Grid>
    );
  }
};
Topbar.propTypes = {
  editorConfig: PropTypes.any
};
