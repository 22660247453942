import React from 'react';

import i18n from 'src/i18n';

import { useDataManagement } from '../DataManagementContext';
import { OptionsEditor, AddOptionSetupChoice } from '../OptionsEditor';

export const getSelectionPayloadData = (isMultiSelection, value) => {
  let selectionPayload;
  if (isMultiSelection) {
    selectionPayload = { multiSelection: value };
    if (value?.selected?.length) {
      const multiSelection = value.selected;
      selectionPayload = { multiSelection: multiSelection };
    } else {
      return null;
    }
  } else {
    if (value?.selected?.length) {
      const singleSelection = value.selected[0];
      selectionPayload = { singleSelection: singleSelection };
    } else {
      return null;
    }
  }
  return selectionPayload;
};

export const Selection = ({
  featureInfo,
  value,
  setValueState,
  saveChangedValue,
  loading,
  error
}) => {
  const {
    getCustomFieldCommonSelectionOptions,
    setCustomFieldCommonSelectionOptions
  } = useDataManagement();
  const isClassificationFeature = Boolean(featureInfo.classificationIdentifier);

  const isMultiSelection = featureInfo.featureComponent.multiSelection;

  const addOptionMsg = i18n.t('instanceEditor.selection.addOption');
  const addCommonOptionMsg = i18n.t('instanceEditor.selection.commonOptions');
  const addCustomOptionMsg = i18n.t('instanceEditor.selection.customOptions');
  const customKeywordsGroupLabel = i18n.t(
    'instanceEditor.selection.customOptions'
  );

  const hasDefaultData = featureInfo.hasDefaultData;
  let contentIdentifier;
  let addOptionSetup = null;

  let options = [];
  let standardOptions;

  if (isClassificationFeature) {
    contentIdentifier = featureInfo.headerCode;

    if (featureInfo.featureComponent.isAddableOption) {
      addOptionSetup = AddOptionSetupChoice.asCustomValue;
    }

    standardOptions = value ? value.standardOptions : [];
  }
  // customField:
  else {
    contentIdentifier = featureInfo.featureIdentifier;

    if (featureInfo.featureComponent.isAddableOption) {
      addOptionSetup = featureInfo.featureComponent.addValueList;
    }

    if (featureInfo.customFieldNotCommonOptions) {
      standardOptions = value ? value.standardOptions : [];
    } else {
      standardOptions = getCustomFieldCommonSelectionOptions(contentIdentifier);
    }
  }
  options = options.concat(standardOptions);
  options = value ? options.concat(value.customOptions) : options;

  const selected = value ? value.selected : [];

  function saveSelection(newSelection, optionToAdd) {
    if (optionToAdd) {
      newSelection.push(optionToAdd);
    }

    let selectionPayload;
    if (isMultiSelection) {
      selectionPayload = { multiSelection: newSelection };
    } else {
      const singleSelection = newSelection.length ? newSelection[0] : null;
      selectionPayload = { singleSelection: singleSelection };
    }

    let changedValue;
    if (!newSelection.length) {
      if (featureInfo.mandatory && !hasDefaultData) return;
      changedValue = null;
    } else {
      const selectionState = { selected: newSelection };
      selectionState.customOptions = newSelection.filter(
        (option) => option.addedSelectionOption
      );
      if (isClassificationFeature) {
        selectionState.standardOptions = standardOptions;
      }

      changedValue = selectionState;
    }

    saveChangedValue(
      changedValue,
      selectionPayload,
      setSelectionsStateAfterSaving
    );

    let serverResponse = null;

    function getNewSelectionState() {
      if (optionToAdd) {
        optionToAdd.label = optionToAdd.selectionValue;

        if (!isClassificationFeature && optionToAdd.addToValueList) {
          optionToAdd.selectionValue =
            serverResponse?.id || optionToAdd.selectionValue;
          optionToAdd.addToValueList = false;
          optionToAdd.addedSelectionOption = false;

          setCustomFieldCommonSelectionOptions(
            contentIdentifier,
            standardOptions.concat(optionToAdd)
          );
        }
      }

      const newSelectionState = { selected: newSelection };
      newSelectionState.customOptions = newSelection.filter(
        (option) => option.addedSelectionOption
      );
      if (isClassificationFeature) {
        newSelectionState.standardOptions = standardOptions;
      }

      return newSelectionState;
    }

    function setSelectionsStateAfterSaving(response) {
      serverResponse = response;
      const newSelectionStateAfterSaving = getNewSelectionState();
      setValueState(newSelectionStateAfterSaving);
    }

    // TODO: error and loading
    // if (ajaxData.error) {
    //   setError(ajaxData.error);
    // }
  }

  return (
    <OptionsEditor
      options={options}
      selectedOptions={selected}
      saveChanges={saveSelection}
      title={featureInfo.title}
      isMultiSelection={isMultiSelection}
      mandatory={featureInfo.mandatory && !hasDefaultData}
      addOptionSetup={addOptionSetup}
      addOptionMsg={addOptionMsg}
      addCommonOptionsMsg={addCommonOptionMsg}
      addCustomOptionMsg={addCustomOptionMsg}
      customOptionsGroupLabel={customKeywordsGroupLabel}
      loading={loading}
      error={error}
    />
  );
};
