import React, { useState } from 'react';
import {
  IconButton,
  MenuItem,
  Select,
  makeStyles,
  span,
  Tooltip
} from '@material-ui/core';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      padding: '0px ​16px 0px 1px',
      overflow: 'initial !important',
      '& .MuiSelect-select.MuiSelect-select': {
        padding: 0,
        paddingLeft: 25
      },
      '& .MuiInputBase-input': {
        padding: 0,
        paddingLeft: 1
      },
      '& .MuiSelect-selectMenu': {
        overflow: 'initial !important'
      }
    }
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  btn: {
    padding: '5px',
    margin: '12px'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  playIcon: {
    height: 38,
    width: 38
  },
  labelPagination: {
    fontSize: '14px',
    padding: '10px'
  }
}));

function PaginationComponent({
  paginationstate,
  gotoPage,
  previousPage,
  rows,
  nextPage,
  handleChangeElementsPerPage,
  handleChangeCurrentPage,
  currentPage,
  elementsPerPage
}) {


  const classes = useStyles();
  const [page, setPage] = useState();
  const [pageIndex, setPageIndex] = useState(1);

  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();

  return (
    <>
      <div className="pagination">
        {paginationstate.countElements >= elementsPerPage && (
          <div>
            <Tooltip title={t('manageOrganization.organization.firstPage')}>
              <span style={{ display: 'inline-block' }}>
                <IconButton
                  className={classes.btn}
                  onClick={() => gotoPage(1)}
                  disabled={currentPage == 1}
                >
                  <FirstPageIcon />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title={t('manageOrganization.organization.previousPage')}>
              <span style={{ display: 'inline-block' }}>
                <IconButton
                  className={classes.btn}
                  onClick={() => previousPage()}
                  disabled={currentPage == 1}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>{' '}
              </span>
            </Tooltip>
            <span>
              <span className={classes.labelPagination}>{t('manageOrganization.organization.page')} {'   '}</span>{' '}
              <Select
                style={{ padding: '0px 5px 0px 5px' }}
                className={classes.root}
                value={currentPage}
                onChange={(e) => {
                  const page = Number(e.target.value);
                  handleChangeCurrentPage(page);
                }}
              >
                {Array.from(
                  { length: paginationstate.countPages },
                  (_, i) => i + 1
                ).map((pageIndex) => (
                  <MenuItem key={pageIndex} value={pageIndex}>
                    {pageIndex}
                  </MenuItem>
                ))}
              </Select>
              <span className={classes.labelPagination}>
                &#160;{t('manageOrganization.organization.pageOf')} &#160; {paginationstate.countPages}
              </span>{' '}
            </span>

            <Tooltip title={t('manageOrganization.organization.nextPage')}>
              <span style={{ display: 'inline-block' }}>
                <IconButton
                  className={classes.btn}
                  onClick={() => nextPage()}
                  disabled={currentPage == paginationstate.countPages}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>{' '}
              </span>
            </Tooltip>
            <Tooltip title={t('manageOrganization.organization.lastPage')}>
              <span style={{ display: 'inline-block' }}>
                <IconButton
                  className={classes.btn}
                  onClick={() => gotoPage(paginationstate.countPages)}
                  disabled={currentPage == paginationstate.countPages}
                >
                  <LastPageIcon />
                </IconButton>{' '}
              </span>
            </Tooltip>
          </div>
        )}
        <span>{t('manageOrganization.organization.elementsPerPage')}</span>{' '}
        <Select
          style={{ padding: '0px 5px 0px 5px' }}
          className={classes.root}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={elementsPerPage}
          onChange={(e) => {
            handleChangeElementsPerPage(Number(e.target.value));
            // if ( paginationstate.endIndexOfCurPage <= elementsPerPage ){
            // }
          }}
        >
          {paginationstate.elementsPerPageOptions?.map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  );
}

export default PaginationComponent;
