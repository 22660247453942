import React from 'react';
import { useEffect, useState, useRef } from 'react';
//import TextField from '@material-ui/core/TextField';
import TextField from '../user/FormsUI/TextField';

import { Box, Button, Grid } from '@material-ui/core';
import { ajaxPostRetry } from 'src/services/ajaxRetry';
import ajaxGet from  "src/services/ajaxGet";
import ajaxPost from  "src/services/ajaxPost";

import { Formik,Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { usePimStyles } from 'src/jsx/PimTogoTheme';
import FocusError from '../user/FocusError';
import * as Yup from 'yup';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import theme from '../../jsx/PimTogoTheme';

const useStyles = makeStyles(() => ({
	abcEditorInTable: {
		maxWidth: '25rem',
		width: '16rem',
		'& > .MuiInputBase-root': {
			height: '44px'
		},
		'& > .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& > .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& > .MuiInput-underline:hover': {
			borderBottom: 'none !important',
		},
		'& > .MuiInput-underline:focus': {
			outline: ' none !important',
		},
	}
}));
export default function ShopConfigOverview() {

	const INITIAL_FORM_SHOP_CONFIG = {
		sw_SHOP_URL: '',
		sw_API_ACCESS_KEY_ID: '',
		sw_SECRET_ACCESS_KEY: ''
	  };
	const { t } = useTranslation();
	const classesPim = usePimStyles();
	
	const formikRef = useRef();

	const [shopUrl, setShopUrl] = useState('');
	const [apiKey, setApiKey] = useState('');
	const [secretAccessKey, setSecretAccessKey] = useState('');
	const [editConfig, setEditConfig] = useState(false);
	const [initFormShopState, setInitFormUserState] = useState(
		INITIAL_FORM_SHOP_CONFIG
	  );

	const FORM_VALIDATION = Yup.object().shape({
		sw_SHOP_URL: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')).matches(
			/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
			t('manageOrganization.organization.inputWebSiteRegExpErrorMessage')
		  ),
		sw_API_ACCESS_KEY_ID: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage')),
		sw_SECRET_ACCESS_KEY: Yup.string().required(t('manageOrganization.organization.requiredFieldErrorMessage'))
	});




	let waitingForApiCall = false;

	useEffect(() => {
		getShopConfiguration();
	  }, []);

	const getShopConfiguration = () => {
		ajaxGet("shopOverview/getShopConfig", {
		}).then((ajaxData) => {
			if (!ajaxData.error && ajaxData.response) {
				if (formikRef.current) {
					formikRef.current.setFieldValue(
						"sw_SHOP_URL",
						ajaxData.response.sw_SHOP_URL
					);
				}
			}
		});
	}


	function saveParametreValue(type, newValue) {
		const payload = {
			configShopType: type,
			configShopValue: newValue
		};

		startApiCall();
		ajaxPostRetry({
			url: 'shopOverview/saveShopConfig',
			json: payload
		}).then((ajaxData) => {
			endApiCall();

			setError(ajaxData.error);
			let msg = ajaxData.response?.message || ajaxData.error;
			TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
			if (ajaxData.error) return;
		//	saveValue(type, newValue);
		});
	}

	function saveValueApiAccessKey(type, newValue) {
		if (type == 'SW_API_ACCESS_KEY_ID' && (apiKey != newValue)) {
			saveParametreValue(type, newValue);
			setApiKey(newValue);
		}
	}

	function saveValueSecretAccessKey(type, newValue) {
		if (type == 'SW_SECRET_ACCESS_KEY' && (secretAccessKey != newValue)) {
			saveParametreValue(type, newValue);
			setSecretAccessKey(newValue);
		}
	}



	function saveValue(type, newValue) {
		if (type == 'SW_SHOP_URL' && (shopUrl != newValue) ) {
			saveParametreValue(type, newValue);
		}

		if (type == 'SW_API_ACCESS_KEY_ID' && (apiKey != newValue)) {
			saveParametreValue(type, newValue);
		}

		if (type == 'SW_SECRET_ACCESS_KEY' && (secretAccessKey != newValue)) {
			saveParametreValue(type, newValue);
		}
	}

	function startApiCall() {
		waitingForApiCall = true;
		setTimeout(() => {
		  if (waitingForApiCall) {
			setLoading(true);
		  }
		}, 2000);
	}
	
	function endApiCall() {
		waitingForApiCall = false;
		setLoading(false);
	}

	function handleClose() {
		setEditConfig(false);
	}
	
	function toggle (){
		getShopConfiguration()
		if (editConfig){
			setEditConfig(false);

		}else{
			setEditConfig(true);
			if (formikRef.current) {
				formikRef.current.setFieldValue(
					"sw_API_ACCESS_KEY_ID",
					''
				);

				formikRef.current.setFieldValue(
					"sw_SECRET_ACCESS_KEY",
					''
				);
			}
		}
		
	}
	return (
		<div>
			<ThemeProvider theme={theme}>
			<Formik 
					initialValues={{
						...initFormShopState
					  }}
					  enableReinitialize={true}
					  innerRef={formikRef}
					  validationSchema={FORM_VALIDATION}
					  onSubmit={(values) => {

						ajaxPost('shopOverview/saveShopConfig',{}, values).then((ajaxData) => {
							if (!ajaxData.error && ajaxData.response) {
							
								const FORM_SHOP_CONFIG = {
									sw_SHOP_URL: values.sw_SHOP_URL,
									sw_API_ACCESS_KEY_ID: '',
									sw_SECRET_ACCESS_KEY: ''
								};


								if (formikRef.current) {
									formikRef.current.setFieldValue(
										"sw_API_ACCESS_KEY_ID",
										''
									);

									formikRef.current.setFieldValue(
										"sw_SECRET_ACCESS_KEY",
										''
									);
								}
								handleClose();
								setInitFormUserState(FORM_SHOP_CONFIG);
								setEditConfig(false);
								console.log(values);
								TOGO.Util.notifyResponse(ajaxData.response.message, false);
							}
						});
					  }}>
			<Form>
			<FocusError />
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						'& .MuiTextField-root': { width: '50rem' },
					}}>
					<TextField  margin="normal" label={'Shop URL'} name="sw_SHOP_URL"  id="SW_SHOP_URL" inputProps={
					{ readOnly: !editConfig }}/> 
					{/* <TextField  label={'tokenUrlRequest'} id="tokenUrlRequest" margin="dense" onBlur={(event) => saveValue(event.target.value)} /> */}
					
					{editConfig ? (<>
					<TextField type="password" label={'API Key'} name="sw_API_ACCESS_KEY_ID" id="SW_API_ACCESS_KEY_ID"  margin="normal"/>
					<TextField type="password"  label={'Secret Access key'} name="sw_SECRET_ACCESS_KEY" id="SW_SECRET_ACCESS_KEY" margin="normal" />

					<Grid container >
					<Grid item xs={6}>
						<Button
						type="submit"
						className={`${classesPim.addAction} ${classesPim.dialogBottom}`}
						style={{ width: '10rem' }}
						>
						{t('common.save')} 

						</Button>
						<Button
							onClick={handleClose}
							className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
							style={{ width: '10rem', marginLeft: '1rem' }}
						>
							{t('common.cancel')} 
						</Button>
					</Grid>
					{/* <Grid item xs={3}>
						<Button
							onClick={handleClose}
							className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
							style={{ width: '10rem', marginLeft: '1rem' }}
						>
							{'cancel'}
						</Button>
					</Grid> */}
					</Grid>
					</>):( <>
					<Grid container >
						<Grid item xs={3}>
						<Button className={`${classesPim.deleteAction} ${classesPim.dialogBottom}`}
							onClick= {toggle}>{t('common.edit') }
						</Button>
						</Grid>
					</Grid>
				</>)}
				</Box>
				</Form>
			</Formik>
			</ThemeProvider>

		</div>
	);
};



