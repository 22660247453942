import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField, LinearProgress } from '@material-ui/core';

import { useTitleStyles } from './FeatureComponent';
import { TableDataContext } from '../../tabeditor/tools/Table';

import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(() => ({
  numericEditorInTable: {
    maxWidth: '25rem',
    width: '16rem',
    '& > .MuiInputBase-root': {
      height: '44px'
    }
  }
}));

export const getNumericPayloadData = (value) => ({
  numericValue: value
});

export const Numeric = ({
  featureInfo,
  number,
  setValueState,
  saveChangedValue,
  loading,
  error,
  title,
  mandatoryErrorMsg
}) => {
  const { t } = useTranslation();
  const [isInTableContext] = useContext(TableDataContext);
  const classesTitle = useTitleStyles();

  const classes = useStyles();

  if (!number) {
    number = '';
  }
  const [inputState, setInputState] = useState(number);
  const [blurState, setBlurState] = useState(number);

  const mandatory = featureInfo.mandatory;
  let isMandatoryError = mandatory && !inputState;

  let regExError = featureInfo.isRegExError;
  let numericMinRestriction = featureInfo.numericMinRestriction;
  let numericMaxRestriction = featureInfo.numericMaxRestriction;
  let numericStepRestriction = featureInfo.numericStepRestriction;
  const regExErrorMessage = featureInfo.regexRestrictionMsg;
  const hasDefaultData = featureInfo.hasDefaultData;

  error = error || '';
  let helper = '';
  if (loading) {
    helper = <LinearProgress color="secondary" />;
  } else if (isMandatoryError && hasDefaultData) {
    helper = mandatoryErrorMsg;
  } else if (isMandatoryError && !hasDefaultData) {
    helper = mandatoryErrorMsg || t('common.errorMandatory');
  } else if (regExError) {
    helper =
      'Die eingegebenen Daten entsprechen nicht den Vorgaben:\n' +
      (regExErrorMessage ? regExErrorMessage : '');
  }

  let shrinkTitle = false;
  if (!title) {
    title = isInTableContext ? null : featureInfo.title;
  } else {
    shrinkTitle = true;
  }

  useEffect(() => {
    setInputState(number);
  }, [number]);

  function saveValue(newValue) {
    if (!newValue) {
      newValue = null;
    }

    if (blurState == newValue || (!blurState && !newValue) || loading) return;
    setBlurState(newValue);

    setValueState(newValue);

    if (!newValue && mandatory && !hasDefaultData && !mandatoryErrorMsg) return;
    saveChangedValue(newValue, getNumericPayloadData(newValue));
  }

  function showError() {
    if (!error) return;
    TOGO.Util.notifyResponse(error, true);
  }

  return (
    <TextField
      label={title}
      InputLabelProps={
        shrinkTitle
          ? null
          : {
              shrink: false,
              disableAnimation: true,
              className: classesTitle.title
            }
      }
      className={
        isInTableContext
          ? classes.numericEditorInTable
          : classesTitle.datepickerStyle
      }
      variant={isInTableContext ? 'standard' : 'outlined'}
      value={inputState}
      type="number"
      inputProps={
        numericMaxRestriction && numericMinRestriction && numericStepRestriction
          ? {
              max: numericMaxRestriction,
              min: numericMinRestriction,
              step: numericStepRestriction
            }
          : {}
      }
      error={
        Boolean(error) ||
        (isMandatoryError && (!hasDefaultData || mandatoryErrorMsg)) ||
        regExError
      }
      FormHelperTextProps={{
        component: 'div',
        style: { whiteSpace: 'pre-wrap', wordWrap: 'break-word' }
      }}
      helperText={helper}
      onChange={(event) => {
        setInputState(event.target.value);
      }}
      onMouseEnter={showError}
      onBlur={(event) => saveValue(event.target.value)}
    />
  );
};
