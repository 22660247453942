import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useDataManagement } from './DataManagementContext';
import { useCreateInstance } from './create-instances/CreateInstanceContext';
import { FeatureComponent } from './featurecomponents/FeatureComponent';
import { ajaxPostRetry } from 'src/services/ajaxRetry';
import { readFeatureValueForInstance } from '../tabeditor/reader/InstanceTabEditorReader';
import ajaxGet from 'src/services/ajaxGet';

const useStyles = makeStyles(() => ({
  defaultData: {
    '& .defaultColor': {
      backgroundColor: '#fafad2',
      display: 'inline-flex',
      borderRadius: '5px'
    }
  },
  blockDefaultData: {
    '& .defaultColor': {
      display: 'inline-flex'
    }
  }
}));

export const CustomFieldEditor = React.memo(
  ({ customFieldType, instanceEditor }) => {
    const {
      getCustomFieldState,
      setCustomFieldState,
      isDefaultData,
      deleteDefaultData,
      setDefaultData,
      setCustomFieldCommonSelectionOptions,
      setBlockDefaultData,
      deleteBlockDefaultData,
      isBlockDefaultData,
      getPtdId
    } = useDataManagement();
    const {
      isCreatingInstance,
      isDuplicateInstance,
      setCreationDataFeature,
      setRegExErrorList,
      deleteFromRegExErrorList
    } = useCreateInstance();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [regExError, setRegExError] = useState(false);

    const defaultDataObject = {
      id: customFieldType,
      type: 'customField',
      instanceId: instanceEditor.instanceId
    };

    const blockDefault = isBlockDefaultData(defaultDataObject);

    let waitingForApiCall = false;

    const customFieldEditorData =
      instanceEditor.customFieldEditor[customFieldType];

    customFieldEditorData.title += customFieldEditorData.unit
      ? ' (' + customFieldEditorData.unit + ')'
      : '';

    if (customFieldEditorData.contentLabel) {
      if (customFieldEditorData.contentLabel.label.missingContentLang) {
        customFieldEditorData.title =
          '<' +
          customFieldEditorData.contentLabel.label.typeName +
          ': ' +
          customFieldEditorData.contentLabel.label.text +
          '>';
      } else {
        customFieldEditorData.title =
          customFieldEditorData.contentLabel.label.text;
      }
    }

    customFieldEditorData.isRegExError = regExError;
    const instanceId = instanceEditor.instanceId;
    const featureType = customFieldEditorData.featureComponent.featureType;
    const value = getCustomFieldState(instanceId, customFieldType);
    const creatingInstance = isCreatingInstance();
    const duplicateInstance = isDuplicateInstance()
    const subscribedContentAutomation =
      customFieldEditorData?.defaultDataAwareInfo?.subscribedContentAutomation;
    const isDefaultDataPTD = isDefaultData(defaultDataObject);
    const hasDefaultData = Boolean(
      customFieldEditorData?.defaultDataAwareInfo?.defaultDataAvailable
    );
    customFieldEditorData.isDefaultData = isDefaultDataPTD;
    customFieldEditorData.hasDefaultData = hasDefaultData;
    customFieldEditorData.isCreateInstance = creatingInstance;

    let targetClassName;
    let targetId;
    let subType = null;
    if (['Product', 'Article'].includes(instanceEditor.instanceType)) {
      targetClassName = instanceEditor.instanceType;
      targetId = instanceEditor.instanceId;
    } else if ('ProductTypeDefinition' === instanceEditor.instanceType) {
      targetClassName = instanceEditor.instanceType;
      targetId = '';
      targetId = instanceId.replace('PtdDefaultDataForProduct', '');
      targetId = targetId.replace('PtdDefaultDataForArticle', '');
    } else {
      targetClassName = 'CustomInstance';
      targetId = instanceEditor.instanceId;
      subType = instanceEditor.instanceType;
    }
    const ptdId = getPtdId();
    const customFieldPayload = {
      featureIdentifier: customFieldType,
      instanceId: targetId,
      instanceClassName: targetClassName,
      subType: subType
    };
    const url = customFieldEditorData.url;

    useEffect(() => {
      if (creatingInstance && !isDefaultDataPTD && !duplicateInstance ) { //TODO problem bei duplicate
        setCustomFieldState(instanceId, customFieldType, null);
      }
      
    }, []);

    function setValueState(clientStateValue) {
      setCustomFieldState(instanceId, customFieldType, clientStateValue);
    }

    function saveChangedValue(newValue, valuePayload, responseCallback) {
      if (newValue === null) {
        resetCustomFieldValue();
        if (hasDefaultData) setDefaultData(defaultDataObject);
      } else {
        if (customFieldEditorData.regexRestriction) {
          const regex = new RegExp(
            '^' + customFieldEditorData.regexRestriction + '$'
          );
          if (newValue === '' || regex.test(newValue)) {
            if (hasDefaultData) {
              resetBlockDefaultDataCustomField(true);
              deleteDefaultData(defaultDataObject);
            }
            saveCustomFieldValue(valuePayload, newValue, responseCallback);
            setRegExError(false);
          } else {
            setRegExError(true);
          }
        } else if (
          customFieldEditorData.numericMinRestriction &&
          customFieldEditorData.numericMaxRestriction &&
          customFieldEditorData.numericStepRestriction &&
          !customFieldEditorData.regexRestriction
        ) {
          if (
            newValue >= customFieldEditorData.numericMinRestriction &&
            newValue <= customFieldEditorData.numericMaxRestriction &&
            newValue % customFieldEditorData.numericStepRestriction == 0
          ) {
            if (hasDefaultData) {
              resetBlockDefaultDataCustomField(true);
              deleteDefaultData(defaultDataObject);
            }
            saveCustomFieldValue(valuePayload, newValue, responseCallback);
            setRegExError(false);
          } else {
            setRegExError(true);
          }
        } else {
          if (hasDefaultData) {
            resetBlockDefaultDataCustomField(true);
            deleteDefaultData(defaultDataObject);
          }
          saveCustomFieldValue(valuePayload, newValue, responseCallback);
        }
      }
    }

    function startApiCall() {
      waitingForApiCall = true;
      setTimeout(() => {
        if (waitingForApiCall) {
          setLoading(true);
        }
      }, 2000);
    }

    function endApiCall() {
      waitingForApiCall = false;
      setLoading(false);
    }

    function saveCustomFieldValue(valuePayload, newValue, responseCallback) {
      const payload = {
        ...customFieldPayload,
        ...valuePayload
      };

      startApiCall();
      ajaxPostRetry({
        url: url,
        params: {
          contentLanguage: instanceEditor.contentLang,
          featureType: featureType
        },
        json: payload
      }).then((ajaxData) => {
        endApiCall();

        setError(ajaxData.error);
        let msg = ajaxData.response?.message || ajaxData.error;
        TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
        if (ajaxData.error) return;

        if (responseCallback) {
          responseCallback(ajaxData.response);
          return;
        }

        setCustomFieldState(instanceId, customFieldType, newValue);
      });
    }

    function resetCustomFieldValue() {
      startApiCall();
      ajaxPostRetry({
        url: 'dataManagement/resetCustomFieldValue',
        params: { contentLanguage: instanceEditor.contentLang, ptdId: ptdId },
        json: customFieldPayload
      }).then((ajaxData) => {
        endApiCall();

        setError(ajaxData.error);
        let msg = ajaxData.response?.message || ajaxData.error;
        TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
        if (ajaxData.error) return;
        let defaultDataCustomField = ajaxData.response.customField;
        let value = null;
        if (defaultDataCustomField) {
          value = readFeatureValueForInstance(
            defaultDataCustomField.featureComponent,
            customFieldEditorData,
            setCustomFieldCommonSelectionOptions
          );
          setDefaultData(defaultDataObject);
        }
        setCustomFieldState(instanceId, customFieldType, value);
      });
    }

    function resetDefaultField() {
      let params = {};
      if (targetClassName === 'CustomInstance') {
        params.contentIdentifier = defaultDataObject.id;
        params.contentLanguage = instanceEditor.contentLang;
        params.ptdId = ptdId;
        params.ptdObjectClass = 'ProductTypeDefinition';
        params.subType = subType;
        params.containingObjectClass =
          customFieldEditorData.defaultDataAwareInfo.defaultDataObjectClass;
      } else {
        params.contentIdentifier = defaultDataObject.id;
        params.contentLanguage = instanceEditor.contentLang;
        params.ptdId = customFieldEditorData.defaultDataAwareInfo.defaultDataId;
        params.ptdObjectClass =
          customFieldEditorData.defaultDataAwareInfo.defaultDataObjectClass;
      }
      ajaxGet('dataManagement/getDefaultFieldPtd', params).then((ajaxData) => {
        let msg = ajaxData.response?.message || ajaxData.error;
        TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
        if (ajaxData.error) return;
        let defaultDataCustomField = ajaxData.response.customField;
        let value = null;
        if (defaultDataCustomField) {
          value = readFeatureValueForInstance(
            defaultDataCustomField.featureComponent,
            customFieldEditorData,
            setCustomFieldCommonSelectionOptions
          );
          setDefaultData(defaultDataObject);
        }
        setCustomFieldState(instanceId, customFieldType, value);
      });
    }

    function saveCreateValue(newValue, valuePayload) {
      if (customFieldEditorData.regexRestriction) {
        const regex = new RegExp(
          '^' + customFieldEditorData.regexRestriction + '$'
        );
        if ((newValue === null || newValue === '') && hasDefaultData) {
          deleteFromRegExErrorList(customFieldType);
          setRegExError(false);
          resetDefaultField();
          setCreationDataFeature(customFieldType, null);
        } else if (newValue === '' || regex.test(newValue)) {
          deleteFromRegExErrorList(customFieldType);
          setRegExError(false);
          deleteDefaultData(defaultDataObject);
          setCreationDataFeature(customFieldType, valuePayload);
          setCustomFieldState(instanceId, customFieldType, newValue);
        } else {
          setRegExErrorList(customFieldType);
          setRegExError(true);
        }
      } else if (
        customFieldEditorData.numericMinRestriction &&
        customFieldEditorData.numericMaxRestriction &&
        customFieldEditorData.numericStepRestriction &&
        !customFieldEditorData.regexRestriction
      ) {
        if ((newValue === null || newValue === '') && hasDefaultData) {
          deleteFromRegExErrorList(customFieldType);
          setRegExError(false);
          resetDefaultField();
          setCreationDataFeature(customFieldType, null);
        } else if (
          newValue >= customFieldEditorData.numericMinRestriction &&
          newValue <= customFieldEditorData.numericMaxRestriction &&
          newValue % customFieldEditorData.numericStepRestriction == 0
        ) {
          deleteFromRegExErrorList(customFieldType);
          setRegExError(false);
          deleteDefaultData(defaultDataObject);
          setCreationDataFeature(customFieldType, valuePayload);
          setCustomFieldState(instanceId, customFieldType, newValue);
        } else {
          setRegExErrorList(customFieldType);
          setRegExError(true);
        }
      } else {
        if ((newValue === null || newValue === '') && hasDefaultData) {
          resetDefaultField();
          setCreationDataFeature(customFieldType, null);
        } else {
          deleteDefaultData(defaultDataObject);
          setCreationDataFeature(customFieldType, valuePayload);
          setCustomFieldState(instanceId, customFieldType, newValue);
        }
      }
    }

    function setBlockDefaultDataCustomField() {
      let contentIdentifier = defaultDataObject.id;
      let type = defaultDataObject.type;
      let parentId = instanceEditor.instanceId;
      let parentObjectClass = instanceEditor.instanceType;
      startApiCall();
      ajaxPostRetry({
        url: 'dataManagement/postSetBlockDefaultData',
        params: {
          contentIdentifier: contentIdentifier,
          type: type,
          parentId: parentId,
          parentObjectClass: parentObjectClass
        }
      }).then((ajaxData) => {
        endApiCall();
        let msg = ajaxData.response?.message || ajaxData.error;
        TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
        if (ajaxData.error) return;
      });
      setBlockDefaultData(defaultDataObject);
      deleteDefaultData(defaultDataObject);
      setCustomFieldState(instanceId, customFieldType, null);
    }

    function resetBlockDefaultDataCustomField(withOutReset) {
      if (!subscribedContentAutomation) return;

      let contentIdentifier = defaultDataObject.id;
      let type = defaultDataObject.type;
      let parentId = instanceEditor.instanceId;
      let parentObjectClass = instanceEditor.instanceType;
      startApiCall();
      ajaxPostRetry({
        url: 'dataManagement/postResetBlockDefaultData',
        params: {
          contentIdentifier: contentIdentifier,
          type: type,
          parentId: parentId,
          parentObjectClass: parentObjectClass
        }
      }).then((ajaxData) => {
        endApiCall();
        let msg = ajaxData.response?.message || ajaxData.error;
        TOGO.Util.notifyResponse(msg, Boolean(ajaxData.error));
        if (ajaxData.error) return;
      });
      deleteBlockDefaultData(defaultDataObject);
      if (!withOutReset) {
        resetCustomFieldValue();
      }
    }

    if (!customFieldEditorData) {
      return null;
    }
    return (
      <div
        className={`custom-field-editor instanceEditorComponent ${
          isDefaultDataPTD && !blockDefault ? classes.defaultData : null
        } ${blockDefault ? classes.blockDefaultData : null}`}
      >
        <FeatureComponent
          featureInfo={customFieldEditorData}
          value={value}
          setValueState={setValueState}
          saveChangedValue={
            creatingInstance ? saveCreateValue : saveChangedValue
          }
          loading={loading}
          error={error}
          setBlockDefaultData={setBlockDefaultDataCustomField}
          resetBlockDefaultData={resetBlockDefaultDataCustomField}
          isBlockDefaultData={blockDefault}
        />
      </div>
    );
  }
);
CustomFieldEditor.displayName = 'CustomFieldEditor';
